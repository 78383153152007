import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import LoadingSpinner from './components/LoadingSpinner';

import Notifier from './components/Notifier';
// import Login from 'src/views/auth/LoginView';
// import MainLayout from 'src/layouts/MainLayout';
// import { useDispatch } from 'react-redux';
// import { login } from './actions/auth';
// import logins from './reducers';

const App = () => {
  // const dispatch = useDispatch();
  const routing = useRoutes(routes);
  // const user = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    // dispatch(login());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LoadingSpinner />
      <Notifier />
      {routing}
    </ThemeProvider>
  );
};

export default App;
