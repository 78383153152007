import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 5,
    marginBottom: 5,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
    height: 100
  },
  button: {
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#2c387e',
    }
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Thank You For Creating Account - ServTix"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box
            textAlign="center"
            mb={5}
          >
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/thank-you.svg"
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            <Box
              mb={3}
            >
              Account Created Successfully
            </Box>
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
            Hey check your email. Activate your account using the link that i just sent  😉
          </Typography>
          <Box
            textAlign="center"
            margin={2}
            display="flex"
            justifyContent="center"
          >
            <Box pr={1}>
              <Button
                className={classes.button}
                onClick={() => navigate('/login')}
              >
                Login
              </Button>
            </Box>
            <Box pl={1}>
              <Button
                className={classes.button}
                onClick={() => navigate('/')}
              >
                Homepage
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
