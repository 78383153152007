import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  Link,
  Tab,
  Tabs,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Keyboard as KeyboardIcon,
  Face as FaceIcon
} from '@material-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import decode from 'jwt-decode';

import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import PasswordLogin from './PasswordLogin';
import FaceIDLogin from './FaceIDLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabs: {
    flexGrow: 1,
    maxWidth: '100%',
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [width, setWidth] = useState(window.innerWidth);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setView = async () => {
    await setIsMobile(width <= 900);
  };

  const handleWindowSizeChange = async () => {
    await setWidth(window.innerWidth);
    await setView();
  };

  const checkLocalStorage = () => {
    if (JSON.parse(localStorage.getItem('profile'))?.token) {
      return JSON.parse(localStorage.getItem('profile'))?.token;
    }
    return false;
  };

  const checkToken = () => {
    const token = checkLocalStorage();
    if (token !== false) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 > new Date().getTime()) {
        history('/app/dashboard');
      }
    }
  };

  useEffect(() => {
    checkToken();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [width]);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        mt="5%"
      >
        <Container maxWidth="sm">
          <Box mt={6} mb={2}>
            <Logo height={60} maxwidth={80} />
            <Typography
              align="center"
              component="div"
            >
              <Box
                justifyContent="center"
                display="flex"
              >
                <Box
                  alt="Prism Wording"
                  src="/static/images/PRISM1.svg"
                  component="img"
                  maxWidth="80%"
                  width="auto"
                  display="block"
                />
              </Box>
              <Box
                fontFamily="Roboto"
                fontWeight="fontWeightMedium"
                fontSize="85%"
                letterSpacing={2}
                color="#616161"
                mt={-3}
              >
                PTP REQUEST &#38; ISSUE MANAGEMENT SYSTEM
              </Box>
            </Typography>
          </Box>
          <Divider mb={2} />
          <Box
            mt={2}
            width={1}
          >
            <Paper square className={classes.tabs}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="Login Option"
              >
                <Tab icon={<KeyboardIcon />} label="Password" />
                <Tab
                  icon={<FaceIcon />}
                  label={!isMobile ? 'Face ID only available on mobile devices' : 'Face ID'}
                  disabled={!isMobile}
                />
              </Tabs>
            </Paper>
          </Box>
          <Box
            maxHeight="35%"
          >
            <Paper square className={classes.tabs}>
              <Box>
                {value === 0 && (
                  <>
                    <PasswordLogin />
                  </>
                )}
                {value === 1 && (
                  <>
                    <FaceIDLogin />
                  </>
                )}
              </Box>
            </Paper>
          </Box>

          <Box
            width={1}
            height="10%"
            maxHeight="10%"
          >
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Don&apos;t have an account?
              {' '}
              <Link
                component={RouterLink}
                to="/register"
                variant="h6"
              >
                Sign up
              </Link>
              {' '}
              now.
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              <Link
                component={RouterLink}
                to="/forgotpassword"
                variant="h6"
              >
                Forgot Password?
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
