import { combineReducers } from 'redux';

import logins from './logins';
import auth from './auth';
import service from './service';
import notifier from './notifier';

export default combineReducers({
  logins,
  auth,
  service,
  notifier,
});
