import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  ArrowDownCircle,
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  FilePlus as FileIcon,
  Feather as FeatherIcon,
  MessageSquare as MessageIcon
} from 'react-feather';
import { useDispatch } from 'react-redux';
import NavItem from './NavItem';

const items = [];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, userData }) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const dummyUser = {
    avatar: '/static/images/avatars/avatar_2.png',
    jobTitle: userData?.result?.JOB || 'Undefined',
    name: userData?.result?.EMPLOYEE_NAME || 'Unavailable',
    type: userData?.accountType || 'User'
  };
  const [user, setUser] = useState(dummyUser);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    setUser(null);
  };

  const checkLocalStorage = () => {
    if (JSON.parse(localStorage.getItem('profile')) === null) {
      history('/login');
    } else {
      setUser(JSON.parse(localStorage.getItem('profile')));
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    checkLocalStorage();
  }, []);

  if (items.length === 0) {
    if (user?.type === 'User') {
      items.push(
        {
          href: '/app/dashboard',
          icon: BarChartIcon,
          title: 'Dashboard'
        },
        {
          href: '/app/service',
          icon: FileIcon,
          title: 'Service'
        },
        {
          href: '/app/equipment',
          icon: FileIcon,
          title: 'Equipment'
        },
        {
          href: '/app/approval',
          icon: FeatherIcon,
          title: 'Approval'
        },
        {
          href: '/app/faqs',
          icon: MessageIcon,
          title: 'FAQ\'S'
        },
        {
          href: '/app/settings',
          icon: SettingsIcon,
          title: 'Settings'
        },
      );
    } else if (user.type === 'Division User' || user.type === 'Vendor User') {
      items.push(
        {
          href: '/app/dashboard',
          icon: BarChartIcon,
          title: 'Dashboard'
        },
        {
          href: '/app/service',
          icon: FileIcon,
          title: 'Service'
        },
        {
          href: '/app/equipment',
          icon: FileIcon,
          title: 'Equipment'
        },
        {
          href: '/app/approval',
          icon: FeatherIcon,
          title: 'Approval'
        },
        {
          href: '/app/faqs',
          icon: MessageIcon,
          title: 'FAQ\'S'
        },
        {
          href: '/app/settings',
          icon: SettingsIcon,
          title: 'Settings'
        },
      );
    } else if (user?.type === 'Division Admin') {
      items.push(
        {
          href: '/app/dashboard',
          icon: BarChartIcon,
          title: 'Dashboard'
        },
        {
          href: '/app/service',
          icon: FileIcon,
          title: 'Service'
        },
        {
          href: '/app/equipment',
          icon: FileIcon,
          title: 'Equipment'
        },
        {
          href: '/app/administrator',
          icon: UsersIcon,
          title: 'Administrator'
        },
        {
          href: '/app/approval',
          icon: FeatherIcon,
          title: 'Approval'
        },
        {
          href: '/app/faqs',
          icon: MessageIcon,
          title: 'FAQ\'S'
        },
        {
          href: '/app/settings',
          icon: SettingsIcon,
          title: 'Settings'
        },
      );
    } else if (user?.type === 'Super Admin') {
      items.push(
        {
          href: '/app/dashboard',
          icon: BarChartIcon,
          title: 'Dashboard'
        },
        {
          href: '/app/service',
          icon: FileIcon,
          title: 'Service'
        },
        {
          href: '/app/equipment',
          icon: FileIcon,
          title: 'Equipment'
        },
        {
          href: '/app/administrator',
          icon: UsersIcon,
          title: 'Administrator'
        },
        {
          href: '/app/approval',
          icon: FeatherIcon,
          title: 'Approval'
        },
        {
          href: '/app/faqs',
          icon: MessageIcon,
          title: 'FAQ\'S'
        },
        {
          href: '/app/settings',
          icon: SettingsIcon,
          title: 'Settings'
        },
      );
    }
  }
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user?.avatar
            // || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAABR1BMVEX'
            || null}
          to=""
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user?.name || user?.result.EMPLOYEE_NAME || ''}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user?.jobTitle || user?.result.POSITION || ''}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user?.type || user?.accountType || ''}
        </Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <NavItem
            href="/#"
            onClick={logout}
            key="Logout"
            title="Logout"
            icon={ArrowDownCircle}
          />
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  userData: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
