import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // Grid,
  Typography,
  makeStyles,
  // Tooltip
  // Button
  // Icons
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.paper
  }
}));

export default function Popup(props) {
  const {
    title, children, openPopup, setOpenPopup, setServiceType, fullScreen
  } = props;
  const handleOnClick = () => {
    setOpenPopup(false);
  };
  const classes = useStyles();
  // const [refresh, setRefresh] = useState(false);

  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      scroll="body"
      fullWidth
      // eslint-disable-next-line no-unneeded-ternary
      fullScreen={fullScreen === true ? true : false}
      className={classes.root}
      onClose={handleOnClick}
      disableBackdropClick
    >
      <DialogTitle>
        <Typography>
          {title}
        </Typography>
        <Box
          right={15}
          position="absolute"
          top={15}
        >
          <CloseIcon
            onClick={() => {
              setOpenPopup(false);
              if (setServiceType) {
                setServiceType(null);
              }
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogTitle>
        <Box
          // position="absolute"
          right={15}
          paddingBottom={2}
          sx={{ p: 3, border: '6px dashed grey' }}
        >
          <Box
            right={15}
            sx={{ p: 3, border: '6px dashed grey' }}
          >
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPopup(false);
                if (setServiceType) {
                  setServiceType(null);
                }
              }}
            >
              Close Popup
            </Button>
          </Box>
        </Box>

      </DialogTitle>
    </Dialog>
  );
}

Popup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  setServiceType: PropTypes.func,
  fullScreen: PropTypes.func,
};
