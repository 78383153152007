import React, { useState } from 'react';
import {
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  ArrowDownward
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import Popup from '../../../components/Popup';
import TicketDetails from '../../../components/TicketDetails';

function ApprovalTicketTable({
  approvalTicket,
  user,
  currentPage,
  refresh,
  setRefresh
}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  let temp = [];
  if (approvalTicket.length) {
    temp = approvalTicket.map((ticket) => (

      {
        id: ticket.id || null,
        serviceRefno: ticket.SERVICE_REFNO || 'null',
        title: ticket.SERVICE_TITLE || 'null',
        status: (
          ticket?.SERVICE_STATUS_NAME === null
            ? ticket?.SERVICE_STATUS
            : ticket?.SERVICE_STATUS_NAME
        ),
        category: (
          ticket?.CATEGORY_NAME === null
            ? ticket?.CATEGORY
            : ticket?.CATEGORY_NAME
        ),
        updateDate: ticket.UPDATE_DATE || '-',
        pic: ticket.PIC_NAME || ticket.PIC || "Haven't been assigned yet!",
        severity: (
          ticket?.SEVERITY_IMPACT === null
            ? ticket?.SEVERITY_ID
            : ticket?.SEVERITY_IMPACT
        ),
        creator: ticket.EMPLOYEE_NAME
      }

    )).filter(
      (row) => (row.status === 'Pending Unresolved') || (row.status === 'Resubmit Request') || (row.status === 'Pending Request')
    ) || null;
  }

  const handleOnClick = () => {
    setOpenTicketDetails(!openTicketDetails);
  };

  return (
    <>
      <MaterialTable
        icons={{
          Check: () => <Check />,
          Export: () => <SaveAlt />,
          Filter: () => <FilterList />,
          FirstPage: () => <FirstPage />,
          LastPage: () => <LastPage />,
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          ThirdStateCheck: () => <Remove />,
          ViewColumn: () => <ViewColumn />,
          DetailPanel: () => <ChevronRight />,
          SortArrow: () => (<ArrowDownward />)
        }}
        columns={[
          { title: 'Status', field: 'status' },
          { title: 'Ticket Number', field: 'serviceRefno' },
          { title: 'Severity', field: 'severity' },
          { title: 'Service Summary', field: 'title' },
          { title: 'Service Category', field: 'category' },
          { title: 'Last Update', field: 'updateDate' },
          { title: 'Creator', field: 'creator' }
        ]}
        data={temp}
        onRowClick={((evt, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
        options={{
          toolbar: false,
          paging: true,
          rowStyle: (rowData) => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          })
        }}
      />
      <Popup
        openPopup={openTicketDetails}
        setOpenPopup={setOpenTicketDetails}
        title="Details"
      >
        <TicketDetails
          ticket={selectedRow}
          user={user}
          openPopup={openTicketDetails}
          setOpenPopup={setOpenTicketDetails}
          currentPage={currentPage}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Popup>
    </>
  );
}

ApprovalTicketTable.propTypes = {
  approvalTicket: PropTypes.array,
  user: PropTypes.object,
  currentPage: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default ApprovalTicketTable;

export const ApprovedTicketTable = ({
  approvalTicket,
  user,
  currentPage,
  refresh,
  setRefresh
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  let temp = [];
  if (approvalTicket.length) {
    temp = approvalTicket.map((ticket) => (

      {
        id: ticket.id || null,
        serviceRefno: ticket.SERVICE_REFNO || 'null',
        title: ticket.SERVICE_TITLE || 'null',
        status: (
          ticket?.SERVICE_STATUS_NAME === null
            ? ticket?.SERVICE_STATUS
            : ticket?.SERVICE_STATUS_NAME
        ),
        category: (
          ticket?.CATEGORY_NAME === null
            ? ticket?.CATEGORY
            : ticket?.CATEGORY_NAME
        ),
        updateDate: ticket.UPDATE_DATE || '-',
        pic: ticket.PIC_NAME || ticket.PIC || "Haven't been assigned yet!",
        severity: (
          ticket?.SEVERITY_IMPACT === null
            ? ticket?.SEVERITY_ID
            : ticket?.SEVERITY_IMPACT
        ),
        creator: ticket.EMPLOYEE_NAME
      }

    )).filter(
      (row) => (row.status !== 'Pending Unresolved') && (row.status !== 'Resubmit Request') && (row.status !== 'Pending Request')
    ) || null;
  }

  const handleOnClick = () => {
    setOpenTicketDetails(!openTicketDetails);
  };

  return (
    <>
      <MaterialTable
        icons={{
          Check: () => <Check />,
          Export: () => <SaveAlt />,
          Filter: () => <FilterList />,
          FirstPage: () => <FirstPage />,
          LastPage: () => <LastPage />,
          NextPage: () => <ChevronRight />,
          PreviousPage: () => <ChevronLeft />,
          Search: () => <Search />,
          ThirdStateCheck: () => <Remove />,
          ViewColumn: () => <ViewColumn />,
          DetailPanel: () => <ChevronRight />,
          SortArrow: () => (<ArrowDownward />)
        }}
        columns={[
          { title: 'Status', field: 'status' },
          { title: 'Ticket Number', field: 'serviceRefno' },
          { title: 'Severity', field: 'severity' },
          { title: 'Service Summary', field: 'title' },
          { title: 'Service Category', field: 'category' },
          { title: 'Last Update', field: 'updateDate' },
          { title: 'Creator', field: 'creator' }
        ]}
        data={temp}
        onRowClick={((evt, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
        options={{
          toolbar: false,
          paging: true,
          rowStyle: (rowData) => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          })
        }}
      />
      <Popup
        openPopup={openTicketDetails}
        setOpenPopup={setOpenTicketDetails}
        title="Details"
      >
        <TicketDetails
          ticket={selectedRow}
          user={user}
          openPopup={openTicketDetails}
          setOpenPopup={setOpenTicketDetails}
          currentPage={currentPage}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Popup>
    </>
  );
};

ApprovedTicketTable.propTypes = {
  approvalTicket: PropTypes.array,
  user: PropTypes.object,
  currentPage: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};
