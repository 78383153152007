import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { registerbysuperadmin as Registers } from '../../../actions/admin';
// import UserDetails from './userDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = ({
  setRegister,
  divisions,
  refresh,
  setRefresh,
  accountType,
  allCompany,
  // divisionsactive, // from here
  // close,
  // divisionidofuser,
  employeeNumberuser,
  // setOpenDialog
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [openAccountType, setOpenAccountType] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [tempCompany, setTempCompany] = useState('');
  const [dataRegistered, setDataRegistered] = useState([]);

  const handleClickAccountType = (value) => {
    if (value === 'User') {
      setOpenAccountType(true);
    } else {
      setOpenAccountType(false);
    }
  };

  const listCompany = allCompany.map((option) => {
    const externalcompany = `${option?.EXTERNAL_TYPE}, ${option?.EXTERNAL_ID} - ${option?.EXTERNAL_NAME}`;
    return {
      externalcompany: externalcompany || '',
      ...option,
    };
  });

  const handleClickCompany = (value) => {
    setTempCompany(value?.EXTERNAL_NAME || '');
    console.log(tempCompany);
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              name: '',
              telephone: '',
              employee_number: '',
              national_identifier: '',
              position: '',
              division_id: '',
              sup_numb: '',
              account_type: '',
              password: '',
              confirmPassword: '',
              policy: false,
              company_reg_no: '',
              company_name: tempCompany,
              employee_number_creator: employeeNumberuser
            }}
            validationSchema={
              ((openAccountType === true) ? (

                Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  name: Yup.string().max(255).required('Name is required'),
                  password: Yup.string().max(255).required('password is required')
                    .matches(
                      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                      'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                    ),
                  confirmPassword: Yup
                    .string()
                    .required('Please confirm your password')
                    .when('password', {
                      is: (password) => (!!(password && password.length > 0)),
                      then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                    }),
                  employee_number: Yup.string()
                    // .matches(/^[0-9]+$/, 'Must be only digits')
                    .required('Employee Number is required'),
                  sup_numb: Yup.string()
                    // .matches(/^[0-9]+$/, 'Must be only digits')
                    .required('Supervisor Employee Number is required'),
                  policy: Yup.boolean().oneOf([true], 'This field must be checked')
                })
              ) : (
                Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  name: Yup.string().max(255).required('Name is required'),
                  password: Yup.string().max(255).required('password is required')
                    .matches(
                      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                      'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                    ),
                  confirmPassword: Yup
                    .string()
                    .required('Please confirm your password')
                    .when('password', {
                      is: (password) => (!!(password && password.length > 0)),
                      then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                    }),
                  policy: Yup.boolean().oneOf([true], 'This field must be checked'),
                  company_name: Yup.string().max(255).required('Company name is required'),
                  national_identifier: Yup.string().max(255).required('I/C or passport is required')
                })
              ))
            }
            onSubmit={
              (values, { setSubmitting/* , resetForm */ }) => {
                const { policy, ...data } = values;
                setSubmitting(true);
                dispatch(Registers(data))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setRegister(true);
                      setUserDetails(true);
                      setDataRegistered(res?.data);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {!userDetails ? (
                  <>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      Create New User Login
                    </Typography>
                    {accountType === 'Super Admin' ? (
                      <TextField
                        error={Boolean(touched.account_type && errors.account_type)}
                        fullWidth
                        helperText={touched.account_type && errors.account_type}
                        label="Account Type"
                        margin="normal"
                        name="account_type"
                        onBlur={handleBlur}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e);
                          handleClickAccountType(e.target.value);
                        }}
                        value={values.account_type}
                        variant="outlined"
                        select
                      >
                        <MenuItem value="Vendor User">
                          Vendor User
                        </MenuItem>
                        <MenuItem value="User">
                          General User
                        </MenuItem>
                      </TextField>
                    ) : (
                      <TextField
                        error={Boolean(touched.account_type && errors.account_type)}
                        fullWidth
                        helperText={touched.account_type && errors.account_type}
                        label="Account Type"
                        margin="normal"
                        name="account_type"
                        onBlur={handleBlur}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e);
                          handleClickAccountType(e.target.value);
                        }}
                        value={values.account_type}
                        variant="outlined"
                        select
                      >

                        <MenuItem value="Vendor User">
                          Vendor User
                        </MenuItem>
                      </TextField>
                    )}
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    {openAccountType && (
                      <TextField
                        error={Boolean(touched.employee_number && errors.employee_number)}
                        fullWidth
                        helperText={touched.employee_number && errors.employee_number}
                        label="Employee Number"
                        margin="normal"
                        name="employee_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.employee_number}
                        variant="outlined"
                        inputProps={{ maxLength: 6 }}
                      />
                    )}
                    <TextField
                      error={Boolean(touched.national_identifier && errors.national_identifier)}
                      fullWidth
                      helperText={touched.national_identifier && errors.national_identifier}
                      label="I/C or Passport"
                      margin="normal"
                      name="national_identifier"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.national_identifier}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.telephone && errors.telephone)}
                      fullWidth
                      helperText={touched.telephone && errors.telephone}
                      label="H/P Number"
                      margin="normal"
                      name="telephone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telephone}
                      variant="outlined"
                    />
                    {!openAccountType && (
                      <TextField
                        error={Boolean(touched.company_reg_no && errors.company_reg_no)}
                        fullWidth
                        helperText={touched.company_reg_no && errors.company_reg_no}
                        label="Company Registration No"
                        margin="normal"
                        name="company_reg_no"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.company_reg_no}
                        variant="outlined"
                      />
                    )}
                    {!openAccountType && (
                      <Autocomplete
                        id="company_name"
                        options={
                          listCompany.sort(
                            (a, b) => b.EXTERNAL_TYPE?.localeCompare(a?.EXTERNAL_TYPE)
                          )
                        }
                        getOptionLabel={(option) => option.EXTERNAL_NAME}
                        groupBy={(option) => option.EXTERNAL_TYPE}
                        onChange={(event, value) => {
                          handleChange({
                            target: {
                              name: 'company_name',
                              value: value ? value.EXTERNAL_NAME : '',
                            },
                          });
                          if (value) handleClickCompany(value);
                        }}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.company_name && errors.company_name)}
                            fullWidth
                            helperText={touched.company_name && errors.company_name}
                            label="Company Name"
                            margin="normal"
                            name="company_name"
                            onBlur={handleBlur}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    {openAccountType && (
                      <TextField
                        error={Boolean(touched.position && errors.position)}
                        fullWidth
                        helperText={touched.position && errors.position}
                        label="Job Postition"
                        margin="normal"
                        name="position"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.position}
                        variant="outlined"
                      />
                    )}
                    {openAccountType && (
                      <TextField
                        error={Boolean(touched.division_id && errors.division_id)}
                        fullWidth
                        helperText={touched.division_id && errors.division_id}
                        label="Division Name"
                        margin="normal"
                        name="division_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.division_id}
                        variant="outlined"
                        select
                      >
                        {divisions ? (
                          divisions.map((division) => (
                            <MenuItem
                              value={division.DIVISION_ID}
                              key={division.DIVISION_ID}
                            >
                              {division.DIVISION}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                    {openAccountType && (
                      <TextField
                        error={Boolean(touched.sup_numb && errors.sup_numb)}
                        fullWidth
                        helperText={touched.sup_numb && errors.sup_numb}
                        label="Supervisor Number"
                        margin="normal"
                        name="sup_numb"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sup_numb}
                        variant="outlined"
                      />
                    )}
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      fullWidth
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      label="Confirm Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      variant="outlined"
                    />
                    <Box
                      alignItems="center"
                      display="flex"
                      ml={-1}
                    >
                      <Checkbox
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        I have read the
                        {' '}
                        <Link
                          color="primary"
                          component={RouterLink}
                          to="#"
                          underline="always"
                          variant="h6"
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>
                        {errors.policy}
                      </FormHelperText>
                    )}
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Create
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      User Information
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Username :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dataRegistered[0]?.EMPLOYEE_NUMBER}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Name :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dataRegistered[0]?.EMPLOYEE_NAME}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Account Type :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dataRegistered[0]?.ACCOUNT_TYPE}
                    </Typography>
                    <br />
                    {/* <UserDetails
                      selectedUser={dataRegistered}
                      divisionsactive={divisionsactive}
                      close={close}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      setOpenDialog={setOpenDialog}
                      divisionidofuser={divisionidofuser}
                      employeeNumberuser={employeeNumberuser}
                      accountType={accountType}
                    /> */}
                  </>
                )}
              </form>

            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

RegisterView.propTypes = {
  setRegister: PropTypes.func,
  divisions: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  allCompany: PropTypes.array,
  employeeNumberuser: PropTypes.string,
  divisionidofuser: PropTypes.string,
  divisionsactive: PropTypes.array,
  close: PropTypes.func,
  setOpenDialog: PropTypes.func
};

export default RegisterView;
