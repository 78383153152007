/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

import Page from 'src/components/Page';
import Popup from 'src/components/Popup';
import {
  fetchOneConsentAgreement,
} from '../../../actions/equipment';
import ApprovalEquipmentRequestForm from './ApprovalEquipmentRequestForm';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConsentAgreementViewer from '../../Administrator/EquipmentRequest/ConsentAgreementViewer';

function EquipmentRequestDetails({
  user,
  setOpenPopup,
  refresh,
  setRefresh,
  data,
  currentPage
}) {
  const dispatch = useDispatch();
  const [consentAgreementDetails, setConsentAgreementDetails] = useState(null);
  const [openConsentAgreement, setOpenConsentAgreement] = useState(false);
  const [tempInitialValue, setTempInitialValue] = useState({
    employeeNumber: data?.EMPLOYEE_NUMBER,
    equipmentItem: data?.EQUIPMENT_ITEM_ID,
    equipmentPackage: data?.EQUIPMENT_PACKAGE_ID,
    equipmentType: data?.EQUIPMENT_TYPE_ID,
    equipmentConsentAgreement: data?.CONSENT_AGREEMENT_ID,
    justification: data?.JUSTIFICATION,
  });

  const formatDate = (tempDate) => {
    if (tempDate !== null) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      const datetime = tempDate;
      const t = datetime.substring(0, 19).split(/[- .T:]/);

      // Apply each element to the Date function
      const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return d;
    }
    return null;
  };

  // This use effect will update form value when change
  // this is to solve dissapearing value when choose attachments
  useEffect(() => {
    dispatch(fetchOneConsentAgreement(data?.CONSENT_AGREEMENT_ID))
      .then((res) => {
        if (res?.success === true) {
          setConsentAgreementDetails(res?.consentAgreement[0]?.AGREEMENT_DETAILS);
        }
      });
    setTempInitialValue({
      ...tempInitialValue,
    });
  }, [
  ]);

  return (
    <Page
      title="Request Details"
    >
      <Box>
        <LoadingSpinner />
        <Box mb={3}>
          {data?.EQUIPMENT_REQUEST_STATUS === 'APR' && (
            <>
              <Alert
                severity="warning"
                action={(
                  <>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        window.open('#/virtual-prism-assistance', '_blank');
                      }}
                    >
                      Click here to learn more
                    </Button>
                    {/* <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        window.open('#/virtual-prism-assistance', '_blank');
                      }}
                    >
                      Click here to learn more
                    </Button> */}
                  </>
                )}
              >
                This &lsquo;Equipment Request&lsquo; has been Approved. Please raise a &lsquo;Service Request&lsquo; within 30 Days after the update date for the division in charge to start processing.
              </Alert>
            </>
          )}
          <Typography>
            Request Reference Number:
            <strong>{` ${data?.EQUIPMENT_REQUEST_REFNO}`}</strong>
            <br />
            Create Date:
            <strong>{` ${data?.EQUIPMENT_REQUEST_CREATE_DATE || data?.CREATE_DATE}`}</strong>
            <br />
            Update Date:
            <strong>{` ${data?.EQUIPMENT_REQUEST_UPDATE_DATE || data?.UPDATE_DATE || '-'}`}</strong>
            <br />
            Status:
            <strong>{` (${data?.EQUIPMENT_REQUEST_STATUS}) ${data?.STATUS_DESCRIPTION}`}</strong>
            <br />
            Request Status Detail:
            <strong>{` ${data?.EQUIPMENT_REQUEST_REMARKS}`}</strong>
            <br />
            {(data?.EQUIPMENT_REQUEST_PIC || data?.PIC !== null) && (
              <>
                Approval Pending at:
                <strong>{` (${data?.EQUIPMENT_REQUEST_PIC || data?.PIC}) ${data?.PIC_EMPLOYEE_NAME}`}</strong>
                <br />
              </>
            )}
            <br />
            <Divider />
            <br />
            Equipment Type:
            <strong>{` (${data?.EQUIPMENT_TYPE_ID}) ${data?.TYPE_NAME}`}</strong>
            <br />
            Equipment Item:
            <strong>{` ${data?.EQUIPMENT_ITEM_NAME}`}</strong>
            <br />
            Equipment Package:
            <strong>{` ${data?.EQUIPMENT_PACKAGE_NAME}`}</strong>
            <br />
            Consent Agreement:
            <Button
              aria-label="Click to view consent agreement"
              color="primary"
              disabled={consentAgreementDetails === null}
              variant="contained"
              onClick={() => {
                setOpenConsentAgreement(true);
              }}
            >
              <strong>{` ${data?.CONSENT_AGREEMENT_ID}`}</strong>
            </Button>
            <br />
            <br />
            <Divider />
            <br />
            Justification:
            <strong>
              {
                data?.JUSTIFICATION?.length > 0
                  ? (
                    data?.JUSTIFICATION.split('\n').map((l, i) => (
                      <>
                        {l !== '\r' ? (
                          <p key={i}>
                            {l || null}
                          </p>
                        ) : (
                          <>
                            <br />
                          </>
                        )}
                      </>
                    ))
                  ) : (
                    <p><i>No Description</i></p>
                  )
              }
            </strong>
          </Typography>
        </Box>
        <Divider />
        <Box mb={2} mt={3}>
          <Typography>Approval History</Typography>
        </Box>
        <Typography>
          {data?.HOD_APPROVAL_BY !== null && (
            <>
              <Box
                mt={1}
                mb={1}
                color={
                  (data?.HOD_APPROVAL_STATUS === 'APR' || data?.HODIV_APPROVAL_STATUS === 'APR' || data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'APR') ? ('rgba(0, 0, 0, 0.87)')
                    : (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'RJR' || data?.HODIV_APPROVAL_STATUS === 'RJR' || data?.HOD_APPROVAL_STATUS === 'RJR') ? ('#fff')
                      : ('text.primary')
                }
                border={1}
                borderColor="grey.200"
                borderRadius={6}
                bgcolor={
                  (data?.HOD_APPROVAL_STATUS === 'APR' || data?.HODIV_APPROVAL_STATUS === 'APR' || data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'APR') ? ('success.light')
                    : (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'RJR' || data?.HODIV_APPROVAL_STATUS === 'RJR' || data?.HOD_APPROVAL_STATUS === 'RJR') ? ('error.light')
                      : ('grey.200')
                }
              >
                <Box m={1}>
                  <Typography>
                    HEAD OF DEPARTMENT :
                    <strong>{`${data?.HOD_APPROVAL_BY || ''} - ${data?.HOD_NAME || null}`}</strong>
                    <br />
                    DATE :
                    <strong>{` ${formatDate(data?.HOD_APPROVAL_DATE) || '-'}`}</strong>
                  </Typography>
                  <Typography>
                    <Box
                      p={2}
                    >
                      {
                        data?.HOD_APPROVAL_DESCRIPTION?.length > 0
                          ? (
                            data?.HOD_APPROVAL_DESCRIPTION.split('\n').map((l, i) => (
                              <>
                                {l !== '\r' ? (
                                  <p key={i}>
                                    {l || null}
                                  </p>
                                ) : (
                                  <>
                                    <br />
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <p><i>No Description</i></p>
                          )
                      }
                    </Box>
                  </Typography>
                  <Typography>
                    Status :
                    <strong>{` (${data?.HOD_APPROVAL_STATUS}) ${data?.HOD_APPROVAL_STATUS_DESCRIPTION}`}</strong>
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          {data?.HODIV_APPROVAL_BY !== null && (
            <>
              <Box
                mt={1}
                mb={1}
                color={
                  (data?.HOD_APPROVAL_STATUS === 'APR') ? ('rgba(0, 0, 0, 0.87)')
                    : (data?.HOD_APPROVAL_STATUS === 'RJR') ? ('#fff')
                      : ('text.primary')
                }
                border={1}
                borderColor="grey.200"
                borderRadius={6}
                bgcolor={
                  (data?.HODIV_APPROVAL_STATUS === 'APR') ? ('success.light')
                    : (data?.HODIV_APPROVAL_STATUS === 'RJR') ? ('error.light')
                      : ('grey.200')
                }
              >
                <Box m={1}>
                  <Typography>
                    HEAD OF DIVISION :
                    <strong>{`${data?.HODIV_APPROVAL_BY || ''} - ${data?.HODIV_NAME || null}`}</strong>
                    <br />
                    DATE :
                    <strong>{` ${formatDate(data?.HODIV_APPROVAL_DATE) || '-'}`}</strong>
                  </Typography>
                  <Typography>
                    <Box
                      p={2}
                    >
                      {
                        data?.HODIV_APPROVAL_DESCRIPTION?.length > 0
                          ? (
                            data?.HODIV_APPROVAL_DESCRIPTION.split('\n').map((l, i) => (
                              <>
                                {l !== '\r' ? (
                                  <p key={i}>
                                    {l || null}
                                  </p>
                                ) : (
                                  <>
                                    <br />
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <p><i>No Description</i></p>
                          )
                      }
                    </Box>
                  </Typography>
                  <Typography>
                    Status :
                    <strong>{` (${data?.HODIV_APPROVAL_STATUS}) ${data?.HODIV_APPROVAL_STATUS_DESCRIPTION}`}</strong>
                  </Typography>
                </Box>
              </Box>
            </>
          )}

          {data?.REQUEST_TYPE_HODIV_APPROVAL_BY !== null && (
            <>
              <Box
                mt={1}
                mb={1}
                color={
                  (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'APR') ? ('rgba(0, 0, 0, 0.87)')
                    : (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'RJR') ? ('#fff')
                      : ('text.primary')
                }
                border={1}
                borderColor="grey.200"
                borderRadius={6}
                bgcolor={
                  (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'APR') ? ('success.light')
                    : (data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS === 'RJR') ? ('error.light')
                      : ('grey.200')
                }
              >
                <Box m={1}>
                  <Typography>
                    EQUIPMENT TYPE HEAD OF DIVISION :
                    <strong>{`${data?.REQUEST_TYPE_HODIV_APPROVAL_BY || ''} - ${data?.TYPE_HODIV_NAME || null}`}</strong>
                    <br />
                    DATE :
                    <strong>{` ${formatDate(data?.REQUEST_TYPE_HODIV_APPROVAL_DATE) || '-'}`}</strong>
                  </Typography>
                  <Typography>
                    <Box
                      p={2}
                    >
                      {
                        data?.REQUEST_TYPE_HODIV_APPROVAL_DESCRIPTION?.length > 0
                          ? (
                            data?.REQUEST_TYPE_HODIV_APPROVAL_DESCRIPTION.split('\n').map((l, i) => (
                              <>
                                {l !== '\r' ? (
                                  <p key={i}>
                                    {l || null}
                                  </p>
                                ) : (
                                  <>
                                    <br />
                                  </>
                                )}
                              </>
                            ))
                          ) : (
                            <p><i>No Description</i></p>
                          )
                      }
                    </Box>
                  </Typography>
                  <Typography>
                    Status :
                    <strong>{` (${data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS}) ${data?.REQUEST_TYPE_HODIV_APPROVAL_STATUS_DESCRIPTION}`}</strong>
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Typography>

      </Box>
      {currentPage === 'ApprovalView' && (
        <>
          <ApprovalEquipmentRequestForm
            data={data}
            user={user}
            setOpenPopup={setOpenPopup}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </>
      )}
      {openConsentAgreement === true && (
        <>
          <Popup
            openPopup={openConsentAgreement}
            setOpenPopup={setOpenConsentAgreement}
            fullScreen
            setServiceType=""
            title="Agreement"
          >
            <ConsentAgreementViewer
              data={consentAgreementDetails}
            />
          </Popup>
        </>
      )}
    </Page>
  );
}

EquipmentRequestDetails.propTypes = {
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  data: PropTypes.object,
  currentPage: PropTypes.string
};

export default EquipmentRequestDetails;
