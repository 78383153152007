import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateIndcator, deleteIndicator } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  noBorder: {
    color: 'black'
  },
}));

const IndicatorDetails = ({
  indicator,
  severities,
  close,
  employeeNumber,
  refresh,
  setRefresh,
  setOpenDialog,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [clickDelete, setClickDelete] = useState(false);
  const [divisionid, setClickDiv] = useState(indicator.division_id);
  const [divisionname, setClickDivname] = useState('');
  console.log(divisionid);

  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };
  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteIndicator(indicator.indicator_id))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
    setClickDelete(close);
    setRefresh(!refresh);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                indicator_id: indicator.indicator_id || '',
                severity_id: indicator.severity_id || '',
                indicator_description: indicator.indicator_description || '',
                division_id: indicator.division_id || '',
                division_name: indicator.division_name || '',
                update_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  indicator_description: Yup.string().max(255).required('Indicator description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateIndcator(indicator.indicator_id, values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpenDialog(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {clickDelete ? (
                    <>
                      <Box style={{ backgroundColor: red[50] }}>
                        <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                          Permanently remove indicator
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 70 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.indicator_id}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Description :
                        </Typography>
                        &nbsp;
                        <Box>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            name="indicator_description"
                            variant="outlined"
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              classes: { disabled: classes.noBorder }
                            }}
                            value={values.indicator_description}
                          />
                        </Box>
                        <br />
                        <br />
                      </Box>
                      <br />
                      <br />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={handleDelCancel}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography
                        color="primary"
                        style={{ fontWeight: 500 }}
                        variant="h6"
                      >
                        UPDATE INDICATOR
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        onClick={() => handleClickDelete()}
                      >
                        <Tooltip title="Delete Indicator">
                          <IconButton aria-label="delete">
                            <DeleteForeverIcon style={{ color: 'red' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <TextField
                        fullWidth
                        label="ID"
                        margin="normal"
                        name="indicator_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.indicator_id}
                        variant="outlined"
                        disabled
                      />
                      <TextField
                        error={Boolean(touched.severity_id && errors.severity_id)}
                        fullWidth
                        helperText={touched.severity_id && errors.severity_id}
                        label="Severity Level"
                        margin="normal"
                        name="severity_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.severity_id}
                        variant="outlined"
                        select
                      >
                        {severities ? (
                          severities.map((severity) => {
                            if (
                              severity.DIVISION_ID
                              === indicator.division_id
                            ) {
                              return (
                                <MenuItem
                                  height={20}
                                  value={severity.SEVERITY_ID}
                                  key={severity.SEVERITY_ID}
                                >
                                  {severity.SEVERITY_ID}
                                  {' ('}
                                  {severity.IMPACT}
                                  {') '}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                      <TextField
                        error={Boolean(touched.indicator_description
                          && errors.indicator_description)}
                        fullWidth
                        helperText={touched.indicator_description && errors.indicator_description}
                        label="Indicator Description"
                        margin="normal"
                        name="indicator_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.indicator_description}
                        variant="outlined"
                        multiline
                      />
                      &nbsp;
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          fullWidth
                        >
                          Update
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          fullWidth
                          onClick={close}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                indicator_id: indicator.indicator_id || '',
                severity_id: indicator.severity_id || '',
                indicator_description: indicator.indicator_description || '',
                division_id: divisionid || indicator.division_id || '',
                division_name: divisionname || indicator.division_name || '',
                update_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  indicator_description: Yup.string().max(255).required('Indicator description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateIndcator(indicator.indicator_id, values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpenDialog(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {clickDelete ? (
                    <>
                      <Box style={{ backgroundColor: red[50] }}>
                        <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                          Permanently remove indicator
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 70 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.indicator_id}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Description :
                        </Typography>
                        &nbsp;
                        <Box>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            name="indicator_description"
                            variant="outlined"
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              classes: { disabled: classes.noBorder }
                            }}
                            value={values.indicator_description}
                          />
                        </Box>
                        <br />
                        <br />
                      </Box>
                      <br />
                      <br />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={handleDelCancel}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography
                        color="primary"
                        style={{ fontWeight: 500 }}
                        variant="h6"
                      >
                        UPDATE INDICATOR
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Delete Indicator">
                          <IconButton aria-label="delete">
                            <DeleteForeverIcon
                              style={{ color: 'red' }}
                              onClick={() => handleClickDelete()}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <TextField
                        error={Boolean(touched.division_id && errors.division_id)}
                        fullWidth
                        helperText={touched.division_id && errors.division_id}
                        label="Select Division In Charge"
                        margin="normal"
                        name="division_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={values.division_id}
                        variant="outlined"
                      >
                        {divisions ? (
                          divisions.map((div) => (
                            <MenuItem
                              value={div.DIVISION_ID}
                              key={div.DIVISION_ID}
                              onClick={() => handleDivClick(div)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {div.DIVISION}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                      <TextField
                        fullWidth
                        label="ID"
                        margin="normal"
                        name="indicator_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.indicator_id}
                        variant="outlined"
                        disabled
                      />
                      <TextField
                        error={Boolean(touched.severity_id && errors.severity_id)}
                        fullWidth
                        helperText={touched.severity_id && errors.severity_id}
                        label="Severity Level"
                        margin="normal"
                        name="severity_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.severity_id}
                        variant="outlined"
                        select
                      >
                        {severities ? (
                          severities.map((severity) => {
                            /* if (
                              severity.DIVISION_ID
                              === indicator.division_id
                            ) {
                              return (
                                <MenuItem
                                  height={20}
                                  value={severity.SEVERITY_ID}
                                  key={severity.SEVERITY_ID}
                                >
                                  {severity.SEVERITY_ID}
                                </MenuItem>
                              );
                            } */
                            if (
                              severity.DIVISION_ID
                              === divisionid
                            ) {
                              return (
                                <MenuItem
                                  height={20}
                                  value={severity.SEVERITY_ID}
                                  key={severity.SEVERITY_ID}
                                >
                                  {severity.SEVERITY_ID}
                                  {' ('}
                                  {severity.IMPACT}
                                  {') '}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                      <TextField
                        error={Boolean(touched.indicator_description
                          && errors.indicator_description)}
                        fullWidth
                        helperText={touched.indicator_description && errors.indicator_description}
                        label="Indicator Description"
                        margin="normal"
                        name="indicator_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.indicator_description}
                        variant="outlined"
                        multiline
                      />
                      &nbsp;
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          fullWidth
                        >
                          Update
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          fullWidth
                          onClick={close}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

IndicatorDetails.propTypes = {
  indicator: PropTypes.object,
  severities: PropTypes.array,
  close: PropTypes.func,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenDialog: PropTypes.func,
  divisions: PropTypes.array,
  accountType: PropTypes.string,
};

export default IndicatorDetails;
