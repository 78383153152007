import React, { useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Captcha from 'demos-react-captcha';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../actions/user';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // height: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  }
}));

const SelfResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { token } = useParams();
  const [message, setMessage] = useState(null);
  const [empNumber, setEmpNumber] = useState('');
  const [tempcounter, setCounter] = useState('');
  const [captchabox, setCaptchabox] = useState(false);
  const [test, setTest] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const checkToken = (param) => {
    const decodedToken = decode(param);
    const employeeNumber = decodedToken?.id;
    setCounter(decodedToken?.counter);
    setEmpNumber(employeeNumber);
    // console.log(employeeNumber);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      setMessage('Token Expired. Please Contact IT Division for support.');
    }
  };

  const handlecaptchabox = () => {
    setCaptchabox((prev) => !prev);
    setTest(false);
  };

  const onChange = (value) => {
    setTest(value);
  };
  const onRefresh = () => {
    setTest(false);
  };

  useEffect(() => {
    checkToken(token);
  }, []);

  return (
    <>
      {message !== null ? (
        <>
          {message}
        </>
      ) : (
        <Page
          className={classes.root}
          title="Forgot Password"
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
              <Formik
                enableReinitialize
                initialValues={{
                  employee_number: empNumber,
                  password: '',
                  confirmPassword: '',
                  counter: tempcounter
                }}
                validationSchema={
                  Yup.object().shape({
                    password: Yup.string().max(255).required('password is required')
                      .matches(
                        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                      ),
                    confirmPassword: Yup
                      .string()
                      .required('Please confirm your password')
                      .when('password', {
                        is: (password) => (!!(password && password.length > 0)),
                        then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                      }),
                    captchabox: Yup.boolean().oneOf([true], 'This field must be checked')
                  })
                }
                onSubmit={
                  (values, { setSubmitting }) => {
                    dispatch(resetPassword(values))
                      .then((res) => {
                        if (res?.success === true) {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          history('/login');
                        } else {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          history('/login');
                          setSubmitting(false);
                        }
                      })
                      .catch();
                  }
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      pt={3}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography
                        color="primary"
                        variant="h2"
                      >
                        Reset Password
                      </Typography>
                    </Box>
                    <br />
                    <br />
                    <Box
                      m={2}
                      pb={5}
                    >
                      <Box>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="New Password"
                          margin="normal"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                        <TextField
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          fullWidth
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          label="Confirm New Password"
                          margin="normal"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.confirmPassword}
                          variant="outlined"
                        />
                        <Box
                          alignItems="center"
                          display="flex"
                          ml={-1}
                        >
                          <Checkbox
                            name="captchabox"
                            onClick={handlecaptchabox}
                          />
                          <Typography
                            color="textSecondary"
                            variant="body1"
                          >
                            I am not a robot
                          </Typography>
                        </Box>
                        {captchabox ? (
                          <>
                            <Captcha onChange={onChange} placeholder="Enter captcha" onRefresh={onRefresh} />
                          </>
                        ) : null}
                        {Boolean(touched.captchabox && errors.captchabox) && (
                          <FormHelperText error>
                            {errors.captchabox}
                          </FormHelperText>
                        )}
                        <Box my={2}>
                          <Button
                            color="primary"
                            disabled={test === false}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            RESET
                          </Button>
                        </Box>
                      </Box>

                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      )}
    </>
  );
};

export default SelfResetPassword;
