import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ModuleDetails from './ModuleDetails';
import CategoryDetails from './CategoryDetails';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ListCategory = ({
  categories,
  modules,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  departments,
  accountType
}) => {
  const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [opencategory, setOpenCategory] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowCatg, setSelectedRowCatg] = useState(null);

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleOnClickCatg = () => {
    setOpenCategory(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCategory(false);
  };

  let categorieslist = [];
  let moduleslist = [];
  let SAcategorieslist = [];
  let SAmoduleslist = [];

  if (categories) {
    categorieslist = categories.map((category) => (
      {
        category_id: category.CATEGORY_ID,
        category_name: category.CATEGORY_NAME || null,
        department_id: category.DEPARTMENT_ID || null,
        department_name: category.DEPARTMENT_NAME || null,
        division_id: category.DIVISION_ID || null,
        division_name: category.DIVISION_NAME || null,
        service_type: (category.SERVICE_TYPE === 'ticket request' ? 'BOTH' : category.SERVICE_TYPE?.toUpperCase()) || null,
        external_flag: category.EXTERNAL_FLAG || null,
        location_flag: category.LOCATION_FLAG || null,
        category_status: category.CATEGORY_STATUS || null
      }
    )).filter((row) => row.division_id === divisionid) || [];
    SAcategorieslist = categories.map((category) => (
      {
        category_id: category.CATEGORY_ID,
        category_name: category.CATEGORY_NAME || null,
        department_id: category.DEPARTMENT_ID || null,
        department_name: category.DEPARTMENT_NAME || null,
        division_id: category.DIVISION_ID || null,
        division_name: category.DIVISION_NAME || null,
        service_type: (category.SERVICE_TYPE === 'ticket request' ? 'BOTH' : category.SERVICE_TYPE?.toUpperCase()) || null,
        external_flag: category.EXTERNAL_FLAG || null,
        location_flag: category.LOCATION_FLAG || null,
        category_status: category.CATEGORY_STATUS || null
      }
    ));
  }
  if (modules) {
    moduleslist = modules.map((module) => (
      {
        module_id: module.MODULE_ID,
        module_description: module.MODULE_DESCRIPTION || null,
        category_id: module.CATEGORY_ID,
        category_name: module.CATEGORY_NAME || null,
        department_id: module.DEPARTMENT_ID || null,
        department_name: module.DEPARTMENT_NAME || null,
        division_id: module.DIVISION_ID || null
      }
    )).filter((row) => row.division_id === divisionid) || [];
  }
  if (modules) {
    SAmoduleslist = modules.map((module) => (
      {
        module_id: module.MODULE_ID,
        module_description: module.MODULE_DESCRIPTION || null,
        category_id: module.CATEGORY_ID,
        category_name: module.CATEGORY_NAME || null,
        department_id: module.DEPARTMENT_ID || null,
        department_name: module.DEPARTMENT_NAME || null,
        division_id: module.DIVISION_ID || null
      }
    ));
  }
  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        CATEGORY
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'category_id' },
            { title: 'Category', field: 'category_name' },
            { title: 'Department', field: 'department_name' },
          ]}
          data={categorieslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClickCatg();
            setSelectedRowCatg(selectedRowData);
          })}
          detailPanel={(rowData) => {
            return (
              <MaterialTable
                icons={tableIcons}
                title=" "
                columns={[
                  { title: 'ID', field: 'module_id' },
                  { title: 'Module', field: 'module_description' }
                ]}
                data={moduleslist.filter((a) => a.category_id === rowData.category_id)}
                options={{
                  search: true,
                  grouping: true,
                  rowStyle: () => ({
                    fontFamily: 'helvetica',
                  })
                }}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
                onRowClick={((event, selectedRowData) => {
                  handleOnClick();
                  setSelectedRow(selectedRowData);
                })}
              />
              // )
            );
          }}
        />
      ) : (
        // SUPER ADMIN VIEW
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'category_id' },
            { title: 'Category', field: 'category_name' },
            { title: 'Division', field: 'division_name' },
            { title: 'Type', field: 'service_type' }
          ]}
          data={SAcategorieslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClickCatg();
            setSelectedRowCatg(selectedRowData);
          })}
          detailPanel={(rowData) => {
            return (
              <MaterialTable
                icons={tableIcons}
                title=" "
                columns={[
                  { title: 'ID', field: 'module_id' },
                  { title: 'Module', field: 'module_description' }
                ]}
                data={SAmoduleslist.filter((a) => a.category_id === rowData.category_id)}
                options={{
                  search: true,
                  grouping: true,
                  rowStyle: () => ({
                    fontFamily: 'helvetica',
                  })
                }}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
                onRowClick={((event, selectedRowData) => {
                  handleOnClick();
                  setSelectedRow(selectedRowData);
                })}
              />
              // )
            );
          }}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Module
        </DialogTitle>
        <DialogContent dividers>
          <ModuleDetails
            selectedmodule={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpen={setOpen}
            divisionid={divisionidofuser}
            categories={categories}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opencategory}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Category
        </DialogTitle>
        <DialogContent dividers>
          <CategoryDetails
            selectedcategory={selectedRowCatg}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpen={setOpenCategory}
            divisionid={divisionidofuser}
            departments={departments}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

ListCategory.propTypes = {
  categories: PropTypes.array,
  modules: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  departments: PropTypes.array,
  accountType: PropTypes.string,
};

export default ListCategory;
