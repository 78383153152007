import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  // TextField,
  // MenuItem,
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// import { fetchOtherDivisionUser } from '../../../actions/user';
import { acknowledgeTicket } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%'
  }
}));

function AssignTicketForm({
  user,
  serviceRefno,
  setOpenPopup,
  refresh,
  setRefresh
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  // const handleClose = () => {
  //   setOpenPopup(false);
  // };

  useEffect(() => {
    // dispatch(fetchOtherDivisionUser(userEmployeeNumber, operation))
    //   .then((res) => {
    //     if (res?.success === true) {
    //       setListOfPeople(res?.listOfPeople);
    //       dispatch(fetchSeverity())
    //         .then((res2) => {
    //           if (res2?.success === true) {
    //             setListOfSeverity(res2.severity);
    //             dispatch(fetchModule())
    //               .then((res3) => {
    //                 if (res3?.success === true) {
    //                   setListOfModule(res3?.modules);
    //                 }
    //               });
    //           }
    //         });
    //     }
    //   });
  }, []);

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          serviceRefno,
          employeeNumber: userEmployeeNumber,
          description: '',
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          dispatch(acknowledgeTicket(values))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setRefresh(!refresh);
                setOpenPopup(true);
              } else if (res?.success === false) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch((err) => {
              console.log('error : ', err);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>

            <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                margin="normal"
                name="description"
                aria-label="maximum height"
                placeholder="Leave blank for auto-generate description."
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
              // defaultValue={`This ticket is assigned to ${tempEmpName}.`}
              />
            </Box>

            <Button
              id="submit"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Start Job
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

AssignTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.bool,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
};

export default AssignTicketForm;
