/* eslint-disable no-loop-func */
export function maxSelectFile(event) {
  const { files } = event.target; // create file object
  if (files.length > 3) {
    const msg = 'Only 3 file can be uploaded at a time';
    event.target.value = null; // discard selected file
    console.log(msg);
    return { success: false, message: msg || 'Error' };
  }
  return { success: true, message: '' };
}

export const checkFileSize = (event) => {
  const { files } = event.target;
  const size = 5100000;
  let err = '';

  for (let x = 0; x < files.length; x++) {
    if (files[x].size > size) {
      err += `${files[x].name || files[x].type} is too large, please pick a file that is less than 5MB\n`;
    }
  }
  if (err !== '') {
    event.target.value = null;
    console.log(err);
    return { success: false, message: err || 'Error' };
  }

  return { success: true, message: '' };
};

export const checkMimeType = (event) => {
  let x = 0;
  // getting file object
  const { files } = event.target;
  // define message container
  let err = '';
  // list allow mime type
  const types = ['image/png', 'image/jpeg', 'image/gif'];
  // loop access array
  for (x = 0; x < files.length; x++) {
    // compare file type find doesn't matach
    if (types.every((type) => files[x].type !== type)) {
      // create error message and assign to container
      err += `${files[x].type} is not a supported format\n`;
    }
  }

  if (err !== '') { // if message not same old that mean has error
    event.target.value = null; // discard selected file
    console.log(err);
    return false;
  }
  return true;
};
