import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  MenuItem,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateModule, deleteModule } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ModuleDetails = ({
  selectedmodule,
  close,
  divisionid,
  employeeNumber,
  refresh,
  setRefresh,
  setOpen,
  categories,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clickDelete, setClickDelete] = useState(false);
  const [categoryid, setCategoryId] = useState('');
  const [categoryname, setCategoryName] = useState('');
  const [departmentname, setDepartmentname] = useState('');
  const [departmentid, setDepartmentid] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleCategoryClick = (category) => {
    setCategoryName(category?.CATEGORY_NAME);
    setCategoryId(category?.CATEGORY_ID);
    setDepartmentname(category?.DEPARTMENT_NAME);
    setDepartmentid(category?.DEPARTMENT_ID);
  };

  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteModule(selectedmodule.module_id))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
    setClickDelete(close);
    setRefresh(!refresh);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  return (
    <Page
      className={classes.root}
      title="Module"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {/* {categoryid !== '' && (
            <> */}
          <Formik
            enableReinitialize
            initialValues={{
              module_id: selectedmodule.module_id || '',
              module_description: selectedmodule.module_description || '',
              category_id: selectedmodule.category_id || categoryid || '',
              update_by: employeeNumber || '',
              department_id: departmentid || selectedmodule.department_id || '',
              department_name: departmentname || selectedmodule.department_name || '',
              category_name: categoryname || selectedmodule.category_name || '',
            }}
            validationSchema={
              Yup.object().shape({
                module_description: Yup.string().max(255).required('Description is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateModule(selectedmodule.module_id, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpen(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // resetForm,
              /* setFieldValue, */
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {clickDelete ? (
                  <>
                    <Box style={{ backgroundColor: red[50] }}>
                      <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                        Permanently remove module
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        ID
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 70 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.module_id}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Description :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.module_description}
                      </Typography>
                      <br />
                      <br />
                    </Box>
                    <br />
                    <br />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        // type="reset"
                        variant="contained"
                        // fullWidth
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        // fullWidth
                        onClick={handleDelCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                      variant="h6"
                    >
                      UPDATE MODULE
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Tooltip title="Delete Module">
                        <IconButton aria-label="delete">
                          <DeleteForeverIcon
                            style={{ color: 'red' }}
                            onClick={() => handleClickDelete()}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {accountType === 'Super Admin' ? (
                      <TextField
                        error={Boolean(touched.category_id && errors.category_id)}
                        fullWidth
                        helperText={touched.category_id && errors.category_id}
                        label="Select Category"
                        margin="normal"
                        name="category_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={values.category_id}
                        variant="outlined"
                        multiline
                      >
                        {categories ? (
                          categories.map((category) => (
                            <MenuItem
                              height={20}
                              value={category.CATEGORY_ID}
                              key={category.CATEGORY_ID}
                              onClick={() => handleCategoryClick(category)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {category.CATEGORY_NAME}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                    ) : (
                      <TextField
                        error={Boolean(touched.category_id && errors.category_id)}
                        fullWidth
                        helperText={touched.category_id && errors.category_id}
                        label="Select Category"
                        margin="normal"
                        name="category_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={values.category_id}
                        variant="outlined"
                        multiline
                      >
                        {categories ? (
                          categories.map((category) => {
                            if (
                              category.DIVISION_ID
                              === divisionid
                            ) {
                              return (
                                <MenuItem
                                  height={20}
                                  value={category.CATEGORY_ID}
                                  key={category.CATEGORY_ID}
                                  onClick={() => handleCategoryClick(category)}
                                  style={{ whiteSpace: 'unset' }}
                                >
                                  {category.CATEGORY_NAME}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                    )}
                    <TextField
                      error={Boolean(touched.module_id && errors.module_id)}
                      fullWidth
                      helperText={touched.module_id && errors.module_id}
                      label="ID"
                      margin="normal"
                      name="module_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.module_id}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      error={Boolean(touched.module_description && errors.module_description)}
                      fullWidth
                      helperText={touched.module_description && errors.module_description}
                      label="Module"
                      margin="normal"
                      name="module_description"
                      onBlur={handleBlur}
                      value={values.module_description}
                      onChange={handleChange}
                      variant="outlined"
                    />
                    &nbsp;
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        Update
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        fullWidth
                        onClick={close}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

ModuleDetails.propTypes = {
  selectedmodule: PropTypes.object,
  divisionid: PropTypes.string,
  close: PropTypes.func,
  categories: PropTypes.array,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpen: PropTypes.func,
  accountType: PropTypes.string,
};

export default ModuleDetails;
