/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
import * as api from '../api/index';

export const listOfEquipmentType = (division) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfEquipmentType(division)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfAllEquipmentType = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfAllEquipmentType()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const addEquipmentType = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addEquipmentType(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateEquipmentType = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateEquipmentType(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const deleteEquipmentType = (typeId, employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.deleteEquipmentType(typeId, employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const listOfEquipmentItem = (division) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfEquipmentItem(division)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfAllEquipmentItem = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfAllEquipmentItem()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const addEquipmentItem = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addEquipmentItem(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateEquipmentItem = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateEquipmentItem(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const deleteEquipmentItem = (typeId, employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.deleteEquipmentItem(typeId, employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const listOfEquipmentPackage = (division) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfEquipmentPackage(division)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfAllEquipmentPackage = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfAllEquipmentPackage()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const addEquipmentPackage = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addEquipmentPackage(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateEquipmentPackage = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateEquipmentPackage(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const deleteEquipmentPackage = (typeId, employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.deleteEquipmentPackage(typeId, employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const listOfConsentAgreement = (division) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfConsentAgreement(division)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfAllConsentAgreement = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfAllConsentAgreement()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const addConsentAgreement = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addConsentAgreement(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateConsentAgreement = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateConsentAgreement(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const deleteConsentAgreement = (agreementId, employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.deleteConsentAgreement(agreementId, employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const listOfActiveEquipmentType = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfActiveEquipmentType()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfActiveEquipmentItem = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfActiveEquipmentItem()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfActiveEquipmentPackage = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfActiveEquipmentPackage()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listOfActiveConsentAgreement = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.listOfActiveConsentAgreement()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const createEquipmentRequest = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const responds = await api.createEquipmentRequest(data)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return responds;
  } catch (error) {
    return (error);
  }
};

export const fetchEquipmentRequest = (empno) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.fetchEquipmentRequest(empno)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const respondEquipmentRequestApproval = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.respondEquipmentRequestApproval(formData)
      .then((res) => {
        if (res?.data) {
          return res?.data;
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    console.log('service error : ', error);
    return error;
  }
};

export const fetchOneConsentAgreement = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.fetchOneConsentAgreement(id)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};
