import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateSeverity } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SeverityDetails = ({
  severity,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  setOpenDialog,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [divisionid, setClickDiv] = useState('');
  const [divisionname, setClickDivname] = useState('');

  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                severity_id: severity.severity_id || '',
                impact: severity.impact || '',
                assign_interval: severity.assign_interval || '',
                escalation_interval: severity.escalation_interval || '',
                resolution_interval: severity.resolution_interval || '',
                division_id: divisionidofuser,
                division_name: severity.division_name || '',
                update_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  impact: Yup.string().max(51).required('Severity level is required'),
                  assign_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  escalation_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  resolution_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateSeverity(severity.severity_id, values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpenDialog(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                    variant="h6"
                  >
                    UPDATE SEVERITY
                  </Typography>
                  <TextField
                    fullWidth
                    label="ID"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    error={Boolean(touched.impact && errors.impact)}
                    fullWidth
                    helperText={touched.impact && errors.impact}
                    label="Impact"
                    margin="normal"
                    name="impact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.impact}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    error={Boolean(touched.assign_interval && errors.assign_interval)}
                    fullWidth
                    helperText={touched.assign_interval && errors.assign_interval}
                    label="Assign Interval (in hour)"
                    margin="normal"
                    name="assign_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.assign_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                  />
                  <TextField
                    error={Boolean(touched.escalation_interval && errors.escalation_interval)}
                    fullWidth
                    helperText={touched.escalation_interval && errors.escalation_interval}
                    label="Escalation Interval (in hour)"
                    margin="normal"
                    name="escalation_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.escalation_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.resolution_interval && errors.resolution_interval)}
                    fullWidth
                    helperText={touched.resolution_interval && errors.resolution_interval}
                    label="Resolution Interval (in hour)"
                    margin="normal"
                    name="resolution_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.resolution_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  &nbsp;
                  <br />
                  <Box
                    alignItems="center"
                    justifyContent="flex-end"
                  />
                  <div>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Update
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                severity_id: severity.severity_id || '',
                impact: severity.impact || '',
                assign_interval: severity.assign_interval || '',
                escalation_interval: severity.escalation_interval || '',
                resolution_interval: severity.resolution_interval || '',
                division_id: divisionid || severity.division_id || '',
                division_name: divisionname || severity.division_name || '',
                update_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  impact: Yup.string().max(51).required('Severity level is required'),
                  assign_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  escalation_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  resolution_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateSeverity(severity.severity_id, values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpenDialog(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                    variant="h6"
                  >
                    UPDATE SEVERITY
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    fullWidth
                    label="ID"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    error={Boolean(touched.impact && errors.impact)}
                    fullWidth
                    helperText={touched.impact && errors.impact}
                    label="Impact"
                    margin="normal"
                    name="impact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.impact}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    error={Boolean(touched.assign_interval && errors.assign_interval)}
                    fullWidth
                    helperText={touched.assign_interval && errors.assign_interval}
                    label="Assign Interval (in hour)"
                    margin="normal"
                    name="assign_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.assign_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                  />
                  <TextField
                    error={Boolean(touched.escalation_interval && errors.escalation_interval)}
                    fullWidth
                    helperText={touched.escalation_interval && errors.escalation_interval}
                    label="Escalation Interval (in hour)"
                    margin="normal"
                    name="escalation_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.escalation_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.resolution_interval && errors.resolution_interval)}
                    fullWidth
                    helperText={touched.resolution_interval && errors.resolution_interval}
                    label="Resolution Interval (in hour)"
                    margin="normal"
                    name="resolution_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.resolution_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  &nbsp;
                  <br />
                  <Box
                    alignItems="center"
                    justifyContent="flex-end"
                  />
                  <div>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Update
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

SeverityDetails.propTypes = {
  severity: PropTypes.object,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenDialog: PropTypes.func,
  divisions: PropTypes.array,
  accountType: PropTypes.string,
};

export default SeverityDetails;
