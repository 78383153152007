/* eslint-disable no-unused-expressions */
/* eslint-disable import/named */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  TextField,
  CardHeader,
  Divider,
  Paper,
  Card
} from '@material-ui/core';
// import {
//   MobileDateRangePicker,
//   DateRangePicker
// } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import os from 'os';

import Page from 'src/components/Page';
import { fetchAccountType } from '../../../actions/user';
import {
  fetchServiceMonitorPermission,
  fetchServiceMonitorData
} from '../../../actions/report';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import ServiceCard from './ServiceCard';
import ServiceTable from './ServiceTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

// Need division based on employee number
const ServiceMonitor = () => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))?.result || []);
  const [accountType, setAccountType] = useState(JSON.parse(localStorage.getItem('profile'))?.accountType || '');
  const [listOfService, setListOfService] = useState([]);
  const [grant, setGrant] = useState(false);
  const [serviceAmount, setServiceAmount] = useState({});
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState('');
  const [tempTableTitle, setTempTableTitle] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  useEffect(() => {
    setUser(user);
    dispatch(fetchAccountType(user?.EMPLOYEE_NUMBER || null))
      .then((res) => {
        if (res?.success === true) {
          setAccountType(res.accountType);
          setUser(res.userInfo);
        } else if (res?.success === false) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          if (res?.relogin === true) {
            history('/login');
          }
        }
      })
      .catch((err) => {
        // Something
        console.log(err);
      });

    dispatch(fetchServiceMonitorPermission(user?.EMPLOYEE_NUMBER || null))
      .then((res) => {
        setGrant(res?.success || false);
        if (res?.success === false) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          if (res?.relogin === true) {
            history('/login');
          }
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          setListOfService(res?.header || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Service Report - Prism"
    >
      {
        (accountType !== 'User' && accountType !== 'Vendor User') && (
          <>
            {grant === true && (
              <>
                <Typography
                  variant="h1"
                  component="h2"
                  gutterBottom
                  color="primary"
                >
                  <Box
                    fontFamily="Roboto"
                    display="flex"
                    justifyContent="center"
                  >
                    <Box>
                      Service Report
                    </Box>
                  </Box>
                </Typography>
                <Container maxWidth={false}>
                  <Typography component="div">
                    <Box>
                      {`${user?.DIVISION_NAME} `}
                      Summary
                    </Box>
                  </Typography>
                  <br />
                  <Formik
                    enableReinitialize
                    initialValues={{
                      startDate: tempStartDate,
                      endDate: tempEndDate,
                      employeeNumber: user?.EMPLOYEE_NUMBER
                    }}
                    validationSchema={
                      Yup.object().shape({
                        startDate: Yup.date().required('Please select a start date.'),
                        endDate: Yup.date().required('Please select an end date.'),
                      })
                    }
                    onSubmit={(values, { setSubmitting }) => {
                      setListOfService([]);
                      dispatch(fetchServiceMonitorData(values))
                        .then((res) => {
                          if (res?.success === true) {
                            enqueueSnackbar({
                              message: res?.message,
                              options: {
                                key: new Date().getTime() + Math.random(),
                                action: (key) => (
                                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                                ),
                                success: res?.success,
                                notifier: res?.notifier || null,
                              },
                            });
                            setServiceAmount({
                              total: res?.total || 0,
                              assigned: res?.assigned || 0,
                              closed: res?.closed || 0,
                              solved: res?.solved || 0,
                              unassigned: res?.unassigned || 0,
                              withdrawn: res?.withdrawn || 0,
                            });
                            setSubmitting(false);
                          } else {
                            enqueueSnackbar({
                              message: res?.message,
                              options: {
                                key: new Date().getTime() + Math.random(),
                                action: (key) => (
                                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                                ),
                                success: res?.success,
                                notifier: res?.notifier || null,
                              },
                            });
                            setSubmitting(false);
                            if (res?.relogin === true) {
                              history('/login');
                            }
                          }
                        })
                        .catch();
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TextField
                            id="startDate"
                            onBlur={handleBlur}
                            error={Boolean(touched.startDate
                              && errors.startDate)}
                            helperText={touched.startDate
                              && errors.startDate}
                            values={values.startDate}
                            onChange={(e) => {
                              setTempStartDate(e.target.value);
                              handleChange;
                            }}
                            label="Start Date"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Typography component="div">
                            <Box
                              p={2}
                              pr={4}
                              pl={4}
                            >
                              to
                            </Box>
                          </Typography>
                          <TextField
                            id="endDate"
                            onBlur={handleBlur}
                            error={Boolean(touched.endDate
                              && errors.endDate)}
                            helperText={touched.endDate
                              && errors.endDate}
                            values={values.endDate}
                            onChange={(e) => {
                              handleChange;
                              setTempEndDate(e.target.value);
                            }}
                            label="End Date"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>

                        <br />
                        <br />
                        <Button
                          id="submit"
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={handleChange}
                        >
                          get service
                        </Button>
                      </form>
                    )}
                  </Formik>
                  <br />
                  {Object.keys(serviceAmount).length !== 0 && (
                    <>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.total,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate
                            }}
                            title="Total"
                            status=""
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.withdrawn,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate
                            }}
                            title="Withdrawn"
                            status="WDW"
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.closed,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate
                            }}
                            title="Closed"
                            status="SCL"
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.solved,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate
                            }}
                            title="Solved"
                            status="SOL"
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.assigned,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate

                            }}
                            title="Assigned"
                            status="ASG"
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          sm={6}
                          xl={3}
                          xs={12}
                        >
                          <ServiceCard
                            data={{
                              amount: serviceAmount?.unassigned,
                              employeeNumber: user?.EMPLOYEE_NUMBER,
                              startDate: tempStartDate,
                              endDate: tempEndDate
                            }}
                            title="Unassigned"
                            status="NEW"
                            setListOfService={setListOfService}
                            user={user}
                            setTempTableTitle={setTempTableTitle}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Container>
                <br />
                <Container>
                  {listOfService?.length !== 0 && (
                    <>
                      <Box>
                        <Paper>
                          <Card>
                            <CardHeader
                              title={tempTableTitle}
                              subheader="List of service with above status"
                            />
                            <Divider />
                            <ServiceTable
                              service={listOfService}
                              user={user}
                            />
                          </Card>
                        </Paper>
                      </Box>
                    </>
                  )}
                </Container>
              </>
            )}
          </>
        )
      }
    </Page>
  );
};

export default ServiceMonitor;
