/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import {
  Button,
  Box,
  Card,
  // makeStyles,
  Divider,
  // Typography,
  TextField,
  Paper,
  CardHeader,
  InputAdornment,
  IconButton,

} from '@material-ui/core';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';

import DivisionTicketTable from './DivisionTicketTable';
import AssignedTicketTable from './AssignedTicketTable';
import InProgressTicketTable from './InProgressTicketTable';
import SolutionProposedTicketTable from './SolutionProposedTicketTable';
import { fetchTicketDetails } from '../../../actions/service';
import Popup from '../../../components/Popup';
import TicketDetails from '../../../components/TicketDetails';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

function ManageView({
  newTicket,
  assignedTicket,
  handleTicket,
  solutionProposedTicket,
  user,
  refresh,
  setRefresh,
  accountType
}) {
  const dispatch = useDispatch();
  const [tempHeader, setTempHeader] = useState(null);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  const [tempServiceNumber, setTempServiceNumber] = useState('');
  const currentPage = 'ManageView';

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleSearchService = (serviceNumber) => {
    if (serviceNumber !== null && serviceNumber !== '') {
      dispatch(fetchTicketDetails(serviceNumber))
        .then((res) => {
          if (res?.data?.success === true) {
            setTempHeader({ serviceRefno: res?.data?.header[0]?.SERVICE_REFNO });
            setOpenTicketDetails(true);
            enqueueSnackbar({
              message: res?.data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                ),
                success: res?.data.notifier,
              },
            });
          } else {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                ),
                success: res?.notifier,
              },
            });
          }
        });
    }
  };

  return (
    <>
      {
        (accountType === 'Division User'
          || accountType === 'Division Admin'
          || accountType === 'Super Admin')
        && (
          <>
            <Box
              mb={2}
              sx={{ display: 'flex', alignItems: 'flex-end' }}
            >
              <TextField
                id="standard-basic"
                label="Search Service Number"
                variant="outlined"
                size="large"
                fullWidth
                type="Text"
                inputProps={{
                  maxLength: 15,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        edge="end"
                        onClick={() => handleSearchService(tempServiceNumber)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )

                }}
                onChange={(event) => {
                  setTempServiceNumber(event.target.value);
                }}
              />

            </Box>
            <Box mb={2}>
              <Paper>
                <Card>
                  <Box>
                    <CardHeader
                      title="Division Service"
                      subheader="Unassigned service"
                    />
                    <Divider />
                    <Box>
                      <DivisionTicketTable
                        newTicket={newTicket || null}
                        user={user}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        accountType={accountType}
                        currentPage={currentPage}
                      />
                    </Box>
                  </Box>
                </Card>
              </Paper>
            </Box>
          </>
        )
      }

      <Box mb={2} mt={2}>
        <Paper>
          <Card>
            <Box>
              <CardHeader
                title="New Assigned Service"
                subheader="Service assigned to you"
              />
              <Divider />
              <Box>
                <AssignedTicketTable
                  assignedTicket={assignedTicket || null}
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  accountType={accountType}
                />
              </Box>
            </Box>
          </Card>
        </Paper>
      </Box>

      <Box mb={2} mt={2}>
        <Paper>
          <Card>
            <Box>
              <CardHeader
                title="Service In-Progress"
                subheader="Service that you are working on"
              />
              <Divider />
              <Box>
                <InProgressTicketTable
                  handleTicket={handleTicket}
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  accountType={accountType}
                />
              </Box>
            </Box>
          </Card>
        </Paper>
      </Box>

      {
        (accountType === 'Division User'
          || accountType === 'Division Admin'
          || accountType === 'Super Admin')
        && (
          <>
            <Box mb={2} mt={2}>
              <Paper>
                <Card>
                  <Box>
                    <CardHeader
                      title="Solutioned Proposed Service"
                      subheader="Waiting to be closed"
                    />
                    <Divider />
                    <Box>
                      <SolutionProposedTicketTable
                        solutionProposedTicket={solutionProposedTicket}
                        user={user}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        accountType={accountType}
                      />
                    </Box>
                  </Box>
                </Card>
              </Paper>
            </Box>
            <Popup
              openPopup={openTicketDetails}
              setOpenPopup={setOpenTicketDetails}
              title="Details"
            >
              <TicketDetails
                ticket={tempHeader}
                user={user}
                openPopup={openTicketDetails}
                setOpenPopup={setOpenTicketDetails}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Popup>
          </>
        )
      }
    </>
  );
}

ManageView.propTypes = {
  newTicket: PropTypes.object,
  assignedTicket: PropTypes.array,
  handleTicket: PropTypes.array,
  solutionProposedTicket: PropTypes.array,
  // categories: PropTypes.object,
  user: PropTypes.object,
  // peoples: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
};

export default ManageView;
