import React from 'react';

export default function Chatbot() {
  const backendLink = process.env.REACT_APP_BACKEND_URL;
  const fullChatbotLink = `${backendLink.slice(0, -4)}/chatBot.html`;

  return (
    // eslint-disable-next-line
    <iframe
      src={fullChatbotLink}
      title="Prism Virtual Bot"
      frameBorder="0"
      style={{
        height: '100%',
        width: '100%'
      }}
    />
  );
}
