/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  // TextField,
  makeStyles,
  TextareaAutosize
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';

import { fetchUserInfo } from '../../../actions/user';
import { respondApproval } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    // marginRight: '50',
    // border: 1,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f',
    }
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function ApprovalTicketForm({
  serviceRefno,
  user,
  // setOpenPopup,
  refresh,
  setRefresh,
  pic
}) {
  const dispatch = useDispatch();
  const [picInfo, setPicInfo] = useState([]);
  const classes = useStyles();
  const [response, setResponse] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [tempDescription, setTempDescription] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleClick = (clickData) => {
    setResponse(clickData);
    setOpenConfirmation(true);
  };

  useEffect(() => {
    dispatch(fetchUserInfo(pic))
      .then((res) => {
        setPicInfo(res?.userInfo[0] || []);
      });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          serviceRefno,
          employeeNumber: user?.EMPLOYEE_NUMBER,
          description: tempDescription,
          response,
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500).required('Please input approval remarks.'),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          if (values.description.length <= 0) {
            setSubmitting(false);
          } else {
            dispatch(respondApproval(values))
              .then((res) => {
                if (res?.success === true) {
                  enqueueSnackbar({
                    message: res?.message || 'Error',
                    options: {
                      key: new Date().getTime() + Math.random(),
                      action: (key) => (
                        <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                      ),
                      success: res?.success || false,
                      notifier: res?.notifier || 'warning',
                    }
                  });
                  setRefresh(!refresh);
                } else {
                  setSubmitting(false);
                  enqueueSnackbar({
                    message: res?.message || 'Error',
                    options: {
                      key: new Date().getTime() + Math.random(),
                      action: (key) => (
                        <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                      ),
                      success: res?.success || false,
                      notifier: res?.notifier || 'warning',
                    }
                  });
                }
              })
              .catch((err) => {
                console.log('error lah : ', err);
              });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              bgcolor="common.white"
              color="text.primary"
              m={2}
              borderRadius={12}
            >
              {openConfirmation === false ? (
                <>
                  <Typography>
                    <Box fontWeight="fontWeightBold" m={1} pt={1}>
                      Details
                    </Box>
                    <Box m={1}>
                      <Box pb={2}>
                        This approval is requested by
                      </Box>
                      <Box
                        pb={2}
                      >
                        <b>Employee Number :</b>
                        {' '}
                        {picInfo.EMPLOYEE_NUMBER}
                        <br />
                        <b>Employee Name :</b>
                        {' '}
                        {picInfo.EMPLOYEE_NAME}
                      </Box>
                      <Box fontStyle="italic" pt={2}>
                        A response is needed before further actions can be taken.
                        Please reconfirm the request before responding.
                      </Box>
                    </Box>
                  </Typography>
                  <Box m={2}>
                    <TextareaAutosize
                      rowsMax={10}
                      rowsMin={10}
                      margin="normal"
                      name="description"
                      aria-label="maximum height"
                      placeholder="Please input approval remarks."
                      className={classes.textArea}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setTempDescription(e?.target.value);
                      }}
                      value={values.description}
                    />
                    {(errors.description) && (
                      <Alert severity="error">
                        {errors.description || ''}
                      </Alert>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    m={1}
                    p={1}
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.red}
                      id="reject"
                      size="large"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => {
                        if (!errors.description) {
                          handleClick('reject');
                        }
                      }}
                    >
                      Reject
                    </Button>

                    <Box style={{ width: '5%' }} />

                    <Button
                      variant="contained"
                      color="primary"
                      id="accept"
                      fullWidth
                      size="large"
                      disabled={isSubmitting}
                      onClick={() => {
                        if (!errors.description) {
                          handleClick('accept');
                        }
                      }}
                    >
                      Approve
                    </Button>
                  </Box>

                </>
              ) : openConfirmation === true ? (
                <>
                  <Box
                    m={2}
                    p={2}
                  >
                    <Typography>
                      <Box>
                        <Box>
                          Are you sure with your response?
                        </Box>
                        <br />
                        <Box>
                          You are about to
                          {' '}
                          <Box>
                            <b>{response.toUpperCase()}</b>
                          </Box>
                          {' '}
                          the request.
                        </Box>
                        <br />
                        <Box pb={2}>
                          With below description:
                          <br />
                          <Box>
                            <i>{values.description || 'No description'}</i>
                          </Box>
                        </Box>
                        {(errors.description) && (
                          <Alert severity="error">
                            {errors.description || ''}
                          </Alert>
                        )}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    m={1}
                    p={1}
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      id="submit"
                      type="submit"
                      size="medium"
                      disabled={isSubmitting}
                    >
                      Confirm
                    </Button>

                    <Box style={{ width: '5%' }} />

                    <Button
                      variant="contained"
                      className={classes.red}
                      id="amend"
                      fullWidth
                      size="medium"
                      disabled={isSubmitting}
                      onClick={() => setOpenConfirmation(false)}
                    >
                      Amend
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  Something when wrong.
                </>
              )}
            </Box>

          </form>
        )}
      </Formik>
    </>
  );
}

ApprovalTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  // setOpenPopup: PropTypes.bool,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
  pic: PropTypes.string,
};

export default ApprovalTicketForm;
