import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import {
  checkMethod,
  checkAccountMFA,
  totpGenerate
} from '../../../actions/user';
import TOTPVerificationView from '../../auth/TOTPVerificationView';

const AuthValidation = () => {
  // const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [openTOTPVerifyPage, setOpenTOTPVerifyPage] = useState(false);
  const [generateData, setGenerateData] = useState({});
  const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.result);

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  useEffect(() => {
    async function validate() {
      let mfaId = '';
      let verificationStatus = '';

      await dispatch(checkAccountMFA(user?.EMPLOYEE_NUMBER)).then((res) => {
        if (res?.success === true) {
          mfaId = res?.data[0]?.MFA_ID;
          verificationStatus = res?.data[0]?.VERIFICATION_STATUS;
        } else {
          handleError(res?.message);
        }
      });
      await dispatch(checkMethod(mfaId)).then((res) => {
        if (res?.success === true) {
          if (res?.data?.status === 'false') {
            // history('/app/dashboard');
            handleError('Authentication chosen if inactive.');
          }
        } else {
          handleError(res?.message);
        }
      });

      if (verificationStatus === 'false') {
        await dispatch(totpGenerate({
          employee_number: user?.EMPLOYEE_NUMBER,
          mfa_id: mfaId
        })).then((res) => {
          if (res?.success === true) {
            const tempData = res?.data[0];
            tempData.mfa_id = mfaId;
            setGenerateData(tempData);
            dispatch({ type: 'SET_USERID', payload: user?.EMPLOYEE_NUMBER });
          } else {
            handleError(res?.message);
          }
        }).then(() => {
          setOpenTOTPVerifyPage(true);
        });
      }
    }
    validate();
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert severity="error">{message}</Alert>
        </>
      )}
      {(openTOTPVerifyPage) && (
        <Box
          m={2}
        >
          <TOTPVerificationView
            data={generateData}
          />
        </Box>
      )}
    </>
  );
};

export default AuthValidation;
