import React from 'react';
import {
  makeStyles,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Page from 'src/components/Page';
import EquipmentType from './EquipmentType';
import EquipmentItem from './EquipmentItem';
import EquipmentPackage from './EquipmentPackage';
import ConsentAgreement from './ConsentAgreement';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const index = ({
  divisionidofuser,
  employeeNumber,
  accountType,
  divisions
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const employeeDivision = JSON.parse(localStorage.getItem('profile'))?.result?.DIVISION_ID;

  return (
    <>
      <Page
        className={classes.root}
        title="Equipment"
      >
        <Box
          mb={3}
        >
          <EquipmentType
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            divisions={divisions}
            employeeDivision={employeeDivision}
            accountType={accountType}
          />
        </Box>
        <Box
          mb={3}
        >
          <EquipmentItem
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            divisions={divisions}
            employeeDivision={employeeDivision}
            accountType={accountType}
          />
        </Box>
        <Box
          mb={3}
        >
          <EquipmentPackage
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            divisions={divisions}
            employeeDivision={employeeDivision}
            accountType={accountType}
          />
        </Box>
        <Box
          mb={3}
        >
          <ConsentAgreement
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            divisions={divisions}
            employeeDivision={employeeDivision}
            accountType={accountType}
          />
        </Box>
      </Page>
    </>
  );
};

index.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
};

export default index;
