import React/* , { useState } */ from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
  Container,
} from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { resetPasswordbysuperadmin } from '../../../actions/user';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((/* theme */) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    backgroundColor: '#fff38d'
  }
}));

const ResetPassword = ({
  selectedUser,
  refresh,
  setRefresh,
  setOpenDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [error, setError] = useState(false);
  // const [message, setMessage] = useState(null);
  // const [formData, setFormData] = useState(null);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  /* const handleError = (val) => {
    setMessage(val);
    setError(true);
  }; */

  return (
    <Page
      className={classes.root}
      title="Password"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              employee_number: selectedUser.employeenumber,
              password: '',
              confirmPassword: ''
            }}
            validationSchema={
              Yup.object().shape({
                password: Yup.string().max(255).required('password is required')
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                  ),
                confirmPassword: Yup
                  .string()
                  .required('Please confirm your password')
                  .when('password', {
                    is: (password) => (!!(password && password.length > 0)),
                    then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                  })
              })
            }
            onSubmit={
              (values, { setSubmitting, resetForm }) => {
                dispatch(resetPasswordbysuperadmin(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpenDialog(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                      setOpenDialog(false);
                      setRefresh(!refresh);
                    }
                  })
                  .catch();
                resetForm({
                  values: {
                    password: '',
                    confirmPassword: ''
                  }
                });
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <br />
                <Typography
                  color="primary"
                  style={{ fontWeight: 500 }}
                >
                  Reset Password
                </Typography>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm New Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                <Box>
                  {/* {error && (
                    <>
                      <Alert severity="error">{message}</Alert>
                    </>
                  )} */}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      RESET
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

ResetPassword.propTypes = {
  selectedUser: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenDialog: PropTypes.func,
};

export default ResetPassword;
