import React, { useState, /* useEffect,  */forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable/* , { MTableBodyRow } */ from 'material-table';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Paper,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox
} from '@material-ui/icons';
import Page from 'src/components/Page';
import DivisionForm from './divisionDetails';
import UserForm from './userDetails';
import RegisterView from './registerForm';
import NewDivisionForm from './newDivisionForm';
// import ResetPassword from './resetPassword';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserList({
  users,
  divisions,
  divisionsactive,
  divisionsview,
  refresh,
  setRefresh,
  employeeNumberuser,
  divisionidofuser,
  accountType,
  allCompany
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [opendivision, setOpendivision] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);
  // const [hoveringOver, setHoveringOver] = useState('');
  // const [hoveringOver2, setHoveringOver2] = useState('');
  const [register, setRegister] = useState(false);
  // const [password, setPassword] = useState(false);
  const [newdivision, setNewDivision] = useState(false);
  // const handleRowHover = (event, propsData) => setHoveringOver(propsData.index);
  // const handleRowHoverLeave = () => setHoveringOver('');
  // const handleRowHover2 = (event, propsData) => setHoveringOver2(propsData.index);
  // const handleRowHoverLeave2 = () => setHoveringOver2('');
  const handleOnClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRegister(false);
    setNewDivision(false);
    // setPassword(false);
  };
  const handleOnClickDivision = () => {
    setOpendivision(true);
  };
  const handleCloseDivision = () => {
    setOpendivision(false);
  };
  const handleClickRegister = () => {
    setRegister(true);
  };
  const handleClickDivision = () => {
    setNewDivision(true);
  };

  let Userlist = [];
  let DivisionList = [];
  if (users) {
    Userlist = users?.map((user) => (
      {
        employeenumber: user.EMPLOYEE_NUMBER || null,
        name: user.EMPLOYEE_NAME || '',
        position: user.POSITION || '',
        department: user.DEPARTMENT_NAME || '',
        division_id: user.DIVISION_ID || '',
        account_type: user.ACCOUNT_TYPE || '',
        login_status: user.LOGIN_STATUS || '',
        mfa_status: user.MFA_STATUS || '',
      }
    )).filter((row) => row.employeenumber !== employeeNumberuser) || [];
  }

  if (divisions) {
    DivisionList = divisions.map((div) => (
      {
        division_id: div.DIVISION_ID || null,
        division_name: div.DIVISION || '',
        division_status: div.DIVISION_STATUS || ''
      }
    )) || [];
  }

  return (
    <Page
      className={classes.paper}
      title="Division"
    >
      <Paper>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginRight={3}
          paddingTop={1}
        >
          <Tooltip title="Add Division" aria-label="add">
            <Button
              variant="outlined"
              color="primary"
              tooltip="Add Division"
              onClick={handleClickDivision}
            >
              ADD DIVISION
            </Button>
          </Tooltip>
        </Box>
        <Box
          marginRight={3}
          marginLeft={3}
          paddingTop={2}
        >
          <Typography
            color="primary"
            style={{ fontWeight: 500 }}
          >
            DIVISION
          </Typography>
          &nbsp;
          <MaterialTable
            icons={tableIcons}
            title=" "
            columns={[
              { title: 'ID', field: 'division_id' },
              { title: 'Division Name', field: 'division_name' },
              { title: 'Status', field: 'division_status' },
            ]}
            data={DivisionList}
            onRowClick={((event, selectedRowData) => {
              handleOnClickDivision();
              setSelectedDivision(selectedRowData);
            })}
            options={{
              search: true,
              grouping: true,
              rowStyle: (/* rowData */) => ({
                fontFamily: 'helvetica',
                // fontSize: '15px',
                /* backgroundColor:
                  rowData.tableData.id === hoveringOver ? '#EEE' : '#FFF' */
              })
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              /* Row: (props) => {
                return (
                  <MTableBodyRow
                    {...props}
                    onMouseEnter={(e) => handleRowHover(e, props)}
                    onMouseLeave={(e) => handleRowHoverLeave(e, props)}
                  />
                );
              } */
            }}
          />
        </Box>
      </Paper>
      <Dialog
        onClose={handleCloseDivision}
        aria-labelledby="customized-dialog-title"
        open={opendivision}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDivision}>
          Division
        </DialogTitle>
        <DialogContent dividers>
          <DivisionForm
            selectedDivision={selectedDivision}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpendivision={setOpendivision}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDivision} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={newdivision}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Division
        </DialogTitle>
        <DialogContent dividers>
          <NewDivisionForm
            divisionsview={divisionsview}
            refresh={refresh}
            setRefresh={setRefresh}
            setNewDivision={setNewDivision}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      &nbsp;
      <Paper>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginRight={3}
          paddingTop={1}
        >
          <Tooltip title="User Registration" aria-label="add">
            <Button
              variant="outlined"
              color="primary"
              tooltip="Register User"
              onClick={handleClickRegister}
            >
              REGISTER
            </Button>
          </Tooltip>
          &nbsp;
        </Box>
        <Box
          marginRight={3}
          marginLeft={3}
        >
          <Typography
            color="primary"
            style={{ fontWeight: 500 }}
          >
            USER
          </Typography>
          &nbsp;
          <MaterialTable
            icons={tableIcons}
            title=" "
            columns={[
              { title: 'Employee Number', field: 'employeenumber' },
              { title: 'Name', field: 'name' },
              { title: 'Position', field: 'position' },
              { title: 'Department', field: 'department' },
              { title: 'Account Type', field: 'account_type' },
            ]}
            data={Userlist}
            options={{
              search: true,
              grouping: true,
              rowStyle: (/* rowData */) => ({
                fontFamily: 'helvetica',
                // fontSize: '15px',
                /* backgroundColor:
                  rowData.tableData.id === hoveringOver2 ? '#EEE' : '#FFF' */
              })
            }}
            onRowClick={((event, selectedRowData) => {
              handleOnClick();
              setSelectedUser(selectedRowData);
              // console.log('row', selectedRowData);
            })}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              /* Row: (props) => {
                return (
                  <MTableBodyRow
                    {...props}
                    onMouseEnter={(e) => handleRowHover2(e, props)}
                    onMouseLeave={(e) => handleRowHoverLeave2(e, props)}
                  />
                );
              } */
            }}
          />
        </Box>
      </Paper>
      &nbsp;
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          User
        </DialogTitle>
        <DialogContent dividers>
          <UserForm
            selectedUser={selectedUser}
            divisionsactive={divisionsactive}
            close={handleClose}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisionidofuser={divisionidofuser}
            employeeNumberuser={employeeNumberuser}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={register}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Register User
        </DialogTitle>
        <DialogContent dividers>
          <RegisterView
            setRegister={setRegister}
            divisions={divisions}
            refresh={refresh}
            setRefresh={setRefresh}
            accountType={accountType}
            allCompany={allCompany}
            setOpenDialog={setOpen}
            // divisionsactive={divisionsactive} // from here
            // close={handleClose}
            // divisionidofuser={divisionidofuser}
            employeeNumberuser={employeeNumberuser}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

UserList.propTypes = {
  users: PropTypes.array,
  divisions: PropTypes.array,
  divisionsactive: PropTypes.array,
  divisionsview: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  employeeNumberuser: PropTypes.string,
  divisionidofuser: PropTypes.string,
  accountType: PropTypes.string,
  allCompany: PropTypes.array
};

export default UserList;
