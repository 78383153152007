import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './polyfills';

const store = createStore(reducers, compose(applyMiddleware(thunk)));
ReactDOM.render((
  <Provider store={store}>
    <SnackbarProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </SnackbarProvider>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
