import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  // Snackbar,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  MenuItem
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import {
  updateAccTyeActivate,
  getOneMFARecord,
  deleteUserAcc,
  fetchActiveMFAMethod
} from '../../../actions/admin';
import ResetPassword from './resetPassword';
import MFAForm from './MFAForm';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const UserDetails = ({
  selectedUser,
  close,
  divisionsactive,
  refresh,
  setRefresh,
  setOpenDialog,
  divisionidofuser,
  employeeNumberuser,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [division, setDivision] = useState(null);
  const [clickDelete, setClickDelete] = useState(false);
  const [resetpass, setResetpass] = useState(false);
  const [mfaRecordData, setMfaRecordData] = useState([]);
  const [mfaMethodData, setMfaMethodData] = useState([]);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  useEffect(() => {
    setDivision(divisionsactive);
    dispatch(getOneMFARecord(selectedUser?.employeenumber))
      .then((res) => {
        if (res?.success === true) {
          setMfaRecordData(res?.data);
        }
      });
    dispatch(fetchActiveMFAMethod())
      .then((res) => {
        if (res?.success === true) {
          setMfaMethodData(res?.data);
        }
      });
  }, [refresh]);

  const handleClickDelete = () => {
    setClickDelete(true);
    setResetpass(false);
  };

  const handleDelete = () => {
    dispatch(deleteUserAcc(selectedUser.employeenumber))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
    setRefresh(!refresh);
    setClickDelete(close);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  const handleresetPass = () => {
    setResetpass((prev) => !prev);
  };

  return (
    <Page
      className={classes.root}
      title="User"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              employeenumber: selectedUser.employeenumber || '',
              name: selectedUser.name || '',
              position: selectedUser.position || '-',
              department: selectedUser.department || '-',
              division_id: selectedUser.division_id || '',
              account_type: selectedUser.account_type || '',
              login_status: selectedUser.login_status || '',
              account_update_by: employeeNumberuser || ''
            }}
            validationSchema={
              Yup.object().shape({
                // status_description: Yup.string().max(30).required('Description is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateAccTyeActivate(selectedUser.employeenumber, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpenDialog(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    console.log('error : ', error);
                    setSubmitting(false);
                  });
              }
            }
          >
            {({
              //   errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              //   touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {clickDelete ? (
                  <>
                    <Box style={{ backgroundColor: red[50] }}>
                      <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                        Permanently remove user
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Emp Number :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.employeenumber}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Name
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 50 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.name}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Position
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 34 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.position}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Department
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 9 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.department}
                      </Typography>
                      <br />
                      <br />
                    </Box>
                    <br />
                    <br />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        // type="reset"
                        variant="contained"
                        // fullWidth
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        // fullWidth
                        onClick={handleDelCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                      variant="h6"
                    >
                      UPDATE USER
                    </Typography>
                    &nbsp;
                    {accountType === 'Super Admin' ? (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Delete User">
                          <IconButton aria-label="delete">
                            <DeleteForeverIcon
                              style={{ color: 'red' }}
                              onClick={() => handleClickDelete()}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : null }
                    <Box>
                      <Typography variant="h5" display="inline">
                        Emp Number
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 12.5 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.employeenumber || selectedUser[0]?.EMPLOYEE_NUMBER}
                      </Typography>
                      <br />
                      <Typography variant="h5" display="inline">
                        Name
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 63 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.name || selectedUser[0]?.EMPLOYEE_NAME}
                      </Typography>
                      &nbsp;
                      <br />
                      <Typography variant="h5" display="inline">
                        Position
                      </Typography>
                      <Typography style={{ marginLeft: 50 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.position}
                      </Typography>
                      <br />
                      <Typography variant="h5" display="inline">
                        Department
                      </Typography>
                      &nbsp;
                      <Typography style={{ marginLeft: 21.5 }} variant="h5" display="inline">
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.department}
                      </Typography>
                      <br />
                    </Box>
                    {division && (
                      <>
                        {division
                          .map((div) => {
                            if (
                              (selectedUser.division_id === divisionidofuser)
                              && (div.DIVISION_ID === selectedUser.division_id)
                            ) {
                              if (
                                (selectedUser.account_type !== 'Vendor User')
                              ) {
                                return (
                                  <Box key={div.DIVISION_ID}>
                                    <TextField
                                      fullWidth
                                      label="Account Type"
                                      margin="normal"
                                      name="account_type"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.account_type}
                                      variant="outlined"
                                      select
                                    >
                                      <MenuItem value="Super Admin">
                                        Super Admin
                                      </MenuItem>
                                      <MenuItem value="Division Admin">
                                        Division Admin
                                      </MenuItem>
                                      <MenuItem value="Division User">
                                        Division User
                                      </MenuItem>
                                      <MenuItem value="User">
                                        User
                                      </MenuItem>
                                    </TextField>
                                  </Box>
                                );
                              }
                            }
                            if (
                              (div.DIVISION_ID === selectedUser.division_id)
                              && (selectedUser.account_type !== 'Vendor User')
                            ) {
                              return (
                                <Box key={div.DIVISION_ID}>
                                  <TextField
                                    fullWidth
                                    label="Account Type"
                                    margin="normal"
                                    name="account_type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.account_type}
                                    variant="outlined"
                                    select
                                  >
                                    <MenuItem value="Division Admin">
                                      Division Admin
                                    </MenuItem>
                                    <MenuItem value="Division User">
                                      Division User
                                    </MenuItem>
                                    <MenuItem value="User">
                                      User
                                    </MenuItem>
                                  </TextField>
                                </Box>
                              );
                            }
                            if (
                              (div.DIVISION_ID === selectedUser.division_id)
                              && (
                                (selectedUser.account_type === 'Vendor User') || (selectedUser.account_type === 'User')
                              )
                            ) {
                              return (
                                <Box key={div.DIVISION_ID}>
                                  <Typography variant="h5" display="inline">
                                    Account Type
                                  </Typography>
                                  &nbsp;
                                  <Typography style={{ marginLeft: 9 }} variant="h5" display="inline">
                                    :
                                  </Typography>
                                  &nbsp;
                                  <Typography display="inline" style={{ marginLeft: 10 }}>
                                    {values.account_type}
                                  </Typography>
                                </Box>
                              );
                            }
                            return null;
                          })}
                      </>
                    )}
                    <TextField
                      fullWidth
                      label="User login status"
                      margin="normal"
                      name="login_status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.login_status || selectedUser[0]?.LOGIN_STATUS}
                      variant="outlined"
                    >
                      <MenuItem value="active" default>Active</MenuItem>
                      <MenuItem value="inactive" default>Inactive</MenuItem>
                    </TextField>
                    &nbsp;
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                      // onClick={handleChange}
                      >
                        Update
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        fullWidth
                        onClick={close}
                      >
                        Cancel
                      </Button>
                    </Box>
                    {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="success">
                        Successful update user
                      </Alert>
                    </Snackbar> */}
                    <br />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="resetpass"
                          value=""
                          onClick={handleresetPass}
                          color="primary"
                        />
                      )}
                      label="Click to reset user password"
                    />
                  </>
                )}
              </form>
            )}
          </Formik>
          {resetpass ? (
            <>
              <ResetPassword
                selectedUser={selectedUser}
                refresh={refresh}
                setRefresh={setRefresh}
                setOpenDialog={setOpenDialog}
              />
            </>
          ) : null}
          {accountType === 'Super Admin' && mfaMethodData?.length !== 0 ? (
            <>
              <br />
              <MFAForm
                data={{ record: mfaRecordData, method: mfaMethodData, user: selectedUser }}
                refresh={refresh}
                setRefresh={setRefresh}
                setOpenDialog={setOpenDialog}
              />
            </>
          ) : null}
        </Container>
      </Box>
    </Page>
  );
};

UserDetails.propTypes = {
  selectedUser: PropTypes.object,
  close: PropTypes.func,
  divisionsactive: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenDialog: PropTypes.func,
  divisionidofuser: PropTypes.string,
  employeeNumberuser: PropTypes.string,
  accountType: PropTypes.string
};

export default UserDetails;
