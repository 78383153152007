/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
  Container,
  MenuItem,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import {
  bulkManageMFARecord
} from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff38d'
  },
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f',
    }
  },
}));

const AuthenticationRecordForm = ({
  data,
  refresh,
  setRefresh,
  // openPopup,
  setOpenPopup,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [mfaMethodData, setMfaMethodData] = useState(data?.method);
  const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.result);
  const [initialValues, setInitialValues] = useState({
    record_data: [],
    action: '',
    mfa_id: '',
    employee_number: user?.EMPLOYEE_NUMBER
  });
  const [tempInitialValues, setTempInitialValues] = useState({});
  const [toggle, setToggle] = useState({
    name: 'Enable',
    action: 'enable',
    status: true
  });

  const handleSwitchChange = (event) => {
    if (event.target.checked === true) {
      setToggle({
        ...toggle,
        name: 'Enable MFA',
        action: 'enable',
        status: true
      });
    } else if (event.target.checked === false) {
      setToggle({
        ...toggle,
        name: 'Disable MFA',
        action: 'disable',
        status: false
      });
    }
  };

  // This usestate will run on first load
  useEffect(() => {
    setMfaMethodData(data?.method);
    setTempInitialValues({
      employee_number: user?.EMPLOYEE_NUMBER || '',
      record_data: data?.record,
      action: '',
      mfa_id: '',
    });
  }, []);

  // This usestate will run when data value change
  useEffect(() => {
    setMfaMethodData(data?.method);
    setTempInitialValues({
      ...tempInitialValues,
      record_data: data?.record,
      action: toggle?.action,
      employee_number: user?.EMPLOYEE_NUMBER
    });
  }, [data, toggle?.status]);

  // This usestate will run when tempInitialValue update
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      record_data: tempInitialValues?.record_data,
      action: tempInitialValues?.action,
      employee_number: tempInitialValues?.employee_number
    });
  }, [tempInitialValues]);

  return (
    <Page
      className={classes.root}
      title="Multi-Factor Authentication."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Box m={1}>
          <FormControlLabel
            control={(
              <Switch
                checked={toggle.status}
                onChange={handleSwitchChange}
                name="Mode Toggle"
                color="primary"
              />
            )}
            label={toggle?.name}
          />
        </Box>
        <>
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={
                Yup.object().shape({
                  mfa_id: Yup.string().max(255),
                  user_id: Yup.string().max(255),
                  employee_number: Yup.string().max(255)
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(bulkManageMFARecord(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpenPopup(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                        setOpenPopup(false);
                        setRefresh(!refresh);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <br />
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    Multi-Factor Authentication
                  </Typography>
                  {toggle?.status === true ? (
                    <TextField
                      error={Boolean(touched.mfa_id
                        && errors.mfa_id)}
                      fullWidth
                      helperText={touched.mfa_id
                        && errors.mfa_id}
                      label="Authentication"
                      id="mfa_id"
                      margin="normal"
                      name="mfa_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.mfa_id ? values.mfa_id : ''}
                      variant="outlined"
                    >
                      <MenuItem
                        value="undefined"
                        default
                        disabled
                      >
                        None
                      </MenuItem>
                      {(Array.isArray(mfaMethodData) && mfaMethodData?.length >= 0) ? (
                        mfaMethodData.map((method) => (
                          <MenuItem
                            value={method?.id}
                            key={method?.id}
                          >
                            {`${method?.AUTH_NAME}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled default>Loading active method</MenuItem>
                      )}
                    </TextField>
                  ) : (
                    <>
                      <Box mt={2}>
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          Are sure to disable MFA for multiple accounts?
                        </Typography>
                      </Box>
                    </>
                  )}

                  <Box>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      p={2}
                    >
                      <Button
                        color="primary"
                        className={toggle?.status === false ? classes.red : null}
                        disabled={toggle?.status === true ? (!isSubmitting && (values.mfa_id === '' || values.mfa_id === 'undefined')) : false}
                        type="submit"
                        variant="contained"
                      >
                        {toggle?.status === true ? 'Activate' : 'Disable'}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </>
      </Box>
    </Page>
  );
};

AuthenticationRecordForm.propTypes = {
  data: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  // openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
};

export default AuthenticationRecordForm;
