import React, { useState, useEffect } from 'react';
import {
  Outlet, useNavigate, useLocation
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import decode from 'jwt-decode';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const history = useNavigate();

  const checkToken = () => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 6000 < new Date().getTime()) {
        history('/login');
      }
    } else {
      history('/login');
    }
  };

  const checkLocalStorage = () => {
    if (JSON.parse(localStorage.getItem('profile')) === null) {
      history('/login');
    } else if (Object.keys(JSON.parse(localStorage.getItem('profile'))).length !== 0) {
      setUser(JSON.parse(localStorage.getItem('profile')));
    } else {
      // window.location.href = 'http://172.18.125.67:3000/login';
      history('/login');
    }
    checkToken();
  };

  useEffect(() => {
    checkLocalStorage();
  }, []);

  useEffect(() => {
    checkLocalStorage();
  }, [location]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        userData={user || { }}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
