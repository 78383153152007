import React, { useEffect, useState } from 'react';
import {
  Container, Grid, makeStyles, Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUserDivisionID, fetchAccountType } from 'src/actions/user';
import { listDivActive } from '../../actions/admin';
import Adminview from './adminView';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [employeeNumber] = useState(user?.result.EMPLOYEE_NUMBER);
  const [divisionidofuser, setDivisionIDofUser] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const stateDivision = useSelector((state) => state?.divisions);
  const [divisions, setDivisions] = useState(stateDivision);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const retrieve = () => {
    dispatch(fetchUserDivisionID(employeeNumber))
      .then((res) => {
        const { data } = res;
        // promptNotification(res);
        setDivisionIDofUser(data?.divisionid);
      });

    dispatch(fetchAccountType(employeeNumber))
      .then((res) => {
        const { data } = res;
        // promptNotification(res);
        setAccountType(data?.accountType);
      });
    dispatch(listDivActive())
      .then((res) => {
        promptNotification(res);
        setDivisions(res);
      });
  };

  useEffect(() => {
    retrieve();
    if (!employeeNumber) {
      history('/login');
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
    /* const timer = setInterval(() => {
      retrieve();
    }, 5000);
    return () => clearInterval(timer); */
  }, [location, refresh]);

  return (
    <Page className={classes.root} title="Administrator">
      {accountType === 'Division Admin' || accountType === 'Super Admin' ? (
        <Container maxWidth={false}>
          <Grid container>
            <Adminview
              user={user?.result}
              divisionidofuser={divisionidofuser}
              accountType={accountType}
              employeeNumber={employeeNumber}
              refresh={refresh}
              setRefresh={setRefresh}
              divisions={divisions}
            />
          </Grid>
        </Container>
      ) : (<></>)}
    </Page>
  );
};

export default Dashboard;
