import React, { /* useEffect,  */useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  MenuItem,
  Typography,
  Paper,
  Dialog,
  IconButton,
  TextareaAutosize
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createIndicator, createSeverity } from '../../../actions/admin';
import SeverityDetails from './severityDetails';
import IndicatorDetails from './indicatorDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const IndicatorForm = ({
  severities,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [clickdiv, setClickDiv] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };

  return (
    <Page
      className={classes.root}
      title="Severity"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                severity_id: '',
                seq: '',
                indicator_description: '',
                division_id: divisionidofuser,
                division_name: '',
                department_id: '',
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  severity_id: Yup.string().max(255).required('Severity level is required'),
                  indicator_description: Yup.string().max(4000).required('Indicator description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createIndicator(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      severity_id: '',
                      seq: '',
                      indicator_description: '',
                      division_id: divisionidofuser,
                      department_id: '',
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW INDICATOR
                  </Typography>
                  <TextField
                    error={Boolean(touched.severity_id && errors.severity_id)}
                    fullWidth
                    helperText={touched.severity_id && errors.severity_id}
                    label="Severity Level"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    select
                  >
                    {severities ? (
                      severities.map((severity) => {
                        if (
                          severity.DIVISION_ID
                          === divisionidofuser
                        ) {
                          return (
                            <MenuItem
                              height={20}
                              value={severity.SEVERITY_ID}
                              key={severity.SEVERITY_ID}
                            >
                              {severity.SEVERITY_ID}
                              {' ('}
                              {severity.IMPACT}
                              {') '}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.indicator_description && errors.indicator_description)}
                    fullWidth
                    helperText={(touched.indicator_description && errors.indicator_description)}
                    multiline
                    margin="normal"
                    label="Indicator Description"
                    name="indicator_description"
                    variant="outlined"
                    inputProps={{
                      inputcomponent: TextareaAutosize,
                      rows: 6
                    }}
                    value={values.indicator_description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                severity_id: '',
                seq: '',
                indicator_description: '',
                division_id: clickdiv,
                division_name: clickdivname,
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  severity_id: Yup.string().max(255).required('Severity level is required'),
                  indicator_description: Yup.string().max(4000).required('Indicator description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createIndicator(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      severity_id: '',
                      seq: '',
                      indicator_description: '',
                      division_id: divisionidofuser,
                      department_id: '',
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW INDICATOR
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.severity_id && errors.severity_id)}
                    fullWidth
                    helperText={touched.severity_id && errors.severity_id}
                    label="Severity Level"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    select
                  >
                    {severities ? (
                      severities.map((severity) => {
                        if (
                          severity.DIVISION_ID
                          === clickdiv
                        ) {
                          return (
                            <MenuItem
                              height={20}
                              value={severity.SEVERITY_ID}
                              key={severity.SEVERITY_ID}
                            >
                              {severity.SEVERITY_ID}
                              {' ('}
                              {severity.IMPACT}
                              {') '}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.indicator_description && errors.indicator_description)}
                    fullWidth
                    helperText={(touched.indicator_description && errors.indicator_description)}
                    multiline
                    margin="normal"
                    label="Indicator Description"
                    name="indicator_description"
                    variant="outlined"
                    inputProps={{
                      inputcomponent: TextareaAutosize,
                      rows: 6
                    }}
                    value={values.indicator_description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

IndicatorForm.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  severities: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default IndicatorForm;

export const SeverityForm = ({
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="Indicator"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                severity_id: '',
                impact: '',
                assign_interval: '',
                escalation_interval: '',
                resolution_interval: '',
                division_id: divisionidofuser,
                division_name: '',
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  severity_id: Yup.string().max(2).required('Severity level is required')
                    .test('Is positive?', 'Must be in integer value & greater than 0', (value) => value > 0),
                  impact: Yup.string().max(51).required('Severity level is required'),
                  assign_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  escalation_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  resolution_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createSeverity(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      severity_id: '',
                      impact: '',
                      assign_interval: '',
                      escalation_interval: '',
                      resolution_interval: '',
                      division_id: divisionidofuser,
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW SEVERITY
                  </Typography>
                  <TextField
                    error={Boolean(touched.severity_id && errors.severity_id)}
                    fullWidth
                    helperText={touched.severity_id && errors.severity_id}
                    label="Severity Level"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    inputProps={{ maxLength: 1 }}
                  />
                  <TextField
                    error={Boolean(touched.impact && errors.impact)}
                    fullWidth
                    helperText={touched.impact && errors.impact}
                    label="Impact"
                    margin="normal"
                    name="impact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.seq}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    error={Boolean(touched.assign_interval && errors.assign_interval)}
                    fullWidth
                    helperText={touched.assign_interval && errors.assign_interval}
                    label="Assign Interval (in hour)"
                    margin="normal"
                    name="assign_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.assign_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.escalation_interval && errors.escalation_interval)}
                    fullWidth
                    helperText={touched.escalation_interval && errors.escalation_interval}
                    label="Escalation Interval (in hour)"
                    margin="normal"
                    name="escalation_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.escalation_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.resolution_interval && errors.resolution_interval)}
                    fullWidth
                    helperText={touched.resolution_interval && errors.resolution_interval}
                    label="Resolution Interval (in hour)"
                    margin="normal"
                    name="resolution_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.resolution_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                severity_id: '',
                impact: '',
                assign_interval: '',
                escalation_interval: '',
                resolution_interval: '',
                division_id: '',
                division_name: '',
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  severity_id: Yup.string().max(2).required('Severity level is required')
                    .test('Is positive?', 'Must be in integer value & greater than 0', (value) => value > 0),
                  impact: Yup.string().max(51).required('Severity level is required'),
                  assign_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  escalation_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  resolution_interval: Yup.string().max(6)
                    .test('Is positive?', 'Must be in integer value & greater than 0',
                      (value) => {
                        if (value) {
                          return value > 0;
                        }
                        return true;
                      }),
                  division_id: Yup.string().max(255).required('Division in charge is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createSeverity(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      severity_id: '',
                      impact: '',
                      assign_interval: '',
                      escalation_interval: '',
                      resolution_interval: '',
                      division_id: '',
                      division_name: '',
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW SEVERITY
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.severity_id && errors.severity_id)}
                    fullWidth
                    helperText={touched.severity_id && errors.severity_id}
                    label="Severity Level"
                    margin="normal"
                    name="severity_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.severity_id}
                    variant="outlined"
                    inputProps={{ maxLength: 1 }}
                  />
                  <TextField
                    error={Boolean(touched.impact && errors.impact)}
                    fullWidth
                    helperText={touched.impact && errors.impact}
                    label="Impact"
                    margin="normal"
                    name="impact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.seq}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    error={Boolean(touched.assign_interval && errors.assign_interval)}
                    fullWidth
                    helperText={touched.assign_interval && errors.assign_interval}
                    label="Assign Interval (in hour)"
                    margin="normal"
                    name="assign_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.assign_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.escalation_interval && errors.escalation_interval)}
                    fullWidth
                    helperText={touched.escalation_interval && errors.escalation_interval}
                    label="Escalation Interval (in hour)"
                    margin="normal"
                    name="escalation_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.escalation_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.resolution_interval && errors.resolution_interval)}
                    fullWidth
                    helperText={touched.resolution_interval && errors.resolution_interval}
                    label="Resolution Interval (in hour)"
                    margin="normal"
                    name="resolution_interval"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.resolution_interval}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

SeverityForm.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export function ListIndicator({
  indicators,
  severities,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let indicatorlist = [];
  let SAindicatorlist = [];

  if (indicators) {
    indicatorlist = indicators.map((indicator) => (
      {
        indicator_id: indicator.INDICATOR_ID || null,
        severity_id: indicator.SEVERITY_ID || '',
        indicator_description: indicator.INDICATOR_DESCRIPTION || '',
        division_id: indicator.DIVISION_ID || '',
        division_name: indicator.DIVISION_NAME || ''
      }
    )).filter((row) => row.division_id === divisionidofuser) || [];
  }
  if (indicators) {
    SAindicatorlist = indicators.map((indicator) => (
      {
        indicator_id: indicator.INDICATOR_ID || null,
        severity_id: indicator.SEVERITY_ID || '',
        indicator_description: indicator.INDICATOR_DESCRIPTION || '',
        division_id: indicator.DIVISION_ID || '',
        division_name: indicator.DIVISION_NAME || ''
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF SEVERITY INDICATOR
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            // { title: 'ID', field: 'indicator_id' },
            { title: 'Severity Level', field: 'severity_id' },
            { title: 'Description', field: 'indicator_description' },
          ]}
          data={indicatorlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            // { title: 'ID', field: 'indicator_id' },
            { title: 'Severity Level', field: 'severity_id' },
            { title: 'Description', field: 'indicator_description' },
            { title: 'Division', field: 'division_name' }
          ]}
          data={SAindicatorlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Severity Indicator
        </DialogTitle>
        <DialogContent dividers>
          <IndicatorDetails
            indicator={selectedRow}
            severities={severities}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListIndicator.propTypes = {
  indicators: PropTypes.array,
  severities: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export function ListSeverity({
  severities,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let severitylist = [];
  let SAseveritylist = [];

  if (severities) {
    severitylist = severities.map((severity) => (
      {
        severity_id: severity.SEVERITY_ID || null,
        impact: severity.IMPACT || '',
        assign_interval: severity.ASSIGN_INTERVAL || '',
        escalation_interval: severity.ESCALATION_INTERVAL || '',
        resolution_interval: severity.RESOLUTION_INTERVAL || '',
        division_id: severity.DIVISION_ID || '',
        division_name: severity.DIVISION_NAME || ''
      }
    )).filter((row) => row.division_id === divisionidofuser) || [];
  }
  if (severities) {
    SAseveritylist = severities.map((severity) => (
      {
        severity_id: severity.SEVERITY_ID || null,
        impact: severity.IMPACT || '',
        assign_interval: severity.ASSIGN_INTERVAL || '',
        escalation_interval: severity.ESCALATION_INTERVAL || '',
        resolution_interval: severity.RESOLUTION_INTERVAL || '',
        division_id: severity.DIVISION_ID || '',
        division_name: severity.DIVISION_NAME || ''
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF SERVICE SEVERITY
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'Severity Level', field: 'severity_id' },
            { title: 'Impact', field: 'impact' },
            { title: 'Assign Interval (hrs)', field: 'assign_interval' },
            { title: 'Escalation Interval (hrs)', field: 'escalation_interval' },
            { title: 'Resolution Interval (hrs)', field: 'resolution_interval' },
          ]}
          data={severitylist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        // SUPER ADMIN VIEW
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'Severity Level', field: 'severity_id' },
            { title: 'Impact', field: 'impact' },
            { title: 'Assign Interval (hrs)', field: 'assign_interval' },
            { title: 'Escalation Interval (hrs)', field: 'escalation_interval' },
            { title: 'Resolution Interval (hrs)', field: 'resolution_interval' },
            { title: 'Division', field: 'division_name' },
          ]}
          data={SAseveritylist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Service Severity
        </DialogTitle>
        <DialogContent dividers>
          <SeverityDetails
            severity={selectedRow}
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ListSeverity.propTypes = {
  severities: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};
