import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Container,
} from '@material-ui/core';
import clsx from 'clsx';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  fetchAllAccountMFARecord,
  fetchActiveMFAMethod,
} from 'src/actions/admin';
import Popup from '../../../components/Popup';
import ListOfAuthenticationRecord from './ListOfAuthenticationRecord';
import AuthenticationMethodForm from './AuthenticationMethodForm';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const AuthenticationRecord = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    let tempData = {};
    async function retriveData() {
      await dispatch(fetchAllAccountMFARecord())
        .then((res) => {
          if (res?.success === true) {
            tempData = {
              ...tempData,
              record: res?.data?.account
            };
          }
        });
      await dispatch(fetchActiveMFAMethod())
        .then((res) => {
          if (res?.success === true) {
            tempData = {
              ...tempData,
              method: res?.data
            };
          }
        });
      setData(tempData);
    }
    retriveData();
  }, [refresh]);

  return (
    <>
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <ListOfAuthenticationRecord
            data={data}
            refresh={refresh}
            setRefresh={setRefresh}
          />
          <Popup
            openPopup={open}
            setOpenPopup={setOpen}
            title=""
          >
            <AuthenticationMethodForm
              openPopup={open}
              setOpenPopup={setOpen}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Popup>
          &nbsp;
        </Container>
      </Paper>
    </>
  );
};

AuthenticationRecord.propTypes = {
  // divisionidofuser: PropTypes.string,
  // employeeNumber: PropTypes.string,
  // accountType: PropTypes.string,
  // divisions: PropTypes.string,
  // employeeDivision: PropTypes.string,
};

export default AuthenticationRecord;
