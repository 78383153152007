import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateLocation, deleteLocation } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LocationDetails = ({
  selectedlocation,
  close,
  employeeNumber,
  refresh,
  setRefresh,
  setOpenDialog
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clickDelete, setClickDelete] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteLocation(selectedlocation.location_id))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
    setRefresh(!refresh);
    setClickDelete(close);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  return (
    <Page
      className={classes.root}
      title="Location"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              location_id: selectedlocation.location_id || '',
              buildings: selectedlocation.buildings || '',
              level: selectedlocation.level || '',
              division: selectedlocation.division || '',
              update_by: employeeNumber
            }}
            validationSchema={
              Yup.object().shape({
                buildings: Yup.string().max(150).required('Building is required'),
                level: Yup.string().max(20).required('Level/Floor is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateLocation(selectedlocation.location_id, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpenDialog(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {clickDelete ? (
                  <>
                    <Box style={{ backgroundColor: red[50] }}>
                      <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                        Permanently remove location
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        ID
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 50 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.location_id}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Location :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.buildings}
                        {', '}
                        {values.level}
                        {', '}
                        {values.division}
                      </Typography>
                      <br />
                      <br />
                    </Box>
                    <br />
                    <br />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        // type="reset"
                        variant="contained"
                        // fullWidth
                        onClick={() => handleDelete(setRefresh(!refresh))}
                      >
                        Yes
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        // fullWidth
                        onClick={handleDelCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                      variant="h6"
                    >
                      UPDATE LOCATION
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Tooltip title="Delete Location">
                        <IconButton aria-label="delete">
                          <DeleteForeverIcon
                            style={{ color: 'red' }}
                            onClick={() => handleClickDelete()}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      label="ID"
                      margin="normal"
                      name="status_id"
                      onBlur={handleBlur}
                      value={values.location_id}
                      variant="outlined"
                      disabled
                    />
                    <TextField
                      error={Boolean(touched.buildings && errors.buildings)}
                      fullWidth
                      helperText={(touched.buildings && errors.buildings) || 'Eg.: Wisma A'}
                      label="Buildings"
                      margin="normal"
                      name="buildings"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.buildings}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.level && errors.level)}
                      fullWidth
                      helperText={(touched.level && errors.level) || 'Eg.: Level 2 / Ground Floor'}
                      label="Level/Floor"
                      margin="normal"
                      name="level"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.level}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.division && errors.division)}
                      fullWidth
                      helperText={(touched.division && errors.division)}
                      label="Division/Dept"
                      margin="normal"
                      name="division"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.division}
                      variant="outlined"
                    />
                    &nbsp;
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        Update
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        fullWidth
                        onClick={close}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

LocationDetails.propTypes = {
  selectedlocation: PropTypes.object,
  close: PropTypes.func,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenDialog: PropTypes.func,
};

export default LocationDetails;
