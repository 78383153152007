import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import View from './divisionadminView';
import AllUserList from './superadminView';
import {
  listDA,
  listDU,
  // listUsers,
  listDiv,
  listDivActive,
  listDivView,
  listUsersIncSeparated,
  listExternalCompView
} from '../../../actions/admin';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(0)
  }
}));

const ManageDivision = ({
  user,
  divisionidofuser,
  accountType,
  employeeNumber,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const stateDA = useSelector((state) => state?.DAs);
  const [das, setDAs] = useState(stateDA);
  const stateDU = useSelector((state) => state?.DUs);
  const [dus, setDUs] = useState(stateDU);
  const stateUser = useSelector((state) => state?.users);
  const [users, setUsers] = useState(stateUser);
  const stateDivision = useSelector((state) => state?.divisions);
  const [divisions, setDivisions] = useState(stateDivision);
  const stateDivisionView = useSelector((state) => state?.divisionsview);
  const [divisionsview, setDivisionsview] = useState(stateDivisionView);
  const stateDivisionActive = useSelector((state) => state?.divisionsactive);
  const [divisionsactive, setDivisionsActive] = useState(stateDivisionActive);
  const stateAllCompany = useSelector((state) => state?.allCompany);
  const [allCompany, setAllCompany] = useState(stateAllCompany);

  const retrieve = () => {
    dispatch(listDA())
      .then((res) => {
        setDAs(res);
      });
    dispatch(listDU())
      .then((res) => {
        setDUs(res);
      });
    dispatch(listUsersIncSeparated())
      .then((res) => {
        setUsers(res);
      });
    dispatch(listDiv())
      .then((res) => {
        setDivisions(res);
      });
    dispatch(listDivView())
      .then((res) => {
        setDivisionsview(res);
      });
    dispatch(listDivActive())
      .then((res) => {
        setDivisionsActive(res);
      });
    dispatch(listExternalCompView())
      .then((res) => {
        setAllCompany(res);
      });
  };

  useEffect(() => {
    retrieve();
  }, [location, refresh]);

  return (
    <Page
      className={classes.root}
      title="Division"
    >
      {accountType === 'Division Admin' ? (
        <Container maxWidth={false} className={clsx(classes.root)}>

          <View
            das={das}
            dus={dus}
            users={users}
            divisions={divisions} // all division from table division
            divisionsactive={divisionsactive} // all active division from table division
            divisionidofuser={divisionidofuser} // division of one user login
            divisionsview={divisionsview} // all division from view
            user={user}
            refresh={refresh}
            setRefresh={setRefresh}
            employeeNumberuser={employeeNumber}
          />

        </Container>
      ) : (
        <Container maxWidth={false} className={clsx(classes.root)}>
          <AllUserList
            users={users}
            divisions={divisions}
            divisionsactive={divisionsactive}
            divisionsview={divisionsview}
            refresh={refresh}
            setRefresh={setRefresh}
            employeeNumberuser={employeeNumber}
            divisionidofuser={divisionidofuser}
            accountType={accountType}
            allCompany={allCompany}
          />
        </Container>
      )}
    </Page>
  );
};

ManageDivision.propTypes = {
  user: PropTypes.object,
  divisionidofuser: PropTypes.string,
  accountType: PropTypes.string,
  employeeNumber: PropTypes.string,
};

export default ManageDivision;
