import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Container,
  Box,
} from '@material-ui/core';
// import { white } from 'material-ui/styles/colors';
import Category from './Category_v2/indexcategory';
import Division from './Division/index';
import Severity from './Severity/index';
import Status from './Status/index';
import Others from './Others/index';
import FAQ from './FAQS/indexfaqs';
import Vendor from './Vendor/index';
import Location from './Location/index';
import ExternalCompany from './ExternalCompany/index';
import EquipmentRequest from './EquipmentRequest';
import Authentication from './Authentication';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>
            &nbsp;
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,

};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: white,
  },
}));

const FullWidthTabs = ({
  user,
  divisionidofuser,
  accountType,
  employeeNumber,
  divisions
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {accountType === 'Super Admin' ? (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Division" {...a11yProps(0)} />
              <Tab label="Authentication" {...a11yProps(1)} />
              <Tab label="Location" {...a11yProps(2)} />
              <Tab label="Category" {...a11yProps(3)} />
              <Tab label="Status" {...a11yProps(4)} />
              <Tab label="Severity" {...a11yProps(5)} />
              <Tab label="Vendor" {...a11yProps(6)} />
              <Tab label="FAQ" {...a11yProps(7)} />
              <Tab label="Others" {...a11yProps(8)} />
              <Tab label="Task" {...a11yProps(9)} />
              <Tab label="Equipment Request" {...a11yProps(10)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Division
              divisionidofuser={divisionidofuser} // division of one user login
              user={user}
              accountType={accountType}
              employeeNumber={employeeNumber}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Authentication
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Location
              employeeNumber={employeeNumber}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Category
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <Status
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={5} dir={theme.direction}>
            <Severity
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={6} dir={theme.direction}>
            <Vendor
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={7} dir={theme.direction}>
            <FAQ
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              accountType={accountType}
              divisions={divisions}
            />
          </TabPanel>
          <TabPanel value={value} index={8} dir={theme.direction}>
            <Others
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={9} dir={theme.direction}>
            <ExternalCompany
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={10} dir={theme.direction}>
            <EquipmentRequest
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
        </>
      ) : (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Division" {...a11yProps(0)} />
              <Tab label="Category" {...a11yProps(1)} />
              <Tab label="Severity" {...a11yProps(2)} />
              <Tab label="Vendor" {...a11yProps(3)} />
              <Tab label="FAQ" {...a11yProps(4)} />
              <Tab label="Others" {...a11yProps(5)} />
              <Tab label="Equipment Request" {...a11yProps(6)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Division
              divisionidofuser={divisionidofuser} // division of one user login
              user={user}
              accountType={accountType}
              employeeNumber={employeeNumber}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Category
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              accountType={accountType}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
            <Status
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel> */}
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Severity
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Vendor
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <FAQ
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={5} dir={theme.direction}>
            <Others
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
          <TabPanel value={value} index={6} dir={theme.direction}>
            <EquipmentRequest
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              accountType={accountType}
            />
          </TabPanel>
        </>
      )}
    </div>
  );
};

FullWidthTabs.propTypes = {
  setClick: PropTypes.func,
  click: PropTypes.bool,
  categories: PropTypes.array,
  modules: PropTypes.array,
  departments: PropTypes.array,
  user: PropTypes.object,
  divisionidofuser: PropTypes.string,
  /* faqs: PropTypes.array,
  answers: PropTypes.array, */
  accountType: PropTypes.string,
  employeeNumber: PropTypes.string,
  divisions: PropTypes.array
  // refresh: PropTypes.bool,
  // setRefresh: PropTypes.func,
};

export default FullWidthTabs;
