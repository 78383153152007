const initialState = {
  tickets: null,
  categories: null,
  peoples: null,
  loading: false
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PERSONAL_TICKET':
      return {
        ...state,
        tickets: action?.payload.personalTicket,
        categories: action?.payload.category,
        peoples: action?.payload.people
      };
    case 'SHOW_LOADING_SPINNER':
      return {
        ...state,
        loading: true
      };
    case 'HIDE_LOADING_SPINNER':
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default serviceReducer;
