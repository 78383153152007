/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import { createTicket, fetchPeoples } from 'src/actions/service';
import { listLocation } from 'src/actions/admin';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { maxSelectFile, checkFileSize } from '../../../components/fileUpload';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function TicketForm({
  categories,
  user,
  setOpenPopup,
  refresh,
  setRefresh
}) {
  const classes = useStyles();
  const history = useNavigate();
  const [includeSecondaryPerson, setIncludeSecondaryPerson] = useState(false);
  const [peoples, setPeoples] = useState([]);
  const [sNumber, setSnumber] = useState('');
  const [sName, setSname] = useState('');
  const [sEmail, setSemail] = useState('');
  const dispatch = useDispatch();
  const [tempAttachment, setAttachment] = useState([]);
  const [tempLocation, setLocation] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const [tempCategory, setCategory] = useState('');
  const [tempLocationId, setTempLocationId] = useState('');
  const [tempLocationName, setTempLocationName] = useState('');
  const [tempErrorMsg, setTempErrorMsg] = useState('');
  const [tempTitle, setTitle] = useState('');
  const [tempDescription, setDescription] = useState('');
  const [tempIpAddress, setIpAddress] = useState('');
  const [tempExtNo, setExtNo] = useState('');
  const [tempPcName, setPcName] = useState('');
  const [tempLocationRemarks, setLocationRemarks] = useState('');
  const [tempInitialValue, setTempInitialValue] = useState({
    title: '',
    category: tempCategory,
    description: '',
    attachment: tempAttachment,
    email: user?.EMAIL_ADDRESS,
    name: user?.EMPLOYEE_NAME,
    employee_number: user?.EMPLOYEE_NUMBER,
    ext_no: '',
    ip_address: '',
    pc_name: '',
    secondary_name: sName,
    secondary_email: sEmail,
    secondary_employee_number: sNumber,
    type: 'ticket',
    location_id: tempLocationId,
    location_name: tempLocationName,
    location_remarks: ''
  });
  let options = [];

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const retrievePeoples = (empNo, skipLoadingLoop) => {
    dispatch(fetchPeoples(empNo, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (Array.isArray(res?.peoples)) {
            setPeoples(res?.peoples || []);
          }
        } else {
          if (res?.relogin === true) {
            history('/login');
          }
        }
      })
      .catch((err) => {
        console.log('ERROR RETRIEVE LIST OF PEOPLES IN DIVISION : ', err);
      });
  };

  if (categories?.length !== 0) {
    options = categories.map((option) => {
      const division = option?.DIVISION_NAME || '';
      return {
        division: division || '',
        ...option,
      };
    });
  } else {
    options = [];
  }

  const locations = tempLocation.map((location) => {
    let locationName = `${location?.LEVEL}, ${location?.BUILDINGS}`;

    if (location?.DIVISION !== '') {
      locationName += `, ${location?.DIVISION}`;
    }

    return {
      location_name: locationName,
      ...location,
    };
  });

  const handleLocation = (val) => {
    setTempLocationId(val?.LOCATION_ID);
    setTempLocationName(val?.location_name);
  };

  const handleOpenLocation = (value) => {
    if (value?.LOCATION_FLAG === '1') {
      setOpenLocation(true);
    } else {
      setOpenLocation(false);
    }
  };

  const handleCheckBoxOnChange = () => {
    setIncludeSecondaryPerson(!includeSecondaryPerson);
    if (includeSecondaryPerson === false) {
      setSnumber('');
      setSname('');
      setSemail('');
    }
  };

  const handleClickCategory = (value, event) => {
    event.preventDefault();
    handleOpenLocation(value);
    setCategory(value?.CATEGORY_ID);
  };

  const handleSecondaryPerson = (person) => {
    setSname(person?.EMPLOYEE_NAME || '');
    setSnumber(person?.EMPLOYEE_NUMBER || '');
    setSemail(person?.EMAIL_ADDRESS || '');
  };

  // This will run on first load of page
  useEffect(() => {
    dispatch(listLocation())
      .then((res) => {
        setLocation(Array.isArray(res) ? res : []);
      });
    retrievePeoples(user?.EMPLOYEE_NUMBER, false);
  }, []);

  // This use effect will update form value when change, this is to solve dissapearing value when chosse attachments
  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      title: tempTitle,
      description: tempDescription,
      ext_no: tempExtNo,
      ip_address: tempIpAddress,
      pc_name: tempPcName,
      location_remarks: tempLocationRemarks,
      category: tempCategory,
      attachment: tempAttachment,
      secondary_name: sName,
      secondary_email: sEmail,
      secondary_employee_number: sNumber,
      location_id: tempLocationId,
      location_name: tempLocationName,
    });
  }, [
    tempAttachment,
    tempCategory,
    tempLocationId,
    tempLocationName,
    tempTitle,
    tempDescription,
    tempIpAddress,
    tempExtNo,
    tempPcName,
    tempLocationRemarks,
    sName,
    sEmail,
    sNumber
  ]);

  return (
    <Page
      title="Add Service Ticket"
    >
      <Box>
        <Formik
          enableReinitialize
          initialValues={tempInitialValue}
          validationSchema={
            Yup.object().shape({
              title: Yup.string().max(255).required('Title is required'),
              description: Yup.string().max(500).min(2).required('Please provide description.'),
              category: Yup.string().ensure().required('Please choose a category'),
              secondary_email: Yup.string().max(255).email('Email must be a valid email'),
              location_remarks: Yup.string().max(255, 'Location remarks must less than 255 characters'),
              ext_no: Yup.string().max(50).required('Please provide a contactable number.'),
            })
          }
          onSubmit={
            (values, { setSubmitting }) => {
              const data = new FormData();
              // data.append('attachment', tempAttachment);
              for (const value in values) {
                data.append(value, values[value]);
              }

              for (let x = 0; x < tempAttachment?.length; x++) {
                data.append('attachment', tempAttachment[x]);
              }
              dispatch(createTicket(data))
                .then((res) => {
                  if (!res?.data) {
                    if (res?.success !== true) {
                      setSubmitting(false);
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.notifier,
                        },
                      });
                    } else {
                      setOpenPopup(false);
                      setRefresh(!refresh);
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.notifier,
                        },
                      });
                    }
                  } else {
                    if (res?.data.success !== true) {
                      setSubmitting(false);
                      enqueueSnackbar({
                        message: res?.data.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.data.notifier,
                        },
                      });
                    } else {
                      setOpenPopup(false);
                      setRefresh(!refresh);
                      enqueueSnackbar({
                        message: res?.data.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.data.notifier,
                        },
                      });
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                });
            }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              keepDirtyOnReinitialize
            >
              <LoadingSpinner />
              <Checkbox
                onClick={() => handleCheckBoxOnChange()}
              />
              <Typography style={{ display: 'inline' }}>Include Secondary Person</Typography>
              <Paper>
                {includeSecondaryPerson && (
                  <>
                    <Box mb={3} boxShadow={1}>
                      <Container>
                        <TextField
                          error={Boolean(touched.secondary_employee_number
                            && errors.secondary_employee_number)}
                          fullWidth
                          helperText={touched.secondary_employee_number
                            && errors.secondary_employee_number}
                          label="Name"
                          id="secondary_employee_number"
                          margin="normal"
                          name="secondary_employee_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.secondary_employee_number}
                          variant="outlined"
                        >
                          <MenuItem
                            value=""
                            default
                            onClick={() => {
                              setSnumber('');
                              setSname('');
                              setSemail('');
                            }}
                          >
                            None
                          </MenuItem>
                          {(peoples && Array.isArray(peoples) && peoples.length > 0) ? (
                            peoples.slice(0, peoples.length).map((people) => (
                              <MenuItem
                                value={people.EMPLOYEE_NUMBER}
                                onClick={() => handleSecondaryPerson(people)}
                                key={people.EMPLOYEE_NUMBER}
                              >
                                {people.EMPLOYEE_NAME}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled default>Loading secondary person data</MenuItem>
                          )}
                        </TextField>
                        <TextField
                          fullWidth
                          label="Employee Number"
                          margin="normal"
                          onChange={handleChange}
                          variant="outlined"
                          disabled
                          value={values.secondary_employee_number}
                        >
                          {values.secondary_employee_number}
                        </TextField>
                        <TextField
                          error={Boolean(touched.secondary_email && errors.secondary_email)}
                          fullWidth
                          helperText={touched.secondary_email && errors.secondary_email}
                          label="Email"
                          margin="normal"
                          name="secondary_email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.secondary_email}
                          variant="outlined"
                        />
                      </Container>
                    </Box>
                  </>
                )}
              </Paper>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography>Ticket Details</Typography>
              </Box>
              <Paper>
                <Box mb={2}>
                  <Container>
                    <Autocomplete
                      onChange={(event, value) => {
                        handleClickCategory(value, event);
                      }}
                      loading={options?.length === 0}
                      options={
                        options.sort((a, b) => b.DIVISION_NAME?.localeCompare(a?.DIVISION_NAME))
                      }
                      groupBy={(option) => option?.DIVISION_NAME}
                      getOptionLabel={(option) => option?.CATEGORY_NAME}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.category && errors.category)}
                          helperText={touched.category && errors.category}
                          label="Category"
                          variant="outlined"
                          id="category"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="category"
                          value={values.category}
                          fullWidth
                        />
                      )}
                    />
                    <br />
                    {openLocation === true && (
                      <>
                        <TextField
                          error={Boolean(touched.location && errors.location)}
                          fullWidth
                          helperText={touched.location && errors.location}
                          id="location"
                          label="Location"
                          margin="normal"
                          name="location"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.location_id}
                          variant="outlined"

                        >
                          {locations ? (
                            locations.map((location) => (
                              <MenuItem
                                value={location?.LOCATION_ID}
                                onClick={() => handleLocation(location)}
                              >
                                {location?.location_name || ''}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                        <TextField
                          error={Boolean(touched.location_remarks && errors.location_remarks)}
                          fullWidth
                          helperText={touched.location_remarks && errors.location_remarks}
                          id="location_remarks"
                          label="Location Remarks (Optional)"
                          margin="normal"
                          name="location_remarks"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onChangeCapture={(e) => {
                            setLocationRemarks(e.target.value);
                          }}
                          type="text"
                          value={values.location_remarks}
                          variant="outlined"
                        />
                      </>
                    )}
                    <Button
                      id="attachment"
                      name="attachment"
                      error={Boolean(touched.attachment && errors.attachment)}
                      fullWidth
                      helperText={touched.attachment && errors.attachment}
                      label="Attachment"
                      variant="outlined"
                      focused="true"
                      component="label"
                      margin="normal"
                    >
                      Attachment :
                      {' '}
                      <input
                        id="attachment"
                        name="attachment"
                        onChange={(event) => {
                          const maxFile = maxSelectFile(event);
                          const fileSize = checkFileSize(event);

                          if (maxFile?.success && fileSize?.success) {
                            setTempErrorMsg('');
                            setAttachment(event.target.files || null);
                            setFieldValue('attachment', event.currentTarget.files[0] || null);
                          } else {
                            console.log(maxFile?.message);
                            setTempErrorMsg(`${maxFile?.message} \n${fileSize?.message}`);
                          }
                        }}
                        onClick={(e) => {
                          e.target.value = '';
                        }}
                        type="file"
                        multiple
                      />
                    </Button>
                    {(tempErrorMsg !== '' && tempErrorMsg !== null) && (
                      <>
                        <Alert severity="error">{tempErrorMsg || ''}</Alert>
                      </>
                    )}
                    <br />
                    <br />
                    <TextField
                      error={Boolean(touched.ext_no && errors.ext_no)}
                      fullWidth
                      helperText={touched.ext_no && errors.ext_no}
                      id="ext_no"
                      label="Contact Number (Ext No / Personal No)"
                      margin="normal"
                      name="ext_no"
                      onBlur={handleBlur}
                      onChange={
                        handleChange
                      }
                      onChangeCapture={(e) => {
                        setExtNo(e.target.value);
                      }}
                      type="text"
                      value={values.ext_no}
                      variant="outlined"

                    />
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      id="title"
                      label="Ticket Title"
                      margin="normal"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setTitle(e.target.value);
                      }}
                      type="text"
                      value={values.title}
                      variant="outlined"

                    />
                    <br />
                    <br />
                    <TextareaAutosize
                      rowsMax={10}
                      rowsMin={10}
                      name="description"
                      aria-label="maximum height"
                      placeholder="Service Description"
                      className={classes.textArea}
                      error={Boolean(touched.description && errors.description)}
                      fullwidth="true"
                      helperText={touched.description && errors.description}
                      label="Description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={values.description}
                    />
                    {(errors.description && touched.description) && (
                      <Alert severity="error">{errors.description || ''}</Alert>
                    )}
                    <Box m={1}>
                      <Button
                        id="submit"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleChange}
                      >
                        Create
                      </Button>
                    </Box>
                  </Container>
                </Box>
              </Paper>
            </form>
          )}
        </Formik>

      </Box>

    </Page>
  );
}

export default TicketForm;

TicketForm.propTypes = {
  categories: PropTypes.array,
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  peoples: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};
