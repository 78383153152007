export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
  let variant = 'info';
  const key = notification.options && notification.options.key;
  if (notification?.options.success === true) {
    if (notification?.options.notifier !== null) {
      const tempVariant = notification?.options.notifier;
      variant = tempVariant;
    } else {
      const tempVariant = 'success';
      variant = tempVariant;
    }
  } else if (notification?.options.success === false) {
    const tempVariant = 'warning';
    variant = tempVariant;
  } else if (notification?.options.success === 'fetched') {
    const tempVariant = 'info';
    variant = tempVariant;
  }

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      options: { ...notification.options, variant },
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
