import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { addNewDivision } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewDivision = ({
  divisionsview,
  refresh,
  setRefresh,
  setNewDivision
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tempDivId, setTempDivId] = useState('');
  const [tempDiv, setTempDiv] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleClickDiv = (division) => {
    setTempDivId(division?.DIVISION_ID || '');
    setTempDiv(division?.DIVISION || '');
  };

  return (
    <Page
      className={classes.root}
      title="Division"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={{
              division_id: tempDivId,
              division: tempDiv,
            }}
            validationSchema={
              Yup.object().shape({
                division_id: Yup.string().ensure().required('Division is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(addNewDivision(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setNewDivision(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  color="primary"
                  style={{ fontWeight: 500 }}
                >
                  Add New Division
                </Typography>
                <TextField
                  error={Boolean(touched.division_id && errors.division_id)}
                  fullWidth
                  helperText={touched.division_id && errors.division_id}
                  label="Division Name"
                  margin="normal"
                  name="division_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.division_id}
                  variant="outlined"
                  select
                >
                  {divisionsview ? (
                    divisionsview.map((division) => (
                      <MenuItem
                        value={division.DIVISION_ID}
                        onClick={() => handleClickDiv(division)}
                        key={division.DIVISION_ID}
                      >
                        {division.DIVISION}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </TextField>
                <br />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ADD
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

NewDivision.propTypes = {
  divisionsview: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setNewDivision: PropTypes.func
};

export default NewDivision;
