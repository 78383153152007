import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import CategoryForm from './categoryForm';
import ModuleForm from './moduleForm';

const styles = (theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  addButton: {
    margin: theme.spacing(1)
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Toolbars({
  categories,
  departments,
  divisionid,
  employeeNumber,
  refresh,
  setRefresh,
  accountType,
}) {
  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosec = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenM = () => {
    setOpenM(true);
  };
  const handleCloseM = () => {
    setOpenM(false);
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginRight={3}
      >
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant="outlined"
          color="primary"
          onClick={handleClick}
        >
          ADD
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClosec}
        >
          <MenuItem>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
              CATEGORY
            </Button>
          </MenuItem>
          <MenuItem>
            <Button variant="outlined" color="primary" onClick={handleClickOpenM}>
              MODULE
            </Button>
          </MenuItem>
        </Menu>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Category
          </DialogTitle>
          <DialogContent dividers>
            <CategoryForm
              departments={departments}
              divisionid={divisionid}
              employeeNumber={employeeNumber}
              setOpen={setOpen}
              refresh={refresh}
              setRefresh={setRefresh}
              accountType={accountType}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        &nbsp;
        <Dialog
          onClose={handleCloseM}
          aria-labelledby="customized-dialog-title"
          open={openM}
          fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseM}>
            Add Module
          </DialogTitle>
          <DialogContent dividers>
            <ModuleForm
              categories={categories}
              departments={departments}
              divisionid={divisionid}
              employeeNumber={employeeNumber}
              refresh={refresh}
              setRefresh={setRefresh}
              setOpenM={setOpenM}
              accountType={accountType}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseM} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
}

Toolbars.propTypes = {
  categories: PropTypes.array,
  departments: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  divisionid: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
};
