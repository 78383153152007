import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  // Divider,
  TextField,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { login } from '../../actions/auth';

const PasswordLogin = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  useEffect(() => {
    return () => {
      setError(false);
      setMessage(null);
    };
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert severity="error">{message}</Alert>
        </>
      )}
      <Box
        m={2}
      >
        <Formik
          initialValues={{
            email: '',
            password: '',
            employee_number: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().max(255).required('Password is required'),
            employee_number: Yup.string().max(6, 'Employee Number must not more than 6 characters.').required('Employee Number is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            try {
              // Submit user credential to backend
              dispatch(login(values))
                .then((res) => {
                  if (res?.success === true) {
                    if (res?.mfaStatus === true) {
                      history('/validate_auth');
                    } else if (res?.mfaStatus === false) {
                      history('/app/dashboard');
                    }
                  } else {
                    handleError(res?.message);
                    setSubmitting(false);
                  }
                })
                .catch(() => {
                  handleError('Server Error');
                  setSubmitting(false);
                });
            } catch (err) {
              handleError(err);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.employee_number && errors.employee_number)}
                fullWidth
                helperText={touched.employee_number && errors.employee_number}
                label="Employee Number"
                margin="normal"
                name="employee_number"
                onBlur={handleBlur}
                onChange={handleChange}
                type="Text"
                inputProps={{
                  maxLength: 6,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                value={values.employee_number}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              <Box
                my={2}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in now
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>

    </>
  );
};

export default PasswordLogin;
