import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Box,
  Button,
  Paper,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ListCategory from './viewCategory';
import {
  listCategory,
  listModule,
  listDepartment
} from '../../../actions/admin';
import Toolbars from './toolbars';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const ManageCategory = ({
  divisionidofuser,
  employeeNumber,
  accountType
}) => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(null);
  const stateCategory = useSelector((state) => state?.categories);
  const [categories, setCategories] = useState(stateCategory);
  const stateModule = useSelector((state) => state?.modules);
  const [modules, setModules] = useState(stateModule);
  const stateDepartment = useSelector((state) => state?.departments);
  const [departments, setDepartments] = useState(stateDepartment);
  const location = useLocation();

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const retrieve = () => {
    dispatch(listCategory())
      .then((res) => {
        promptNotification(res);
        setCategories(res);
      });
    dispatch(listModule())
      .then((res) => {
        promptNotification(res);
        setModules(res);
      });
    dispatch(listDepartment())
      .then((res) => {
        promptNotification(res);
        setDepartments(res);
      });
  };

  useEffect(() => {
    retrieve();
    if (!employeeNumber) {
      history('/login');
    }
  }, [location, refresh]);

  return (
    <Page
      className={classes.root}
      title="Category"
    >
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Toolbars
              categories={categories}
              departments={departments}
              divisionid={divisionidofuser}
              employeeNumber={employeeNumber}
              refresh={refresh}
              setRefresh={setRefresh}
              accountType={accountType}
            />
          </Box>
          <ListCategory
            categories={categories}
            modules={modules}
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            departments={departments}
            accountType={accountType}
          />
          &nbsp;
        </Container>
      </Paper>
    </Page>
  );
};

ManageCategory.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
};

export default ManageCategory;
