import React, { /* useEffect,  */useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { addNewLocation } from '../../../actions/admin';
import LocationDetails from './locationDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LocationForm = ({
  employeeNumber,
  refresh,
  setRefresh
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="Location"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              buildings: '',
              level: '',
              division: '',
              create_by: employeeNumber
            }}
            validationSchema={
              Yup.object().shape({
                buildings: Yup.string().max(150).required('Building is required'),
                level: Yup.string().max(20).required('Level/Floor is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting, resetForm }) => {
                dispatch(addNewLocation(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
                resetForm({
                  values: {
                    buildings: '',
                    level: '',
                    division: '',
                    create_by: employeeNumber
                  }
                });
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  color="primary"
                  style={{ fontWeight: 500 }}
                >
                  NEW LOCATION
                </Typography>
                <TextField
                  error={Boolean(touched.buildings && errors.buildings)}
                  fullWidth
                  helperText={(touched.buildings && errors.buildings) || 'Eg.: Wisma A'}
                  label="Buildings"
                  margin="normal"
                  name="buildings"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.buildings}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.level && errors.level)}
                  fullWidth
                  helperText={(touched.level && errors.level) || 'Eg.: Level 2 / Ground Floor'}
                  label="Level/Floor"
                  margin="normal"
                  name="level"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.level}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.division && errors.division)}
                  fullWidth
                  helperText={(touched.division && errors.division)}
                  label="Division/Dept"
                  margin="normal"
                  name="division"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.division}
                  variant="outlined"
                />
                &nbsp;
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <div>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Add
                    </Button>
                    &nbsp;
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="reset"
                      variant="outlined"
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                  </div>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

LocationForm.propTypes = {
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default LocationForm;

export function ListLocation({
  ptplocation,
  employeeNumber,
  refresh,
  setRefresh
}) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let locationlist = [];

  if (ptplocation) {
    locationlist = ptplocation.map((loc) => (
      {
        location_id: loc.LOCATION_ID || null,
        buildings: loc.BUILDINGS || '',
        level: loc.LEVEL || '',
        division: loc.DIVISION || null
      }
    ));
  }
  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF LOCATION IN PTP
      </Typography>
      &nbsp;
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={[
          { title: 'Buildings', field: 'buildings' },
          { title: 'Level', field: 'level' },
          { title: 'Division/Dept', field: 'division' }
        ]}
        data={locationlist}
        options={{
          search: true,
          grouping: true,
          rowStyle: () => ({
            fontFamily: 'helvetica',
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
        onRowClick={((event, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Location in PTP
        </DialogTitle>
        <DialogContent dividers>
          <LocationDetails
            selectedlocation={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListLocation.propTypes = {
  ptplocation: PropTypes.array,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};
