import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import {
  tokenLogin
} from '../../actions/user';

function TokenLogin() {
  const param = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  useEffect(() => {
    dispatch(tokenLogin(param, history))
      .then((res) => {
        if (res?.success === false) {
          handleError(res?.message);
        }
        if (res?.success === true) {
          setMessage('Login success! PRISM will redirect to your account page shortly.');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {error ? (
        <>
          <Alert
            onClick={() => window.location.replace('/#/login')}
            severity="error"
          >
            {`${message}. Please click here to refresh page.`}
          </Alert>
        </>
      ) : (
        <Alert
          onClick={() => window.location.replace('/#/login')}
          severity="sucess"
        >
          {message}
        </Alert>
      )}
    </>
  );
}

export default TokenLogin;
