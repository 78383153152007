import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
  MenuItem
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { deleteExternalCompany, updateExtCompStatus } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ExternalCompanyDetails = ({
  ext,
  close,
  employeeNumber,
  refresh,
  setRefresh,
  setOpen,
  //   divisions,
  //   accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clickDelete, setClickDelete] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  //   const [divisionid, setClickDiv] = useState('');
  //   const [divisionname, setClickDivname] = useState('');

  //   const handleDivClick = (div) => {
  //     setClickDiv(div?.DIVISION_ID);
  //     setClickDivname(div?.DIVISION);
  //   };
  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelete = () => {
    dispatch(deleteExternalCompany(ext.gkey))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
    setRefresh(!refresh);
    setClickDelete(close);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {/* {accountType !== 'Super Admin' ? ( */}
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              external_id: ext.external_id || '',
              external_name: ext.external_name || '',
              external_status: ext.external_status || '',
              external_type: ext.external_type || '',
              gkey: ext.gkey || '',
              // division_id: vnd.division_id,
              // division_name: vnd.division_name || '',
              update_by: employeeNumber
            }}
            validationSchema={
              Yup.object().shape({
                // status_description: Yup.string().max(30).required('Description is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateExtCompStatus(ext.gkey, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpen(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {clickDelete ? (
                  <>
                    <Box style={{ backgroundColor: red[50] }}>
                      <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                        Permanently remove external company
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Key
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 45 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.gkey}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Company :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.external_id}
                        {' - '}
                        {values.external_name}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Role
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 40 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.external_type}
                      </Typography>
                      <br />
                      <br />
                    </Box>
                    <br />
                    <br />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        onClick={handleDelCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <Typography
                        color="primary"
                        style={{ fontWeight: 500 }}
                        variant="h6"
                      >
                        UPDATE EXTERNAL COMPANY STATUS
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        onClick={() => handleClickDelete()}
                      >
                        <Tooltip title="Delete External Company">
                          <IconButton aria-label="delete">
                            <DeleteForeverIcon style={{ color: 'red' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Typography variant="h5" display="inline">
                        KEY
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 42 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.gkey}
                      </Typography>
                      <br />
                      <Typography variant="h5" display="inline">
                        Company :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.external_id}
                        {' - '}
                        {values.external_name}
                      </Typography>
                      <br />
                      <Typography variant="h5" display="inline">
                        Role
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 40 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {values.external_type}
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      label="External Company status"
                      margin="normal"
                      name="external_status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.external_status}
                      variant="outlined"
                    >
                      <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                      <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                    </TextField>
                    &nbsp;
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        Update
                      </Button>
                    </Box>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Container>
        {/* ) : ( */}
        {/* // SUPER ADMIN VIEW */}
        {/* <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                vendor_id: vnd.vendor_id || '',
                vendor_name: vnd.vendor_name || '',
                vendor_status: vnd.vendor_status || '',
                division_id: divisionid || vnd.division_id || '',
                division_name: divisionname || vnd.division_name || '',
                updated_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  // status_description: Yup.string().max(30).required('Description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateVendorStatus(vnd.vendor_id, values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpen(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {clickDelete ? (
                    <>
                      <Box style={{ backgroundColor: red[50] }}>
                        <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                          Permanently remove vendor
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Vendor ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.vendor_id}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Vendor Name :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.vendor_name}
                        </Typography>
                        <br />
                        <br />
                      </Box>
                      <br />
                      <br />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={handleDelCancel}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Typography
                          color="primary"
                          style={{ fontWeight: 500 }}
                          variant="h6"
                        >
                          UPDATE VENDOR STATUS
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          onClick={() => handleClickDelete()}
                        >
                          <Tooltip title="Delete Vendor">
                            <IconButton aria-label="delete">
                              <DeleteForeverIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Typography variant="h5" display="inline">
                          Vendor ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 31 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.vendor_id}
                        </Typography>
                        <br />
                        <Typography variant="h5" display="inline">
                          Vendor Name :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {values.vendor_name}
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        label="Select Division In Charge"
                        margin="normal"
                        name="division_id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={values.division_id}
                        variant="outlined"
                      >
                        {divisions ? (
                          divisions.map((div) => (
                            <MenuItem
                              value={div.DIVISION_ID}
                              key={div.DIVISION_ID}
                              onClick={() => handleDivClick(div)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {div.DIVISION}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                      <TextField
                        fullWidth
                        label="Vendor status"
                        margin="normal"
                        name="vendor_status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select
                        value={values.vendor_status}
                        variant="outlined"
                      >
                        <MenuItem value="Active" default>Active</MenuItem>
                        <MenuItem value="Inactive" default>Inactive</MenuItem>
                      </TextField>
                      &nbsp;
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          fullWidth
                        >
                          Update
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        )} */}
      </Box>
    </Page>
  );
};

ExternalCompanyDetails.propTypes = {
  ext: PropTypes.object,
  close: PropTypes.func,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpen: PropTypes.func,
  // accountType: PropTypes.string,
  // divisions: PropTypes.array
};

export default ExternalCompanyDetails;
