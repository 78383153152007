/* eslint-disable no-nested-ternary */
import React, { useState, forwardRef, useEffect } from 'react';
import {
  Button,
  Box,
  Card,
  makeStyles,
  Divider,
  Tooltip,
  Paper,
  CardHeader
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialTable, { MTableBodyRow } from 'material-table';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import Popup from '../../../components/Popup';
import TicketForm from './TicketForm';
import RequestForm from './RequestForm';
import LoadingSpinner from '../../../components/LoadingSpinner';
import TicketDetails from '../../../components/TicketDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 1100
  },
  avatar: {
    marginLeft: theme.spacing(2)
  },
  addTicketButton: {
    marginRight: theme.spacing(1),
    position: 'absolute',
    right: '25px',
    margin: '25px'
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

function NormalView({
  tickets,
  ticketCategories,
  requestCategories,
  equipmentRequest,
  user,
  peoples,
  refresh,
  setRefresh,
  openAdd
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openTicketForm, setOpenTicketForm] = useState(openAdd);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [selection] = useState(true);
  const currentPage = 'NormalView';

  let temp = [];

  const formatDate = (tempDate) => {
    if (tempDate !== null) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      const datetime = tempDate;
      const t = datetime.substring(0, 19).split(/[- .T:]/);

      // Apply each element to the Date function
      const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return d.toString() || '';
    }
    return '';
  };

  if (tickets) {
    const services = tickets.map((service) => {
      let tempCreateDate = service?.CREATE_DATE;
      let tempUpdateDate = service?.UPDATE_DATE;

      if (service?.CREATE_DATE !== null) {
        tempCreateDate = formatDate(service?.CREATE_DATE);
      }

      if (service?.UPDATE_DATE !== null) {
        tempUpdateDate = formatDate(service?.UPDATE_DATE);
      }

      return {
        tempCreateDate,
        tempUpdateDate,
        ...service,
      };
    });

    temp = services.map((ticket) => ({
      id: ticket.id || null,
      serviceRefno: ticket.SERVICE_REFNO || 'null',
      title: ticket.SERVICE_TITLE || 'null',
      status: ticket?.SERVICE_STATUS_NAME || ticket.SERVICE_STATUS || 'null',
      category: ticket?.CATEGORY_NAME || ticket.CATEGORY || 'null',
      updateDate: ticket?.tempUpdateDate || ticket?.tempCreateDate || ticket?.UPDATE_DATE || ticket?.CREATE_DATE || '-',
      pic: ticket.PIC_NAME || ticket.PIC || "Haven't been assigned yet!"
    })) || null;
  }

  const handleOnClick = () => {
    setOpen(!open);
    setOpenTicketDetails(!openTicketDetails);
  };

  const handleOnClickSelection = (event) => {
    setServiceType(event.currentTarget.value);
  };

  useEffect(() => {
    // checkOpenForm(openAdd);
  }, []);

  return (
    <>
      <Box
        display="flex"
        width="100%"
        mb={2}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexGrow={1}
        />
        <Box>
          <Tooltip title="Submit New Service" aria-label="add">
            <Button
              tooltip="Add New Service"
              onClick={() => setOpenTicketForm(true)}
              color="primary"
              variant="contained"
              size="medium"
            >
              ADD SERVICE
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Paper variant="outlined">
        <LoadingSpinner />
        <Card className={clsx(classes.root)} mb={2}>
          <Box display="flex" mb={1}>
            <CardHeader
              title="Service"
              subheader="Service that you have create"
            />
          </Box>
          <Divider />
          <Box>
            <MaterialTable
              title=" "
              icons={tableIcons}
              columns={[
                { title: 'Ticket Number', field: 'serviceRefno' },
                { title: 'Service Summary', field: 'title' },
                { title: 'Status', field: 'status' },
                { title: 'Service Category', field: 'category' },
                { title: 'Last Update', field: 'updateDate' },
                { title: 'PIC', field: 'pic' }
              ]}
              data={temp}
              onRowClick={(evt, selectedRowData) => {
                handleOnClick();
                setSelectedRow(selectedRowData);
              }}
              options={{
                search: true,
                toolbar: true,
                paging: true
              }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
                Row: (props) => {
                  return (
                    <MTableBodyRow
                      {...props}
                    />
                  );
                }
              }}
            />
          </Box>
        </Card>

        <Popup
          openPopup={openTicketForm}
          setOpenPopup={setOpenTicketForm}
          setServiceType={setServiceType}
          title="Create New Service"
        >
          {selection === true && (
            <>
              <Box
                mb={2}
              >
                <Alert severity="info">
                  &rsquo;Tickets&rsquo; is to report incidents or get assistance from someone while
                  &rsquo;Request&rsquo; is to get something that requires superior approval.
                </Alert>
              </Box>
              <Box
                textAlign="center"
                margin={2}
                display="flex"
                justifyContent="left"
              >
                <Box pr={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleOnClickSelection}
                    value="ticket"
                  >
                    Tickets
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleOnClickSelection}
                    value="request"
                  >
                    Request
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {serviceType === 'ticket' ? (
            <>
              <TicketForm
                categories={Array.isArray(ticketCategories) ? ticketCategories : []}
                user={user}
                peoples={Array.isArray(peoples) ? peoples : []}
                openPopup={openTicketForm}
                setOpenPopup={setOpenTicketForm}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </>
          ) : serviceType === 'request' ? (
            <>
              <RequestForm
                categories={requestCategories}
                equipmentRequest={equipmentRequest}
                user={user}
                peoples={peoples}
                openPopup={openTicketForm}
                setOpenPopup={setOpenTicketForm}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </>
          ) : null}
        </Popup>
        <Popup
          openPopup={openTicketDetails}
          setOpenPopup={setOpenTicketDetails}
          title="Details"
        >
          <TicketDetails
            ticket={selectedRow}
            user={user}
            openPopup={openTicketDetails}
            setOpenPopup={setOpenTicketDetails}
            currentPage={currentPage}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Popup>
      </Paper>
    </>
  );
}

NormalView.propTypes = {
  tickets: PropTypes.array,
  ticketCategories: PropTypes.array,
  requestCategories: PropTypes.array,
  equipmentRequest: PropTypes.array,
  user: PropTypes.object,
  peoples: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  openAdd: PropTypes.bool,
};

export default NormalView;
