/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';

import { fetchOtherDivisionUser } from '../../../actions/user';
import {
  fetchSeverity,
  fetchModule,
  fetchReason,
  forwardTicket
} from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import { maxSelectFile, checkFileSize } from '../../../components/fileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function ForwardTicketForm({
  user,
  serviceRefno,
  setOpenPopup,
  currentModule,
  currentSeverity,
  type,
  refresh,
  setRefresh,
  accountType
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);
  const [listOfPeople, setListOfPeople] = useState([]);
  const [listOfSeverity, setListOfSeverity] = useState([]);
  const [listOfModule, setListOfModule] = useState([]);
  const [listOfReason, setListOfReason] = useState([]);
  const [tempAttachment, setAttachment] = useState([]);
  const [tempEmpNum, setTempEmpNum] = useState('000000');
  const [tempEmpName, setTempEmpName] = useState('');
  const [tempServerity, setTempSeverity] = useState('');
  const [tempServerityImpact, setTempSeverityImpact] = useState('');
  const [tempModule, setTempModule] = useState('');
  const [tempCategory, setTempCategory] = useState('');
  const [tempReason, setTempReason] = useState('');
  const [tempReasonDescription, setTempReasonDescription] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const operation = 'FWD';

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const excludeInactivePeople = (peoples) => {
    peoples.map((temp, i) => {
      if (temp.LOGIN_STATUS !== 'active') {
        peoples.splice(i, 1);
      }
    });

    setListOfPeople(peoples || []);
  };

  const handleClickEmployee = (employee) => {
    setTempEmpNum(employee?.EMPLOYEE_NUMBER || null);
    setTempEmpName(employee?.EMPLOYEE_NAME || null);
  };

  const handleClickModule = (module) => {
    setTempModule(module?.MODULE_ID || null);
    setTempCategory(module?.CATEGORY_ID || null);
  };

  const handleClickSeverity = (severity) => {
    setTempSeverity(severity?.SEVERITY_ID || '');
    setTempSeverityImpact(severity?.SEVERITY_IMPACT || '');
  };

  const handleClickReason = (reason) => {
    setTempReason(reason?.REASON_ID || null);
    setTempReasonDescription(reason?.REASON_DESCRIPTION || '');
  };

  const loadData = () => {
    dispatch(fetchOtherDivisionUser(userEmployeeNumber, operation))
      .then((res) => {
        if (res?.success === true) {
          excludeInactivePeople(res?.listOfPeople || []);
        }
      });

    dispatch(fetchSeverity(userEmployeeNumber))
      .then((res2) => {
        if (res2?.success === true) {
          setListOfSeverity(res2.severity);
        }
      });

    dispatch(fetchModule(userEmployeeNumber))
      .then((res3) => {
        if (res3?.success === true) {
          if (type === 'request') {
            setListOfModule(res3?.requestModules || []);
          } else {
            setListOfModule(res3?.ticketModules || []);
          }
        }
      });
    dispatch(fetchReason(user?.DIVISION_ID))
      .then((res) => {
        if (res?.success === true) {
          setListOfReason(res?.reason);
        }
      });
  };

  useEffect(() => {
    loadData();
    setTempModule(currentModule);
    setTempSeverity(currentSeverity);
  }, []);

  return (
    <Box
      bgcolor="common.white"
      color="text.primary"
      m={2}
      borderRadius={12}
      p={2}
    >
      <Formik
        enableReinitialize
        initialValues={{
          service_refno: serviceRefno,
          employeeNumber: userEmployeeNumber,
          assignToNumber: tempEmpNum,
          assignToName: tempEmpName,
          severity: tempServerity,
          severity_impact: tempServerityImpact,
          module: tempModule,
          category: tempCategory,
          attachment: tempAttachment,
          reason_id: tempReason,
          reason_description: tempReasonDescription,
          description: tempDescription,
          service_status: 'FWD',
          status_name: 'Forwarded',
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500).min(5).required('Please provide description.'),
            severity: Yup.number().required('Please select severity.'),
            module: Yup.string().required('Please select module.'),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          const data = new FormData();
          for (const value in values) {
            data.append(value, values[value]);
          }

          for (let x = 0; x < tempAttachment?.length; x++) {
            data.append('attachment', tempAttachment[x]);
          }

          dispatch(forwardTicket(data))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setRefresh(!refresh);
                setOpenPopup(false);
              } else {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.module
                && errors.module)}
              fullWidth
              helperText={touched.module
                && errors.module}
              label="Module"
              id="module"
              margin="normal"
              name="module"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.module}
              variant="outlined"
              disabled={accountType !== 'Vendor User' ? (false) : (true)}
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfModule !== 0 ? (
                listOfModule.slice(0, listOfModule.length).map((module, index) => (
                  <MenuItem
                    value={module.MODULE_ID}
                    onClick={() => handleClickModule(listOfModule[index])}
                  >
                    {module.MODULE_ID === currentModule && ('(Current) ')}
                    {module.MODULE_DESCRIPTION}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled default>No module data load</MenuItem>
              )}
            </TextField>

            <TextField
              error={Boolean(touched.severity
                && errors.severity)}
              fullWidth
              helperText={touched.severity
                && errors.severity}
              label="Severity"
              id="severity"
              margin="normal"
              name="severity"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.severity}
              variant="outlined"
              disabled={accountType !== 'Vendor User' ? (false) : (true)}
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfSeverity !== 0 ? (
                listOfSeverity.map((severity, index) => (
                  <MenuItem
                    value={severity.SEVERITY_ID}
                    onClick={() => handleClickSeverity(listOfSeverity[index])}
                  >
                    {severity?.SEVERITY_ID === currentSeverity && ('(Current) ')}
                    {severity?.SEVERITY_ID === currentSeverity
                      && (setTempSeverityImpact(severity?.IMPACT))}
                    {severity.IMPACT}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={null} disabled default>No severity data load</MenuItem>
              )}
            </TextField>

            <TextField
              error={Boolean(touched.reason
                && errors.reason)}
              fullWidth
              helperText={touched.reason
                && errors.reason}
              label="Reason"
              id="reason"
              margin="normal"
              name="reason"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.reason}
              variant="outlined"
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfReason.length !== 0 ? (
                listOfReason
                  .filter((reason) => reason?.STATUS_REASON === 'FWD')
                  .map((reason, index) => (
                    <MenuItem
                      value={reason?.REASON_ID}
                      onClick={() => handleClickReason(listOfReason[index])}
                    >
                      {reason?.REASON_DESCRIPTION}
                    </MenuItem>
                  ))

              ) : (
                <MenuItem value="" disabled default>
                  Fail to load reason.
                </MenuItem>
              )}
            </TextField>

            <TextField
              error={Boolean(touched.assignToName
                && errors.assignToName)}
              fullWidth
              helperText={touched.assignToName
                && errors.assignToName}
              label="Forward to"
              id="assignToName"
              margin="normal"
              name="assignToName"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.assignToName}
              variant="outlined"
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfPeople !== 0 ? (
                listOfPeople.map((people, index) => (
                  <MenuItem
                    value={people.EMPLOYEE_NAME}
                    onClick={() => handleClickEmployee(listOfPeople[index])}
                  >
                    {people.EMPLOYEE_NAME}
                  </MenuItem>
                ))

              ) : (
                <MenuItem value="" disabled default>No people data load</MenuItem>
              )}
            </TextField>

            <TextField
              value={values.assignToNumber}
              name="assignToNumber"
              disabled
              variant="outlined"
              fullWidth
              label="Employee Number Preview"
              margin="normal"
            />
            <br />
            <br />

            <Button
              error={Boolean(touched.attachment && errors.attachment)}
              fullWidth
              helperText={touched.attachment && errors.attachment}
              label="Attachment"
              variant="outlined"
              focused
              component="label"
              onBlur={handleBlur}
              margin="normal"
            >
              Attachment :
              {' '}
              <input
                id="attachment"
                name="attachment"
                onChange={(event) => {
                  if (maxSelectFile(event) && checkFileSize(event)) {
                    setAttachment(event.target.files || null);
                    setFieldValue('attachment', event.currentTarget.files[0] || null);
                  }
                }}
                onClick={(e) => {
                  e.target.value = '';
                }}
                type="file"
                multiple
              />
            </Button>

            <br />
            <br />

            <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                name="description"
                aria-label="maximum height"
                placeholder="Forward Description"
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                fullwidth="true"
                helperText={touched.description && errors.description}
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                onChangeCapture={(e) => {
                  setTempDescription(e.target.value);
                }}
                value={values.description}
              />
              {(errors.description && touched.description) && (
                <Alert severity="error">
                  {errors.description || ''}
                </Alert>
              )}
              <br />
            </Box>

            <Button
              id="submit"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

ForwardTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.bool,
  currentModule: PropTypes.string,
  currentSeverity: PropTypes.string,
  type: PropTypes.string,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
  accountType: PropTypes.string
};

export default ForwardTicketForm;
