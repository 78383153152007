import * as api from '../api/index';

export const fetchPersonalTicket = (employeeNumber, skipLoadingLoop) => async (dispatch) => {
  try {
    if (skipLoadingLoop !== true) {
      dispatch({ type: 'SHOW_LOADING_SPINNER' });
    }
    const response = await api.fetchPersonalTicket(employeeNumber)
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    const { data } = response;
    if (response?.status !== 200) {
      dispatch({ type: 'HIDE_LOADING_SPINNER' });
      return response;
    }
    dispatch({ type: 'FETCH_PERSONAL_TICKET', payload: data });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchPeoples = (employeeNumber, skipLoadingLoop) => async (dispatch) => {
  try {
    if (skipLoadingLoop === false) {
      dispatch({ type: 'SHOW_LOADING_SPINNER' });
    }
    const response = await api.fetchPeoples(employeeNumber)
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    const { data } = response;
    dispatch({ type: 'FETCH_PERSONAL_TICKET', payload: data });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchManageTicket = (employeeNumber, skipLoadingLoop) => async (dispatch) => {
  try {
    if (skipLoadingLoop !== true) {
      dispatch({ type: 'SHOW_LOADING_SPINNER' });
    }
    const data = await api.fetchManageTicket(employeeNumber)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const createTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createRequest = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createRequest(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const manageTicket = (employeeNumber) => async (dispatch) => {
  try {
    const { data } = await api.fetchPersonalTicket(employeeNumber);

    dispatch({ type: '', data });
  } catch (error) {
    console.log(error);
  }
};

export const fetchTicketDetails = (serviceRefno) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.fetchTicketDetails(serviceRefno)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchAttachmentList = (refno, autoNo) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.fetchAttachmentList(refno, autoNo)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.updateTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadFile = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.uploadFile(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const dismissTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.dismissTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const withdrawTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.withdrawTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const closeTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.closeTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const forwardTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.forwardTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const forwardChangeOwnerTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.forwardChangeOwnerTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const respondApproval = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.respondApproval(formData)
      .then((res) => {
        if (res?.data) {
          return res?.data;
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    console.log('service error : ', error);
    return error;
  }
};

export const assignTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.assignTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const acknowledgeTicket = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.acknowledgeTicket(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err?.data || err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchSeverity = (employeeNumber) => async (dispatch) => {
  try {
    const response = await api.fetchTicketSeverity(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchModule = (employeeNumber) => async (dispatch) => {
  try {
    const response = await api.fetchTicketModule(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchStatus = (type) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchTicketStatus(type)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchReason = (divisionId) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchForwardReason(divisionId)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchVendor = (divisionId) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchVendorInOneDivision(divisionId)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchApproval = (employeeNumber, skipLoadingLoop) => async (dispatch) => {
  try {
    if (skipLoadingLoop !== true) {
      dispatch({ type: 'SHOW_LOADING_SPINNER' });
    }
    const response = await api.fetchApproval(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchSolvedVia = (divisionId) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchServiceSolvedVia(divisionId)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const listExternalUser = () => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchExternalUser()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listExternalUserType = () => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchExternalUserType()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const createTask = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createTask(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const fetchAllModule = () => async (dispatch) => {
  try {
    const response = await api.fetchAllTicketModule()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllDivision = (divisionId) => async (dispatch) => {
  try {
    const response = await api.fetchAllActiveDivision(divisionId)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};
