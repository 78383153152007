import React, { useState, forwardRef } from 'react';
import {
  Paper
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox
} from '@material-ui/icons';
import MaterialTable, { MTableBodyRow } from 'material-table';
import PropTypes from 'prop-types';

import Popup from '../../../components/Popup';
import TicketDetails from '../../../components/TicketDetails';

function AssignedTicketTable({
  assignedTicket,
  user,
  refresh,
  setRefresh
}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  let temp = [];

  const formatDate = (tempDate) => {
    if (tempDate !== null) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      const datetime = tempDate;
      const t = datetime.substring(0, 19).split(/[- .T:]/);

      // Apply each element to the Date function
      const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return d.toString() || '';
    }
    return '';
  };
  if (assignedTicket) {
    const services = assignedTicket.map((service) => {
      let tempCreateDate = service?.CREATE_DATE;
      let tempUpdateDate = service?.UPDATE_DATE;

      if (service?.CREATE_DATE !== null) {
        tempCreateDate = formatDate(service?.CREATE_DATE);
      }

      if (service?.UPDATE_DATE !== null) {
        tempUpdateDate = formatDate(service?.UPDATE_DATE);
      }

      return {
        tempCreateDate,
        tempUpdateDate,
        ...service,
      };
    });

    temp = services.map((ticket) => (

      {
        id: ticket.id || null,
        serviceRefno: ticket.SERVICE_REFNO || 'null',
        title: ticket.SERVICE_TITLE || 'null',
        status: (
          ticket?.SERVICE_STATUS_NAME === null
            ? ticket?.SERVICE_STATUS
            : ticket?.SERVICE_STATUS_NAME
        ),
        category: (
          ticket?.CATEGORY_NAME === null
            ? ticket?.CATEGORY
            : ticket?.CATEGORY_NAME
        ),
        updateDate: ticket?.tempUpdateDate || ticket?.tempCreateDate || '-',
        pic: ticket.PIC || "Haven't been assigned yet!",
        severity: (
          ticket?.SEVERITY_IMPACT === null
            ? ticket?.SEVERITY_ID
            : ticket?.SEVERITY_IMPACT
        ),
        creator: ticket.EMPLOYEE_NAME,
        serviceType: ticket.SERVICE_TYPE.toUpperCase() || 'null'
      }

    )) || null;
  }

  const handleOnClick = () => {
    setOpenTicketDetails(!openTicketDetails);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: 'Type', field: 'serviceType' },
          { title: 'Status', field: 'status' },
          { title: 'Service Number', field: 'serviceRefno' },
          { title: 'Severity', field: 'severity' },
          { title: 'Service Summary', field: 'title' },
          { title: 'Service Category', field: 'category' },
          { title: 'Last Update', field: 'updateDate' },
          { title: 'Creator', field: 'creator' },
        ]}
        data={temp}
        onRowClick={((evt, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
        options={{
          toolbar: false,
          paging: true
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
              />
            );
          }
        }}
      />
      <Popup
        openPopup={openTicketDetails}
        setOpenPopup={setOpenTicketDetails}
        title="Details"
      >
        <TicketDetails
          ticket={selectedRow}
          user={user}
          openPopup={openTicketDetails}
          setOpenPopup={setOpenTicketDetails}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Popup>
    </>
  );
}

AssignedTicketTable.propTypes = {
  assignedTicket: PropTypes.object,
  user: PropTypes.object,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
};

export default AssignedTicketTable;
