import React, { useState } from 'react';

function TestPage() {
  const [base64, setBase64] = useState('Empty');

  function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64(reader.result);
      // document.getElementById('output').innerHTML = reader.result;
      console.log(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  function handleImage() {
    const { files } = document.getElementById('file');
    if (files.length > 0) {
      getBase64(files[0]);
    }
  }

  return (
    <div>
      <br />
      <input id="file" type="file" name="Input" onChange={handleImage} />
      <br />
      <br />
      <textarea name="Text1" cols="40" rows="5" value={base64} />
      <div id="output">{base64}</div>
    </div>
  );
}

export default TestPage;
