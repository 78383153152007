/* eslint-disable no-nested-ternary */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import LoadingLinear from '../../../components/LoadingLinear';

const TicketAmount = ({
  iconColor, icon, user, data, title, className, ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%'
    },
    avatar: {
      backgroundColor: colors.blue[600],
      height: 56,
      width: 56
    },
    differenceIcon: {
      color: colors.red[900]
    },
    differenceValue: {
      color: colors.red[900],
      marginRight: theme.spacing(1)
    },
    spinner: {
      height: '100%',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    content: {
      height: '100%',
    }
  }));

  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      {data.length !== 0 ? (
        <>
          <CardContent>
            <Grid
              container
              justify="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h5"
                >
                  {title}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  {data?.SERVICE_STATUS}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {data?.AMOUNT}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  className={classes.avatar}
                  style={iconColor === 'red' ? ({ backgroundColor: colors.red[600] })
                    : iconColor === 'blue' ? ({ backgroundColor: colors.blue[600] })
                      : iconColor === 'yellow' ? ({ backgroundColor: colors.yellow[600] })
                        : iconColor === 'green' ? ({ backgroundColor: colors.green[600] })
                          : ({ backgroundColor: colors.blueGrey[600] })}
                >
                  {icon || (<><ConfirmationNumberOutlinedIcon /></>)}
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </>
      ) : (
        <>
          <CardContent
            className={classes.content}
          >
            <Box
              className={classes.spinner}
              display="flex"
              alignItems="center"
              alignContent="center"
            >
              <LoadingLinear />
            </Box>
          </CardContent>
        </>
      )}
    </Card>
  );
};

TicketAmount.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object,
  data: PropTypes.array,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
};

export default TicketAmount;
