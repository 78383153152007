import React, { /* useEffect,  */useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateDA } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const UserForm = ({
  users,
  divisionidofuser,
  setaddOpen,
  refresh,
  setRefresh
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null);
  const [selectEmp, setselectEmp] = useState('');
  const [dispPosition, setdispPosition] = useState('');
  const [dispDepartment, setdispDepartment] = useState('');
  const [dispAccType, setdispAcctype] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleSelectEmp = (user) => {
    setselectEmp(user?.EMPLOYEE_NUMBER);
    setdispPosition(user?.POSITION);
    setdispDepartment(user?.DEPARTMENT_NAME);
    setdispAcctype(user?.ACCOUNT_TYPE);
    setOpen((prev) => !prev);
  };

  const options = users.map((option) => {
    const divisionid = option.DIVISION_ID;
    return {
      divisionid: divisionid || '',
      ...option,
    };
  }).filter((option) => option.LOGIN_STATUS === 'active');

  /* const handleCollapse = () => {
    setOpen(false);
  }; */

  const handleEditAccType = () => {
    setEdit((prev) => !prev);
  };

  return (
    <Page
      className={classes.root}
      title="Division"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              division: '',
              employeenumber: '',
              name: '',
              position: '',
              department: '',
              account_type: '',
            }}
            validationSchema={
              Yup.object().shape({
                account_type: Yup.string().max(255).required('Account type is required'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                setFormData(values);
                console.log('try : ', formData);
                dispatch(updateDA(selectEmp, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setaddOpen(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // resetForm,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Autocomplete
                  onChange={(event, user) => {
                    handleSelectEmp(user);
                  }}
                  options={options.filter((a) => (divisionidofuser === a?.DIVISION_ID) && (a?.ACCOUNT_TYPE === 'User'))}
                  getOptionLabel={(option) => `${option.EMPLOYEE_NUMBER} ${option.EMPLOYEE_NAME}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Employee Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  )}
                />
                {/* <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Employee Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                  select
                >
                  <MenuItem
                    value=""
                    onClick={handleCollapse}
                  >
                    No User Available
                  </MenuItem>
                  {users ? (
                    users.map((user) => {
                      if (
                        user.DIVISION_ID
                        === divisionidofuser
                      ) {
                        if (
                          user.ACCOUNT_TYPE
                          !== 'Division Admin'
                        ) {
                          if (
                            user.ACCOUNT_TYPE
                            !== 'Division User'
                          ) {
                            if (
                              user.ACCOUNT_TYPE
                              !== 'Super Admin'
                            ) {
                              if (
                                user.ACCOUNT_TYPE
                                !== 'Vendor User'
                              ) {
                                return (
                                  <MenuItem
                                    value={user.EMPLOYEE_NAME}
                                    key={user.EMPLOYEE_NUMBER}
                                    onClick={() => handleSelectEmp(user)}
                                  >
                                    {user.EMPLOYEE_NUMBER}
                                    <br />
                                    {user.EMPLOYEE_NAME}
                                  </MenuItem>
                                );
                              }
                            }
                          }
                        }
                      }
                      return null;
                    })
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </TextField> */}
                {open ? (
                  <>
                    <Typography variant="h5" display="inline">
                      Emp Number
                    </Typography>
                    &nbsp;
                    <Typography style={{ marginLeft: 12.5 }} variant="h5" display="inline">
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {selectEmp}
                    </Typography>
                    <br />
                    <Typography variant="h5" display="inline">
                      Position
                    </Typography>
                    &nbsp;
                    <Typography style={{ marginLeft: 47 }} variant="h5" display="inline">
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dispPosition}
                    </Typography>
                    <br />
                    <Typography variant="h5" display="inline">
                      Department
                    </Typography>
                    &nbsp;
                    <Typography style={{ marginLeft: 21.5 }} variant="h5" display="inline">
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dispDepartment}
                    </Typography>
                    <br />
                    <Typography variant="h5" display="inline">
                      Account Type
                    </Typography>
                    &nbsp;
                    <Typography style={{ marginLeft: 9 }} variant="h5" display="inline">
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {dispAccType}
                    </Typography>
                    <br />
                    <br />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="edit"
                          value=""
                          onClick={handleEditAccType}
                          color="primary"
                        />
                      )}
                      label="Click to update account type"
                    />
                    {edit ? (
                      <>
                        <TextField
                          fullWidth
                          label="Account Type"
                          margin="normal"
                          name="account_type"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.account_type}
                          variant="outlined"
                          select
                        >
                          <MenuItem value="Division Admin">
                            Division Admin
                          </MenuItem>
                          <MenuItem value="Division User">
                            Division User
                          </MenuItem>
                        </TextField>
                        <Box
                          alignItems="center"
                          justifyContent="flex-end"
                        />
                        <div>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            onClick={handleChange}
                            fullWidth
                          >
                            Update
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                &nbsp;
                {/* <Box
                  alignItems="center"
                  justifyContent="flex-end"
                />
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    Add
                  </Button>
                </div> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

UserForm.propTypes = {
  users: PropTypes.array,
  divisionidofuser: PropTypes.string,
  setaddOpen: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default UserForm;
