import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  // Switch,
  Container,
  TextField,
  makeStyles,
  // Checkbox,
  // FormControlLabel,
  Typography,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateCategory } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CategoryDetails = ({
  selectedcategory,
  employeeNumber,
  refresh,
  setRefresh,
  setOpen,
  departments,
  divisionid,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deptid, setClickDept] = useState('');
  const [deptname, setClickDeptname] = useState('');
  const [clickdivisionid, setClickDivId] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleDeptClick = (dept) => {
    setClickDept(dept?.DEPARTMENT_ID || '');
    setClickDeptname(dept?.DEPARTMENT || '');
    setClickDivId(dept?.DIVISION_ID || '');
    setClickDivname(dept?.DIVISION || '');
    console.log(clickdivname);
  };

  return (
    <Page
      className={classes.root}
      title="Category"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={{
              category_name: selectedcategory.category_name || '',
              categoryID: selectedcategory.category_id || '',
              department_id: deptid || selectedcategory.department_id || '',
              department_name: deptname || selectedcategory.department_name || '',
              division_id: clickdivisionid || selectedcategory.division_id || '',
              division_name: clickdivname || selectedcategory.division_name || '',
              // service_type: selectedcategory.service_type?.toLowerCase() || '',
              service_type: (selectedcategory.service_type === 'BOTH' ? 'ticket request' : selectedcategory.service_type?.toLowerCase()) || '',
              external_flag: selectedcategory.external_flag || '',
              location_flag: selectedcategory.location_flag || '',
              category_status: selectedcategory.category_status || '',
              update_by: employeeNumber
            }}
            validationSchema={
              Yup.object().shape({
                category_name: Yup.string().max(255).required('Category is required')
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateCategory(selectedcategory.category_id, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpen(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // resetForm,
              /* setFieldValue, */
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  color="primary"
                  style={{ fontWeight: 500 }}
                  variant="h6"
                >
                  UPDATE CATEGORY
                </Typography>
                {accountType === 'Division Admin' ? (
                  <TextField
                    error={Boolean(touched.department_id && errors.department_id)}
                    fullWidth
                    helperText={touched.department_id && errors.department_id}
                    label="Select Department In Charge"
                    margin="normal"
                    name="department_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.department_id}
                    variant="outlined"
                  >
                    {departments ? (
                      departments.map((dept) => {
                        if (
                          dept.DIVISION_ID
                          === divisionid
                        ) {
                          return (
                            <MenuItem
                              value={dept.DEPARTMENT_ID}
                              key={dept.DEPARTMENT_ID}
                              onClick={() => handleDeptClick(dept)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {dept.DEPARTMENT}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                ) : (
                  <TextField
                    error={Boolean(touched.department_id && errors.department_id)}
                    fullWidth
                    helperText={touched.department_id && errors.department_id}
                    label="Select Department In Charge"
                    margin="normal"
                    name="department_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.department_id}
                    variant="outlined"
                  >
                    {departments ? (
                      departments.map((dept) => {
                        if (
                          dept.DIVISION_STATUS
                          === 'ACTIVE'
                        ) {
                          return (
                            <MenuItem
                              value={dept.DEPARTMENT_ID}
                              key={dept.DEPARTMENT_ID}
                              onClick={() => handleDeptClick(dept)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {dept.DEPARTMENT}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                )}
                <TextField
                  error={Boolean(touched.categoryID && errors.categoryID)}
                  fullWidth
                  helperText={touched.categoryID && errors.categoryID}
                  label="ID"
                  margin="normal"
                  name="categoryID"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.categoryID}
                  variant="outlined"
                  disabled
                />
                <TextField
                  error={Boolean(touched.category_name && errors.category_name)}
                  fullWidth
                  helperText={touched.category_name && errors.category_name}
                  label="Category"
                  margin="normal"
                  name="category_name"
                  onBlur={handleBlur}
                  onChange={(e) => handleChange(e)}
                  value={values.category_name}
                  // onChange={(e) => setCategoryName(e.target.value)}
                  variant="outlined"
                  multiline
                />
                <TextField
                  error={Boolean(touched.service_type && errors.service_type)}
                  fullWidth
                  helperText={touched.service_type && errors.service_type}
                  label="Select Service Type"
                  margin="normal"
                  name="service_type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  value={values.service_type}
                  variant="outlined"
                >
                  <MenuItem value="ticket">Ticket</MenuItem>
                  <MenuItem value="request">Request</MenuItem>
                  <MenuItem value="ticket request">Both</MenuItem>
                </TextField>
                <TextField
                  error={Boolean(touched.location_flag && errors.location_flag)}
                  fullWidth
                  helperText={touched.location_flag && errors.location_flag}
                  label="Allow requestor to insert location when choose this category?"
                  margin="normal"
                  name="location_flag"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  value={values.location_flag}
                  variant="outlined"
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </TextField>
                <TextField
                  error={Boolean(touched.external_flag && errors.external_flag)}
                  fullWidth
                  helperText={touched.external_flag && errors.external_flag}
                  label="This category can be viewed by external personnel?"
                  margin="normal"
                  name="external_flag"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  value={values.external_flag}
                  variant="outlined"
                >
                  <MenuItem value="Y">Yes</MenuItem>
                  <MenuItem value="N">No</MenuItem>
                </TextField>
                <TextField
                  error={Boolean(touched.category_status && errors.category_status)}
                  fullWidth
                  helperText={touched.category_status && errors.category_status}
                  label="Enable category?"
                  margin="normal"
                  name="category_status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  value={values.category_status}
                  variant="outlined"
                >
                  <MenuItem value="ENABLE">Yes</MenuItem>
                  <MenuItem value="DISABLE">No</MenuItem>
                </TextField>
                <div>
                  <br />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Update
                    </Button>
                  </Box>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

CategoryDetails.propTypes = {
  selectedcategory: PropTypes.object,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpen: PropTypes.func,
  divisionid: PropTypes.string,
  departments: PropTypes.array,
  accountType: PropTypes.string,
};

export default CategoryDetails;
