/* eslint-disable no-useless-escape */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect } from 'react';
import {
  Box,
  FormLabel,
  FormControl,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
  Button
} from '@material-ui/core';
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { fetchTicketDetails, fetchAttachmentList } from '../actions/service';
import { fetchAccountType } from '../actions/user';
import LoadingSpinner from './LoadingSpinner';
import WithdrawTicketForm from '../views/services/ServiceView/WithdrawTicketForm';
import DismissTicketForm from '../views/services/ServiceView/DismissTicketForm';
import CloseTicketForm from '../views/services/ServiceView/CloseTicketForm';
import UpdateTicketForm from '../views/services/ServiceView/UpdateTicketForm';
import AssignTicketForm from '../views/services/ServiceView/AssignTicketForm';
import AcknowledgeTicketForm from '../views/services/ServiceView/AcknowledgeTicketForm';
import ForwardTicketForm from '../views/services/ServiceView/ForwardTicketForm';
import ForwardChangeOwnerTicketForm from '../views/services/ServiceView/ForwardChangeOwnerTicketForm';
import DelegateTaskForm from '../views/services/ServiceView/DelegateTaskForm';
import ApprovalTicketForm from '../views/approval/ApprovalView/ApprovalTicketForm';
import EquipmentRequestDetails from '../views/equipment/EquipmentView/EquipmentRequestDetails';
import Popup from './Popup';

function TicketDetails({
  ticket,
  user,
  setOpenPopup,
  currentPage,
  refresh,
  setRefresh
}) {
  const { serviceRefno } = ticket;
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState([]);
  const [details, setDetails] = useState([]);
  const [message, setMessage] = useState('');
  const [localRefresh, setLocalRefresh] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDismiss, setOpenDismiss] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openForward, setOpenForward] = useState(false);
  const [openForwardChangeOwner, setOpenForwardChangeOwner] = useState(false);
  const [openDelegateTask, setOpenDelegateTask] = useState(false);
  const [openStartJob, setOpenStartJob] = useState(false);
  const [openEquipmentRequest, setOpenEquipmentRequest] = useState(false);
  const [arrayLength, setArrayLength] = useState(0);
  const [accountType, setAccountType] = useState(null);
  const employeeNumber = user?.EMPLOYEE_NUMBER || null;
  const [tempAttachments, setTempAttachments] = useState([]);

  const handleError = (error) => {
    setOpenMessage(!openMessage);
    setMessage(error);
  };

  const handleUpdate = () => {
    setOpenUpdate(!openUpdate);
  };

  const handleClose = () => {
    setOpenClose(!openClose);
  };

  const handleWithdraw = () => {
    setOpenWithdraw(!openWithdraw);
  };

  const handleAssign = () => {
    setOpenAssign(!openAssign);
  };

  const handleForward = () => {
    setOpenForward(!openForward);
  };

  const handleForwardChangeOwner = () => {
    setOpenForwardChangeOwner(!openForwardChangeOwner);
  };

  const handleDelegateTask = () => {
    setOpenDelegateTask(!openDelegateTask);
  };

  const handleStartJob = () => {
    setOpenStartJob(!openStartJob);
  };

  const handleDismiss = () => {
    setOpenDismiss(!openDismiss);
  };

  const closeAllAction = () => {
    if (openUpdate) {
      setOpenUpdate(false);
    }
    if (openAssign) {
      setOpenAssign(false);
    }
    if (openWithdraw) {
      setOpenWithdraw(false);
    }
    if (openForward) {
      setOpenForward(false);
    }
    if (openForwardChangeOwner) {
      setOpenForwardChangeOwner(false);
    }
    if (openDelegateTask) {
      setOpenDelegateTask(false);
    }
    if (openStartJob) {
      setOpenStartJob(false);
    }
    if (openClose) {
      setOpenClose(false);
    }
    if (openDismiss) {
      setOpenDismiss(false);
    }
  };

  const handleChange = (event) => {
    if (event.target.value === 'withdraw') {
      closeAllAction();
      handleWithdraw();
    } else if (event.target.value === 'update') {
      closeAllAction();
      handleUpdate();
    } else if (event.target.value === 'assign') {
      closeAllAction();
      handleAssign();
    } else if (event.target.value === 'forward') {
      closeAllAction();
      handleForward();
    } else if (event.target.value === 'forwardchangeowner') {
      closeAllAction();
      handleForwardChangeOwner();
    } else if (event.target.value === 'delegateTask') {
      closeAllAction();
      handleDelegateTask();
    } else if (event.target.value === 'startJob') {
      closeAllAction();
      handleStartJob();
    } else if (event.target.value === 'close') {
      closeAllAction();
      handleClose();
    } else if (event.target.value === 'dismiss') {
      closeAllAction();
      handleDismiss();
    } else if (event.target.value === 'none') {
      closeAllAction();
    }
  };

  const checkAttachment = async (refno, autoNo) => {
    await dispatch(fetchAttachmentList(refno, autoNo))
      .then((res) => {
        if (res?.success === false) {
          setTempAttachments([]);
        } else {
          setTempAttachments(res?.attachment);
        }
      })
      .catch((err) => {
        console.log('attachment error: ', err);
        handleError(err);
      });
  };

  useEffect(() => {
    // Retrieve attachment associate
    checkAttachment(serviceRefno);

    // Close all action
    closeAllAction();

    // Retrieve Ticket details
    dispatch(fetchTicketDetails(serviceRefno))
      .then((res) => {
        if (res?.success === false) {
          handleError(res?.message || null);
        } else {
          setDetails(res?.data.listOfDetails);
          setHeaders(res?.data.header || []);
          setArrayLength(details.length);
        }
      })
      .catch((err) => {
        handleError(err);
      });

    // Retrieve user account type to show action radio button
    dispatch(fetchAccountType(employeeNumber))
      .then(({ data }) => {
        setAccountType(data?.accountType);
      });
  }, [details?.length, refresh, localRefresh]);

  const formatDate = (tempDate) => {
    // Split timestamp into [ Y, M, D, h, m, s ]
    const datetime = tempDate;
    const t = datetime.substring(0, 19).split(/[- .T:]/);

    // Apply each element to the Date function
    const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    return d;
  };

  return (

    <Box>
      <LoadingSpinner />
      {openMessage ? (
        <>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Something when wrong ☹️😵 —
            <strong>{` ${message}`}</strong>
          </Alert>
        </>
      ) : (
        <>
          {headers.map((header) => (
            <>
              <Typography>
                Service Number:
                <strong>{`  ${header.SERVICE_REFNO || null}`}</strong>
              </Typography>
              <Typography>
                <Box display="flex">
                  <Box>
                    Service Type:
                  </Box>
                  <Box
                    bgcolor={header?.SERVICE_TYPE.toUpperCase() === 'TICKET' ? '#2196f3' : '#800080'} // Blue or Purple
                    color="#ffff"
                    borderRadius={9}
                  >
                    <Box
                      // p={1}
                      paddingLeft={1}
                      paddingRight={1}
                    >
                      <strong>{`  ${header?.SERVICE_TYPE?.toUpperCase() || null}`}</strong>
                    </Box>
                  </Box>
                </Box>
              </Typography>
              <Typography>
                Title:
                <strong>{`  ${header.SERVICE_TITLE || null}`}</strong>
              </Typography>
              <br />
              <Typography>
                Category:
                <strong>{`  ${header.CATEGORY_NAME || header.CATEGORY || 'None'}`}</strong>
              </Typography>
              <Typography>
                Module:
                <strong>{`  ${header.SERVICE_MODULE_NAME || header.SERVICE_MODULE || 'None'}`}</strong>
              </Typography>
              <Typography>
                Severity:
                <strong>{`  ${header.SEVERITY_NAME || header.SEVERITY_ID || 'None'}`}</strong>
              </Typography>
              <Typography>
                Create Date:
                <strong>{`  ${formatDate(header.CREATE_DATE) || null}`}</strong>
              </Typography>
              <Typography>
                Overall Status:
                <strong>{`  ${header.SERVICE_STATUS_NAME || header.SERVICE_STATUS || null}`}</strong>
              </Typography>
              <Typography>
                Contact Number:
                <strong>{`  ${header?.PHONE_EXT_NO || ' -'}`}</strong>
              </Typography>
              <br />
              {(header?.EQUIPMENT_REQUEST_REFNO !== null) && (
                <>
                  <Typography>
                    Equipment Request Reference Number:
                    <Button
                      aria-label="Click to view consent agreement"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setOpenEquipmentRequest(true);
                      }}
                    >
                      <strong>{` ${header?.EQUIPMENT_REQUEST_REFNO}`}</strong>
                    </Button>
                  </Typography>

                  <Popup
                    openPopup={openEquipmentRequest}
                    setOpenPopup={setOpenEquipmentRequest}
                    title="Equipment Request Details"
                  >
                    <EquipmentRequestDetails
                      user={user}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      setOpenPopup={openEquipmentRequest}
                      data={header}
                    />
                  </Popup>
                </>
              )}
              {(header?.EXTERNAL_REFNO !== null) && (
                <>
                  <Typography>
                    External Reference Number:
                    <strong>{` ${header?.EXTERNAL_REFNO || '-'} (${header?.EXTERNAL_REFNAME})`}</strong>
                  </Typography>
                </>
              )}
              {(header?.LOCATION_ID !== null && header?.LOCATION_ID !== '') && (
                <>
                  <Typography>
                    Location:
                    <strong>{` ${header?.LOCATION_NAME || header?.LOCATION_ID || null}`}</strong>
                  </Typography>
                </>
              )}
              {(header?.LOCATION_REMARKS !== null && header?.LOCATION_REMARKS !== '') && (
                <>
                  <Typography>
                    Location Remarks:
                    <strong>{` ${header?.LOCATION_REMARKS?.toUpperCase() || null}`}</strong>
                  </Typography>
                </>
              )}
              <br />
              <Divider />

              <Typography>
                Creator:
                <strong>{` ${header.EMPLOYEE_NUMBER || ''} - ${header.EMPLOYEE_NAME || header.EMPLOYEE_NUMBER || null}`}</strong>
              </Typography>
              <Typography>
                Department:
                <strong>{`  ${header.DEPARTMENT_NAME || header.DEPARTMENT_ID || null}`}</strong>
              </Typography>
              <Typography>
                Email:
                <strong>{`  ${header.EMAIL || header.EMAIL || null}`}</strong>
              </Typography>
              {(header.PHONE_NO1 !== null) && (
                <Typography>
                  Phone No 1:
                  <strong>{`  ${header.PHONE_NO1 || header.PHONE_NO1 || null}`}</strong>
                </Typography>
              )}
              {(header.PHONE_NO2 !== null) && (
                <Typography>
                  Phone No 2:
                  <strong>{`  ${header.PHONE_NO2 || header.PHONE_NO2 || null}`}</strong>
                </Typography>
              )}
              {(header.MOBILE_NO !== null) && (
                <Typography>
                  H/P:
                  <strong>{`  ${header.MOBILE_NO || header.MOBILE_NO || null}`}</strong>
                </Typography>
              )}
              {(header.EMPLOYEE_NAME_2 !== null && header.EMPLOYEE_NAME_2 !== '') && (
                <>
                  <Typography>
                    Sub-Creator:
                    <strong>{`${header.EMPLOYEE_NUMBER_2 || ''} - ${header.EMPLOYEE_NAME_2 || header.EMPLOYEE_NUMBER_2 || null}`}</strong>
                  </Typography>
                  <Typography>
                    Sub-Creator Email:
                    <strong>{`  ${header.EMAIL_EMP2 || header.EMAIL_EMP2 || null}`}</strong>
                  </Typography>
                  {(header.PHONE_NO1 !== null) && (
                    <Typography>
                      Sub-Creator Phone No 1:
                      <strong>{`  ${header.PHONE_NO1_EMP2 || header.PHONE_NO1_EMP2 || null}`}</strong>
                    </Typography>
                  )}
                  {(header.PHONE_NO1 !== null) && (
                    <Typography>
                      Sub-Creator Phone No 2:
                      <strong>{`  ${header.PHONE_NO2_EMP2 || header.PHONE_NO2_EMP2 || null}`}</strong>
                    </Typography>
                  )}
                  {(header.MOBILE_NO !== null) && (
                    <Typography>
                      Sub-Creator H/P:
                      <strong>{`  ${header.MOBILE_NO_EMP2 || header.MOBILE_NO_EMP2 || null}`}</strong>
                    </Typography>
                  )}
                </>
              )}
              <br />
              <Divider />
              {(header.CUSTOMER_ID !== null && header.CUSTOMER_ID !== '') && (
                <>
                  <Typography>
                    User/Customer Details
                  </Typography>
                  <Typography>
                    User/Customer:
                    <strong>{` ${header.CUSTOMER_ID || ''} - ${header.CUSTOMER_NAME || header.CUSTOMER_ID || null}`}</strong>
                  </Typography>
                  <Typography>
                    User Dept/Customer Company:
                    <strong>{` ${header.REPORT_FROM || ''} - ${header.REPORT_FROM_NAME || header.REPORT_FROM || null}`}</strong>
                  </Typography>
                  <Typography>
                    Contacted By:
                    <strong>{` ${header.CONTACT_BY || null}`}</strong>
                  </Typography>
                </>
              )}
              <br />
              {header?.CURRENT_PIC !== null ? (
                <>
                  <Typography>
                    Main PIC:
                    <strong>{` ${header?.PIC || '-'} - ${header?.MAIN_PIC_NAME}`}</strong>
                  </Typography>
                  <Typography>
                    Current PIC:
                    <strong>{` ${header?.CURRENT_PIC || '-'} - ${header?.CURRENT_PIC_NAME}`}</strong>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography>
                    Main PIC:
                    <strong>{` ${header?.PIC || '-'} - ${header?.MAIN_PIC_NAME}`}</strong>
                  </Typography>
                </>
              )}
              <br />
            </>
          ))}
          <Divider />
          <Box mt={2} mb={1}>
            <Typography>
              Resolution History :
            </Typography>
            {details.length !== 0 && (
              details.map((detail, x) => (
                <>
                  <Box
                    mt={1}
                    mb={1}
                    color={
                      (detail.SERVICE_STATUS === 'PNU' || detail.SERVICE_STATUS === 'PER' || detail.SERVICE_STATUS === 'RSR') ? ('#fff')
                        : (detail.SERVICE_STATUS === 'AUR' || detail.SERVICE_STATUS === 'APR') ? ('rgba(0, 0, 0, 0.87)')
                          : (detail.SERVICE_STATUS === 'RUR' || detail.SERVICE_STATUS === 'RJR') ? ('#fff')
                            : (detail.SERVICE_STATUS === 'HCL' || detail.SERVICE_STATUS === 'SCL') ? ('text.primary')
                              : ('text.primary')
                    }
                    border={1}
                    borderColor="grey.200"
                    borderRadius={6}
                    bgcolor={
                      (detail.SERVICE_STATUS === 'PNU' || detail.SERVICE_STATUS === 'PER' || detail.SERVICE_STATUS === 'RSR') ? ('info.light')
                        : (detail.SERVICE_STATUS === 'AUR' || detail.SERVICE_STATUS === 'APR') ? ('success.light')
                          : (detail.SERVICE_STATUS === 'RUR' || detail.SERVICE_STATUS === 'RJR') ? ('error.light')
                            : (detail.SERVICE_STATUS === 'HCL' || detail.SERVICE_STATUS === 'SCL') ? ('action.disabled')
                              : (detail.SERVICE_STATUS === 'DLG' || detail.DELEGATE_FLAG === 'true'/* || headers.some((header) => header.DELEGATE_FLAG === 'true') */) ? '#F5C781'
                                : ('grey.200')
                    }
                  >
                    <Box m={1}>
                      <Typography>
                        {`${detail.AUTO_NO}. `}
                        FROM :
                        <strong>{`${detail.CREATED_BY || ''} - ${detail.EMPLOYEE_NAME || detail.CREATED_BY}`}</strong>
                        <Box
                          m={2}
                          mt={-0.5}
                        >
                          DATE :
                          <strong>{` ${formatDate(detail.CREATE_DATE) || '-'}`}</strong>
                        </Box>
                      </Typography>
                      <Typography>
                        <Box
                          p={2}
                        >
                          {
                            detail?.DESCRIPTION?.length > 0
                              ? (
                                detail?.DESCRIPTION.split('\n').map((l, i) => (
                                  <>
                                    {l !== '\r' ? (
                                      <p key={i}>
                                        {l || null}
                                      </p>
                                    ) : (
                                      <>
                                        <br />
                                      </>
                                    )}
                                  </>
                                ))
                              ) : (
                                <p><i>No Description</i></p>
                              )
                          }
                          {detail?.UNRESOLVED_REMARKS?.length > 0
                            && (
                              <>
                                <br />
                                <p>Remarks: </p>
                                {detail?.UNRESOLVED_REMARKS.split('\n').map((l, i) => (
                                  <>
                                    <p key={i}>{l || <br />}</p>
                                  </>
                                ))}
                              </>
                            )}
                          {(detail?.APPROVAL_REMARKS?.length > 0)
                            && (
                              <>
                                <br />
                                <p>Remarks: </p>
                                {detail?.APPROVAL_REMARKS.split('\n').map((l, i) => (
                                  <>
                                    <p key={i}>{l || <br />}</p>
                                  </>
                                ))}
                              </>
                            )}
                        </Box>
                      </Typography>
                      {tempAttachments?.length !== 0 && (
                        <>
                          <Typography>
                            <Box
                              p={2}
                            >
                              Attachment :
                              <br />
                              {tempAttachments.map((attachment) => (
                                <>
                                  {attachment?.AUTO_NO === detail?.AUTO_NO && (
                                    <>
                                      <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/service/download/file/${attachment?.ATTACHMENT_NAME}`} rel="noreferrer">
                                        {attachment?.ATTACHMENT_NAME}
                                      </a>
                                      <br />
                                    </>
                                  )}
                                </>
                              ))}
                            </Box>
                          </Typography>

                        </>
                      )}

                      <Typography>
                        Status :
                        <strong>{` ${detail.SERVICE_STATUS_NAME || detail.SERVICE_STATUS}`}</strong>
                        <Box>
                          {detail?.SOLVED_VIA?.length > 0 && (
                            <>
                              <br />
                              Solved Via :
                              <strong>{` ${detail?.SOLVED_VIA_NAME || detail?.SOLVED_VIA}`}</strong>
                            </>
                          )}
                          {detail?.VENDOR_ID?.length > 0
                            && (
                              <>
                                <br />
                                Vendor :
                                <strong>{` ${detail?.VENDOR_ID || ''} - ${detail?.VENDOR_NAME || ''}`}</strong>
                              </>
                            )}
                          {detail?.EXTERNAL_REFNO?.length > 0 && (
                            <>
                              <br />
                              External Service Number:
                              <strong>{` ${detail?.EXTERNAL_REFNO || '-'} (${detail?.EXTERNAL_REFNAME || '-'})`}</strong>
                            </>
                          )}
                          {detail?.DELEGATE_FLAG === 'true' && (
                            <>
                              <br />
                              Current PIC:
                              <strong>{` ${detail?.CURRENT_PIC || '-'} - ${detail?.CURRENT_PIC_NAME}`}</strong>
                            </>
                          )}
                        </Box>
                      </Typography>
                    </Box>
                    {arrayLength === x + 1 && (
                      <>
                        <IconButton
                          aria-label="refresh"
                          color="default"
                          onClick={() => setLocalRefresh(!localRefresh)}
                          edge="end"
                        >
                          <RefreshIcon />
                        </IconButton>
                        <Divider variant="middle" />
                        <Box mt={2} width="auto">
                          {detail?.SERVICE_STATUS ? (
                            <>
                              {currentPage === 'NormalView' ? (
                                <>
                                  <Box p={2} width="auto">
                                    <FormControl component="fieldset">
                                      <RadioGroup aria-label="Action" name="action" onChange={handleChange}>
                                        {headers[0]?.SERVICE_STATUS !== 'WDW' ? (
                                          <>
                                            <FormLabel component="legend">Action</FormLabel>
                                            {
                                              (headers[0]?.SERVICE_STATUS !== 'DIS'
                                                && headers[0]?.SERVICE_STATUS !== 'SOL'
                                                && headers[0]?.SERVICE_STATUS !== 'SCL'
                                                && headers[0]?.SERVICE_STATUS !== 'HCL'
                                                && headers[0]?.SERVICE_STATUS !== 'PNU'
                                                && headers[0]?.SERVICE_STATUS !== 'AUR'
                                                && headers[0]?.SERVICE_STATUS !== 'RJR'
                                                && headers[0]?.SERVICE_STATUS !== 'URS')
                                              && (
                                                <>
                                                  {headers[0]?.DELEGATE_FLAG === 'true' ? (
                                                    <FormControlLabel value="withdraw" control={<Radio />} label="Withdraw" />
                                                  ) : (
                                                    <>
                                                      <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                      <FormControlLabel value="withdraw" control={<Radio />} label="Withdraw" />
                                                    </>
                                                  )}
                                                </>
                                              )
                                            }
                                            {(
                                              headers[0]?.SERVICE_STATUS === 'SOL'
                                              || headers[0]?.SERVICE_STATUS === 'DIS'
                                              || headers[0]?.SERVICE_STATUS === 'AUR'
                                              || headers[0]?.SERVICE_STATUS === 'URS'
                                            )
                                              && (
                                                <>
                                                  <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                  <FormControlLabel value="close" control={<Radio />} label="Close" />
                                                </>
                                              )}
                                            {(
                                              headers[0]?.SERVICE_STATUS === 'RJR'
                                            )
                                              && (
                                                <>
                                                  <FormControlLabel value="close" control={<Radio />} label="Close" />
                                                </>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {(
                                              headers[0]?.SERVICE_STATUS === 'HCL'
                                              || headers[0]?.SERVICE_STATUS === 'SCL'
                                              || headers[0]?.SERVICE_STATUS === 'WDW'
                                            )
                                              && (
                                                <>
                                                  <Box
                                                    bgcolor="common.white"
                                                    color="text.primary"
                                                    borderRadius={12}
                                                    fontFamily="Roboto"
                                                  >
                                                    <Box
                                                      m={1}
                                                      p={1}
                                                    >
                                                      You can no longer respond to this service. ☹️
                                                    </Box>
                                                  </Box>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </Box>

                                  <Divider variant="middle" />
                                  {openUpdate && (
                                    <>
                                      <Box>
                                        <UpdateTicketForm
                                          serviceRefno={detail?.SERVICE_REFNO || null}
                                          user={user}
                                          accountType={accountType}
                                          setOpenPopup={setOpenPopup}
                                          openUpdate
                                          setOpenUpdate
                                          currentPage={currentPage}
                                          refresh={refresh}
                                          setRefresh={setRefresh}
                                          type={headers[0]?.SERVICE_TYPE || ''}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {openWithdraw && (
                                    <>
                                      <Box>
                                        <WithdrawTicketForm
                                          serviceRefno={detail?.SERVICE_REFNO || null}
                                          user={user}
                                          setOpenPopup={setOpenPopup}
                                          refresh={refresh}
                                          setRefresh={setRefresh}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {openClose && (
                                    <>
                                      <Box>
                                        <CloseTicketForm
                                          serviceRefno={detail?.SERVICE_REFNO || null}
                                          user={user}
                                          setOpenPopup={setOpenPopup}
                                          refresh={refresh}
                                          setRefresh={setRefresh}
                                        />
                                      </Box>
                                    </>
                                  )}
                                </>
                              ) : currentPage === 'ApprovalView' ? (
                                <>
                                  {(headers[0]?.SERVICE_STATUS === 'PNU'
                                    || headers[0]?.SERVICE_STATUS === 'PER')
                                    ? (
                                      <>
                                        <ApprovalTicketForm
                                          serviceRefno={detail?.SERVICE_REFNO || null}
                                          user={user}
                                          setOpenPopup={setOpenPopup}
                                          refresh={refresh}
                                          setRefresh={setRefresh}
                                          pic={headers[0]?.PIC || headers[0]?.EMPLOYEE_NUMBER}
                                        />
                                      </>
                                    ) : (headers[0]?.SERVICE_STATUS === 'RSR') ? (
                                      <>
                                        {headers[0]?.PIC_SUPERIOR_EMPLOYEE_NUMBER
                                          === user?.EMPLOYEE_NUMBER
                                          ? (
                                            <>
                                              <ApprovalTicketForm
                                                serviceRefno={detail?.SERVICE_REFNO || null}
                                                user={user}
                                                setOpenPopup={setOpenPopup}
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                                pic={headers[0]?.PIC || headers[0]?.EMPLOYEE_NUMBER}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <Box
                                                bgcolor="common.white"
                                                color="text.primary"
                                                m={2}
                                                borderRadius={12}
                                                p={2}
                                                fontFamily="Roboto"
                                              >
                                                Respond is unavailable right now. ☹️
                                              </Box>
                                            </>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        <Box
                                          bgcolor="common.white"
                                          color="text.primary"
                                          m={2}
                                          borderRadius={12}
                                          p={2}
                                          fontFamily="Roboto"
                                        >
                                          Respond is unavailable right now. ☹️
                                        </Box>
                                      </>
                                    )}
                                </>
                              ) : (
                                // View for manage service
                                <>
                                  {(
                                    accountType === 'Division User'
                                    || accountType === 'Division Admin'
                                    || accountType === 'Super Admin'
                                  ) ? (
                                    <>
                                      <Box p={1}>
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend">Action</FormLabel>
                                          <RadioGroup aria-label="Action" name="action" onChange={handleChange}>
                                            {(detail.SERVICE_STATUS === 'NEW') ? (
                                              <>
                                                <FormControlLabel value="assign" control={<Radio />} label="Assign" />
                                                <FormControlLabel value="dismiss" control={<Radio />} label="Dismiss" />
                                                {(accountType === 'Division Admin' || accountType === 'Super Admin') && (
                                                  <FormControlLabel value="forwardchangeowner" control={<Radio />} label="Change Ownership" />
                                                )}
                                              </>
                                            ) : detail.SERVICE_STATUS === 'ASG' ? (
                                              <>
                                                <FormControlLabel value="startJob" control={<Radio />} label="Start Job" />
                                                <FormControlLabel value="assign" control={<Radio />} label="Re-Assign" />
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'WIP' && headers.some((header) => header.DELEGATE_FLAG !== 'true' && header.PIC === user.EMPLOYEE_NUMBER)) ? (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                                <FormControlLabel value="delegateTask" control={<Radio />} label="Delegate Task" />
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'WIP' && headers.some((header) => header.DELEGATE_FLAG !== 'true' && header.PIC !== user.EMPLOYEE_NUMBER)) ? (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'TSK' && headers.some((header) => header.DELEGATE_FLAG !== 'true' && header.PIC === user.EMPLOYEE_NUMBER)) ? (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                                <FormControlLabel value="delegateTask" control={<Radio />} label="Delegate Task" />
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'TSK' && headers.some((header) => header.DELEGATE_FLAG !== 'true' && header.PIC !== user.EMPLOYEE_NUMBER)) ? (
                                              <>
                                                <Box
                                                  bgcolor="common.white"
                                                  color="text.primary"
                                                  borderRadius={12}
                                                  fontFamily="Roboto"
                                                >
                                                  <Box
                                                    m={1}
                                                    p={1}
                                                  >
                                                    You can no longer respond to this service. ☹️
                                                  </Box>
                                                </Box>
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'SOL') ? (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                              </>
                                            ) : (headers.some((header) => header.DELEGATE_FLAG === 'true')) ? (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                              </>
                                            ) : detail.SERVICE_STATUS === 'UPD' || detail.SERVICE_STATUS === 'APR' ? (
                                              <>
                                                {headers[0].SERVICE_STATUS === 'NEW' ? (
                                                  <>
                                                    <FormControlLabel value="assign" control={<Radio />} label="Assign" />
                                                    <FormControlLabel value="dismiss" control={<Radio />} label="Dismiss" />
                                                    {(accountType === 'Division Admin' || accountType === 'Super Admin') && (
                                                      <FormControlLabel value="forwardchangeowner" control={<Radio />} label="Change Ownership" />
                                                    )}
                                                  </>
                                                ) : headers[0].SERVICE_STATUS === 'ASG' ? (
                                                  <>
                                                    <FormControlLabel value="startJob" control={<Radio />} label="Start Job" />
                                                    <FormControlLabel value="assign" control={<Radio />} label="Re-Assign" />
                                                  </>
                                                ) : (
                                                  <>
                                                    <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                    <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                                  </>
                                                )}
                                              </>
                                            ) : detail.SERVICE_STATUS === 'WDW' || detail.SERVICE_STATUS === 'SCL' || detail.SERVICE_STATUS === 'HCL' ? (
                                              <>
                                                <Box
                                                  bgcolor="common.white"
                                                  color="text.primary"
                                                  borderRadius={12}
                                                  fontFamily="Roboto"
                                                >
                                                  <Box
                                                    m={1}
                                                    p={1}
                                                  >
                                                    You can no longer respond to this service. ☹️
                                                  </Box>
                                                </Box>
                                              </>
                                            ) : (
                                              <>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward" />
                                              </>
                                            )}
                                          </RadioGroup>
                                        </FormControl>
                                      </Box>
                                      <Divider variant="middle" />
                                      {openAssign && (
                                        <>
                                          <Box
                                            bgcolor="common.white"
                                            color="text.primary"
                                            m={2}
                                            borderRadius={12}
                                          >
                                            <AssignTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              currentCategory={headers[0]?.CATEGORY}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openUpdate && (
                                        <>
                                          <Box>
                                            <UpdateTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              accountType={accountType}
                                              setOpenPopup={setOpenPopup}
                                              currentPage={currentPage}
                                              openUpdate
                                              setOpenUpdate
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openForward && (
                                        <>
                                          <Box>
                                            <ForwardTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                              accountType={accountType}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openForwardChangeOwner && (
                                        <>
                                          <Box>
                                            <ForwardChangeOwnerTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                              accountType={accountType}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openDelegateTask && (
                                        <>
                                          <Box>
                                            <DelegateTaskForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                              accountType={accountType}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openStartJob && (
                                        <>
                                          <Box p={2}>
                                            <AcknowledgeTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                            />
                                          </Box>
                                        </>
                                      )}

                                      {openDismiss && (
                                        <>
                                          <Box p={2}>
                                            <DismissTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                            />
                                          </Box>
                                        </>
                                      )}
                                    </>
                                  ) : accountType === 'Vendor User' ? (
                                    <>
                                      <Box p={1}>
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend">Action</FormLabel>
                                          <RadioGroup aria-label="Action" name="action" onChange={handleChange}>
                                            {/* <FormControlLabel
                                              value="none"
                                              control={<Radio />}
                                              label="None"
                                            /> */}
                                            {detail.SERVICE_STATUS === 'ASG' ? (
                                              <>
                                                <FormControlLabel value="startJob" control={<Radio />} label="Start Job" />
                                              </>
                                            ) : (detail.SERVICE_STATUS === 'WIP' || detail.SERVICE_STATUS === 'SOL' || detail.SERVICE_STATUS === 'DLG') ? (
                                              <>
                                                {/*
                                                <FormControlLabel
                                                value="update"
                                                 control={<Radio />}
                                                 label="Update"
                                                  />
                                                 */}
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />
                                              </>
                                            ) : detail.SERVICE_STATUS === 'UPD' || detail.SERVICE_STATUS === 'APR' ? (
                                              <>
                                                {/*
                                                 <FormControlLabel
                                                 value="update"
                                                  control={<Radio />}
                                                   label="Update"
                                                   />
                                                 */}
                                                <FormControlLabel value="update" control={<Radio />} label="Update" />

                                              </>
                                            ) : detail.SERVICE_STATUS === 'WDW' ? (
                                              <>
                                                {/* Dont show any action if ticket withdraw */}
                                              </>
                                            ) : detail.SERVICE_STATUS === 'TSK' ? (
                                              <>
                                                <Box
                                                  bgcolor="common.white"
                                                  color="text.primary"
                                                  borderRadius={12}
                                                  fontFamily="Roboto"
                                                >
                                                  <Box
                                                    m={1}
                                                    p={1}
                                                  >
                                                    You can no longer respond to this service. ☹️
                                                  </Box>
                                                </Box>
                                              </>
                                            )
                                              : (
                                                <>
                                                  <FormControlLabel value="update" control={<Radio />} label="Update" />
                                                </>
                                              )}
                                          </RadioGroup>
                                        </FormControl>
                                      </Box>
                                      <Divider variant="middle" />
                                      {openForward && (
                                        <>
                                          <Box>
                                            <ForwardTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                              accountType={accountType}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openStartJob && (
                                        <>
                                          <Box p={2}>
                                            <AcknowledgeTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              setOpenPopup={setOpenPopup}
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                            />
                                          </Box>
                                        </>
                                      )}
                                      {openUpdate && (
                                        <>
                                          <Box>
                                            <UpdateTicketForm
                                              serviceRefno={detail?.SERVICE_REFNO || null}
                                              user={user}
                                              accountType={accountType}
                                              setOpenPopup={setOpenPopup}
                                              currentPage={currentPage}
                                              openUpdate
                                              setOpenUpdate
                                              refresh={refresh}
                                              setRefresh={setRefresh}
                                              currentModule={headers[0]?.SERVICE_MODULE}
                                              currentSeverity={headers[0]?.SEVERITY_ID}
                                              type={headers[0]?.SERVICE_TYPE || ''}
                                            />
                                          </Box>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Box px={2} mb={2}>
                                        <Typography>
                                          Action is unavailable for current user account. ☹️
                                        </Typography>
                                      </Box>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div>else</div>
                            </>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              ))
            )}
          </Box>
        </>
      )}
    </Box>

  );
}

TicketDetails.propTypes = {
  ticket: PropTypes.object,
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  currentPage: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default TicketDetails;
