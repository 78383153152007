import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import decode from 'jwt-decode';
import Page from 'src/components/Page';
import {
  createFaceIdData
} from '../../../actions/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  }
}));

function CreateFaceIdForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [openAcknowledgement, setOpenAcknowledgement] = useState(false);
  const [message, setMessage] = useState(null);
  const history = useNavigate();
  const {
    prismToken,
    facialId,
    timestamp,
    employeeNumber
  } = useParams();

  const checkToken = () => {
    const token = prismToken;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 6000 < new Date().getTime()) {
        history('/login');
      }
      if (decodedToken?.id !== employeeNumber) {
        history('/login');
      }
    } else {
      history('/login');
    }
  };

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      <Page
        className={classes.root}
        title="Setup"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container>
            {openAcknowledgement === false ? (
              <>
                <Formik
                  initialValues={{
                    faceioId: facialId,
                    activationDatetime: timestamp,
                    employeeNumber,
                    faceidStatus: 'active'
                  }}
                  validationSchema={
                    Yup.object().shape({
                      pin: Yup.string()
                        .matches(/^[0-9]+$/, 'Must be only digits')
                        .min(4, 'Must be exactly 4 digits')
                        .max(8, 'Must be exactly 8 digits')
                        .required('Please re-enter your face id pin')
                    })
                  }
                  onSubmit={
                    (values, { setSubmitting }) => {
                      dispatch(createFaceIdData(values, history))
                        .then((res) => {
                          if (res?.success === false) {
                            handleError(res?.message);
                          } else if (res?.success === true) {
                            setSubmitting(false);
                            setMessage(res?.message);
                            setOpenAcknowledgement(true);
                          }
                        })
                        .catch((err) => {
                          handleError(err?.message);
                        });
                      setSubmitting(false);
                    }
                  }
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>

                      <Card>
                        <CardHeader
                          subheader="Face ID setup"
                          title="Face ID"
                        />
                        <Divider />
                        <CardContent>
                          <TextField
                            error={Boolean(touched.pin && errors.pin)}
                            fullWidth
                            helperText={touched.pin && errors.pin}
                            label="Re-Enter Face ID PIN"
                            margin="normal"
                            name="pin"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.pin}
                            variant="outlined"
                          />
                        </CardContent>
                        <Divider />
                        <Box>
                          {error && (
                            <>
                              <Alert severity="error">{message}</Alert>
                            </>
                          )}
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            p={2}
                          >
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Box>
                        </Box>
                      </Card>
                    </form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <Card>
                  <CardHeader
                    subheader="Acknowledgement"
                    title="Face ID"
                  />
                  <Divider />
                  <CardContent>
                    <Alert severity="success">{message}</Alert>
                  </CardContent>
                </Card>
              </>
            )}
          </Container>
        </Box>
      </Page>
    </>

  );
}

export default CreateFaceIdForm;
