import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ExternalCompanyDetails from './externalCompanyDetails';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ListExternalCompany = ({
  externalCompany,
  // divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  // accountType
}) => {
  // const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // let vendorlist = [];
  let SAexternalCompanylist = [];

  /* if (externalCompany) {
    externalCompanylist = externalCompany.map((ext) => (
      {
        auto_no: ext.AUTO_NO,
        vendor_id: ext.VENDOR_ID || null,
        vendor_name: ext.VENDOR_NAME || '',
        vendor_status: ext.VENDOR_STATUS || '',
        division_id: ext.DIVISION_ID || null,
        division_name: ext.DIVISION_NAME || ''
      }
    )).filter((row) => row.division_id === divisionid) || [];
  } */
  if (externalCompany) {
    SAexternalCompanylist = externalCompany.map((ext) => (
      {
        gkey: ext.GKEY || null,
        external_id: ext.EXTERNAL_ID || null,
        external_name: ext.EXTERNAL_NAME || '',
        external_status: ext.EXTERNAL_STATUS || '',
        external_type: ext.EXTERNAL_TYPE || '',
        division_id: ext.DIVISION_ID || null,
        division_name: ext.DIVISION_NAME || ''
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF EXTERNAL COMPANY
      </Typography>
      &nbsp;
      {/* {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            //   { title: 'ID', field: 'vendor_id' },
            { title: 'Vendor Name', field: 'vendor_name' },
            { title: 'Status', field: 'vendor_status' }
          ]}
          data={vendorlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : ( */}
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={[
          { title: 'ID', field: 'external_id' },
          { title: 'Company Name', field: 'external_name' },
          { title: 'Role', field: 'external_type' },
          { title: 'Status', field: 'external_status' },
          // { title: 'Division', field: 'division_name' }
        ]}
        data={SAexternalCompanylist}
        options={{
          search: true,
          grouping: true,
          rowStyle: () => ({
            fontFamily: 'helvetica',
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        onRowClick={((event, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
      />
      {/* )} */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          External Company
        </DialogTitle>
        <DialogContent dividers>
          <ExternalCompanyDetails
            ext={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpen={setOpen}
            divisions={divisions}
            // accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

ListExternalCompany.propTypes = {
  externalCompany: PropTypes.array,
  // divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  // accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ListExternalCompany;
