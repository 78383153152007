import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Captcha from 'demos-react-captcha';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
// import { createEmployee } from '../../actions/logins';
import { register as Registers } from '../../actions/auth';
// import App from './captcha';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // height: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [captchabox, setCaptchabox] = useState(false);
  const [test, setTest] = useState(false);

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  const handlecaptchabox = () => {
    setCaptchabox((prev) => !prev);
    setTest(false);
  };

  const onChange = (value) => {
    setTest(value);
  };
  const onRefresh = () => {
    setTest(false);
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              name: '',
              employee_number: '',
              password: '',
              confirmPassword: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                name: Yup.string().max(255).required('Name is required'),
                password: Yup.string().max(255).required('password is required')
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                  ),
                confirmPassword: Yup
                  .string()
                  .required('Please confirm your password')
                  .when('password', {
                    is: (password) => (!!(password && password.length > 0)),
                    then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                  }),
                employee_number: Yup.string().min(6).required('Employee Number is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked'),
                captchabox: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                const { policy, ...data } = values;
                setSubmitting(true);
                // console.log(JSON.stringify(data, null, 2));
                // navigate('/app/dashboard', { replace: true });
                dispatch(Registers(data))
                  .then((res) => {
                    // console.log(res);
                    if (res?.success === false) {
                      handleError(res?.message);
                    } else if (res?.success === true) {
                      history('/ack');
                    }
                  })
                  .catch((err) => {
                    handleError(err?.message);
                  });
                setSubmitting(false);
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  m={2}
                  // pt={15}
                  pb={5}
                >
                  <Box
                    pt={3}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      New User Registration
                    </Typography>
                    {/* <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography> */}
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.employee_number && errors.employee_number)}
                      fullWidth
                      helperText={touched.employee_number && errors.employee_number}
                      label="Employee Number"
                      margin="normal"
                      name="employee_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.employee_number}
                      variant="outlined"
                      inputProps={{ maxLength: 6 }}
                    />
                    <Typography>
                      <Box mt={2}>
                        For employee with no PTP email,
                        please proceed by inserting your personal email in below field.
                      </Box>
                    </Typography>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      fullWidth
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      label="Confirm Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmPassword}
                      variant="outlined"
                    />
                    <Box
                      alignItems="center"
                      display="flex"
                      ml={-1}
                    >
                      <Checkbox
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        I have read the
                        {' '}
                        <Link
                          color="primary"
                          component={RouterLink}
                          to="#"
                          underline="always"
                          variant="h6"
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>
                        {errors.policy}
                      </FormHelperText>
                    )}
                    <Box
                      alignItems="center"
                      display="flex"
                      ml={-1}
                    >
                      <Checkbox
                        // checked={values.captchabox}
                        name="captchabox"
                        onClick={handlecaptchabox}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        I am not a robot
                      </Typography>
                    </Box>
                    {captchabox ? (
                      <>
                        {/* <App /> */}
                        <Captcha onChange={onChange} placeholder="Enter captcha" onRefresh={onRefresh} />
                      </>
                    ) : null}
                    {Boolean(touched.captchabox && errors.captchabox) && (
                      <FormHelperText error>
                        {errors.captchabox}
                      </FormHelperText>
                    )}
                    {error && (
                      <>
                        <Alert severity="error">{message}</Alert>
                      </>
                    )}
                    {/* {test ? true(
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Create
                        </Button>
                      </Box>
                    ) : ( */}
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={test === false}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Create
                      </Button>
                    </Box>
                    {/* )} */}
                  </Box>

                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
