/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
// import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Container,
  // Divider,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Page from 'src/components/Page';
import RichTextViewerJodit from 'src/components/RichTextViewerJodit';
import Popup from 'src/components/Popup';
import {
  listOfActiveEquipmentType,
  listOfActiveEquipmentItem,
  listOfActiveEquipmentPackage,
  listOfActiveConsentAgreement,
  createEquipmentRequest
} from 'src/actions/equipment';
import ConsentAgreementAcknowledgement from './ConsentAgreementAcknowledgement';
import LoadingSpinner from '../../../components/LoadingSpinner';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({

  red: {
    backgroundColor: '#ff726f',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f',
    }
  },
  green: {
    backgroundColor: '#90ee90',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#288327',
    }
  },
}));

function EquipmentRequestForm({
  user,
  setOpenPopup,
  refresh,
  setRefresh
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [equipmentType, setEquipmentType] = useState([]);
  const [equipmentItem, setEquipmentItem] = useState([]);
  const [equipmentPackage, setEquipmentPackage] = useState([]);
  const [rawEquipmentItem, setRawEquipmentItem] = useState([]);
  const [rawEquipmentPackage, setRawEquipmentPackage] = useState([]);
  const [tempEquipmentItem, setTempEquipmentItem] = useState([]);
  const [tempEquipmentType, setTempEquipmentType] = useState('');
  const [tempEquipmentPackage, setTempEquipmentPackage] = useState([]);
  const [tempJustification, setTempJustification] = useState([]);
  const [rawConsentAgreement, setRawConsentAgreement] = useState([]);
  const [tempConsentAgreement, setTempConsentAgreement] = useState('');
  const [consentAgreement, setConsentAgreement] = useState([]);
  const [tempAcknowledgement, setTempAcknowledgement] = useState(false);
  const [openConsentAgreement, setOpenConsentAgreement] = useState(false);
  const [tempInitialValue, setTempInitialValue] = useState({
    employeeNumber: user?.EMPLOYEE_NUMBER,
    equipmentItem: tempEquipmentItem,
    equipmentPackage: tempEquipmentPackage,
    equipmentType: tempEquipmentType,
    equipmentConsentAgreement: tempConsentAgreement,
    agreementAcknowledgement: tempAcknowledgement,
    justification: tempJustification
  });

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const changeConsentAgreement = (value) => {
    rawConsentAgreement?.forEach((row) => {
      if (row?.EQUIPMENT_TYPE_ID === value) {
        setTempConsentAgreement(row?.AGREEMENT_ID);
      }
    });
  };

  const filterSelection = (typeId) => {
    // Filter equipment Item variable based on selected equipment type
    setEquipmentItem(
      // If there is no selected id then make equipment item empty else put value
      typeId !== undefined
        ? (() => {
          const tempList = [];
          rawEquipmentItem?.forEach((row) => {
            if (row?.EQUIPMENT_TYPE_ID === typeId) {
              tempList.push({
                ...row
              });
            }
          });
          return tempList;
        })
        : []
    );

    // Filter equipment package variable based on selected equipment type
    setEquipmentPackage(
      // If there is no selected id then make equipment package empty else put value
      typeId !== undefined ? (() => {
        const tempList = [];
        rawEquipmentPackage?.forEach((row) => {
          if (row?.EQUIPMENT_TYPE_ID === typeId) {
            tempList.push({
              ...row
            });
          }
        });
        return tempList;
      })
        : []
    );

    // Filter equipment package variable based on selected equipment type
    setConsentAgreement(
      // If there is no selected id then make equipment package empty else put value
      typeId !== undefined ? (() => {
        const tempList = [];
        rawConsentAgreement?.forEach((row) => {
          if (row?.EQUIPMENT_TYPE_ID === typeId) {
            tempList.push({
              ...row
            });
          }
        });
        return tempList;
      })
        : []
    );
  };

  const handleClickItem = (value) => {
    setTempEquipmentItem(value);
  };

  const handleClickPackage = (value) => {
    setTempEquipmentPackage(value);
  };

  const handleClickType = (value) => {
    // If value is null then empty value in variable
    if (value === null || value !== tempEquipmentType) {
      setTempEquipmentItem([]);
      setTempEquipmentPackage([]);
      setTempAcknowledgement(false);
    }

    setTempEquipmentType(value?.TYPE_ID);

    // Filter drop down menu and data shown in form based on type selection
    filterSelection(value?.TYPE_ID);

    // Set consent agreement id based on type_id
    changeConsentAgreement(value?.TYPE_ID);
  };

  const retrieveData = () => {
    dispatch(listOfActiveEquipmentType())
      .then((res) => {
        setEquipmentType(res?.equipmentType);
      });
    dispatch(listOfActiveEquipmentItem())
      .then((res) => {
        setRawEquipmentItem(res?.equipmentItem);
      });
    dispatch(listOfActiveEquipmentPackage())
      .then((res) => {
        setRawEquipmentPackage(res?.equipmentPackage);
      });
    dispatch(listOfActiveConsentAgreement())
      .then((res) => {
        setRawConsentAgreement(res?.consentAgreement);
      });
  };

  useEffect(() => {
    retrieveData();
  }, []);

  // This use effect will update form value when change
  // this is to solve dissapearing value when choose attachments
  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      equipmentItem: tempEquipmentItem,
      equipmentPackage: tempEquipmentPackage,
      equipmentConsentAgreement: tempConsentAgreement,
      agreementAcknowledgement: tempAcknowledgement,
      equipmentType: tempEquipmentType,
      justification: tempJustification
    });
  }, [
    tempEquipmentItem,
    tempEquipmentPackage,
    tempAcknowledgement,
    tempEquipmentType,
    tempJustification,
    tempConsentAgreement
  ]);

  return (
    <Page
      title="Request Equipment"
    >
      <Box>
        <Formik
          enableReinitialize
          initialValues={tempInitialValue}
          validationSchema={
            Yup.object().shape({
              justification: Yup.string().max(500).min(2).required('Please provide justification.'),
              agreementAcknowledgement: Yup.boolean().oneOf([true], 'Please click agree to the agreement.'),
              equipmentItem: Yup.array().required('Provide select Item'),
              equipmentPackage: Yup.array().required('Provide select Package'),
            })
          }
          onSubmit={
            (values, { setSubmitting }) => {
              dispatch(createEquipmentRequest(values))
                .then((res) => {
                  if (res?.success !== true) {
                    setSubmitting(false);
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier,
                      },

                    });
                  } else {
                    setOpenPopup(false);
                    setRefresh(!refresh);
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier,
                      },
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                });
            }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <LoadingSpinner />
              <Box mb={2} mt={3}>
                <Typography>Request Details</Typography>
              </Box>
              <Paper>
                <Box mb={3}>
                  <Container>

                    <Autocomplete
                      options={
                        equipmentType
                      }
                      onChange={(event, value) => {
                        handleClickType(value);
                      }}
                      loading={equipmentType?.length === 0}
                      groupBy={(option) => option.DIVISION_NAME}
                      getOptionLabel={(option) => option.TYPE_NAME}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.equipmentType && errors.equipmentType)}
                          helperText={touched.equipmentType && errors.equipmentType}
                          label="Type"
                          variant="outlined"
                          id="equipmentType"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="equipmentType"
                          value={values.equipmentType}
                          fullWidth
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={equipmentItem}
                      error={Boolean(touched.equipmentItem && errors.equipmentItem)}
                      helperText={touched.equipmentItem && errors.equipmentItem}
                      disableCloseOnSelect
                      disabled={equipmentItem?.length === 0}
                      value={values.equipmentItem}
                      getOptionLabel={(option) => option?.ITEM_NAME}
                      onChange={(event, value) => {
                        handleClickItem(value);
                      }}
                      renderOption={(option, { selected }) => {
                        return (
                          <>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.ITEM_NAME}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            (tempEquipmentType === '' || tempEquipmentType === undefined)
                              ? 'Item'
                              : equipmentItem?.length !== 0
                                ? 'Item'
                                : 'No active Item for this equipment type. Please contact division admin for assistance'
                          }
                          variant="outlined"
                          id="equipmentItem"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="equipmentItem"
                          error={Boolean(touched.equipmentItem && errors.equipmentItem)}
                          helperText={touched.equipmentItem && errors.equipmentItem}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={equipmentPackage}
                      disableCloseOnSelect
                      disabled={equipmentPackage?.length === 0}
                      value={values.equipmentPackage}
                      error={Boolean(touched.equipmentPackage && errors.equipmentPackage)}
                      helperText={touched.equipmentPackage && errors.equipmentPackage}
                      getOptionLabel={(option) => option?.PACKAGE_NAME}
                      onChange={(event, value) => {
                        handleClickPackage(value);
                      }}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.PACKAGE_NAME}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            (tempEquipmentType === '' || tempEquipmentType === undefined)
                              ? 'Package'
                              : equipmentPackage?.length !== 0
                                ? 'Package'
                                : 'No active Item for this equipment type. Please contact division admin for assistance'
                          }
                          variant="outlined"
                          id="equipmentPackage"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="equipmentPackage"
                          value={values.equipmentPackage}
                          error={Boolean(touched.equipmentPackage && errors.equipmentPackage)}
                          helperText={touched.equipmentPackage && errors.equipmentPackage}
                        />
                      )}
                    />

                    <TextField
                      error={Boolean(touched.justification && errors.justification)}
                      fullWidth
                      helperText={touched.justification && errors.justification}
                      label="Justification"
                      id="justification"
                      margin="normal"
                      name="justification"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setTempJustification(event?.target.value);
                      }}
                      type="text"
                      value={values.justification}
                      variant="outlined"
                      multiline
                    />

                    <br />
                    <Button
                      variant="outlined"
                      className={consentAgreement?.length === 0 ? '' : (tempAcknowledgement === false ? classes.red : classes.green)}
                      tooltip="Read Agreement"
                      fullWidth
                      disabled={consentAgreement?.length === 0}
                      onClick={() => setOpenConsentAgreement(!openConsentAgreement)}
                    >
                      {consentAgreement?.length !== 0 ? 'Read Consent Agreement' : 'There is no active Consent Agreement for this equipment type. Please contact division admin for assistance'}
                    </Button>

                    <Popup
                      openPopup={openConsentAgreement}
                      setOpenPopup={setOpenConsentAgreement}
                      fullScreen
                      title={`${consentAgreement[0]?.AGREEMENT_NAME} Consent Agreement` || 'Consent Agreement'}
                    >
                      <RichTextViewerJodit
                        data={consentAgreement[0]?.AGREEMENT_DETAILS}
                      />
                      <ConsentAgreementAcknowledgement
                        data={tempAcknowledgement}
                        setData={setTempAcknowledgement}
                      />
                    </Popup>

                    <TextField
                      type="hidden"
                      id="consentAgreement"
                      margin="normal"
                      value={consentAgreement[0]?.AGREEMENT_ID || ''}
                      name="consentAgreement"
                    />

                    <Box m={1}>
                      {(errors.agreementAcknowledgement) && (
                        <>
                          <Alert severity="error">{errors.agreementAcknowledgement || ''}</Alert>
                        </>
                      )}
                      <Button
                        id="submit"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Request
                      </Button>
                    </Box>
                  </Container>
                </Box>
              </Paper>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
}

EquipmentRequestForm.propTypes = {
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default EquipmentRequestForm;
