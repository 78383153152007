import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';

const TopBar = ({
  transparent,
  className,
  children,
  ...rest
}) => {
  const useStyles = makeStyles(({
    root: { backgroundColor: 'transparent', },
    visible: {
      height: 70,
      backgroundColor: process.env.REACT_APP_SYSTEM_COLOUR,
      opacity: '1',
    },
    invisible: {
      height: 70,
      opacity: '0',
    },
    logo: {
      width: 120,
      opacity: '1',
    },
  }));
  const classes = useStyles();

  return (
    <>
      {transparent === true ? (
        // Hide topbar background
        <AppBar
          className={clsx(classes.root)}
          elevation={0}
          {...rest}
        >
          <Toolbar>
            <RouterLink to="/">
              <Logo
                className={classes.logo}
                src="/static/ptp_logo_2.png"
              />
              <Logo
                className={classes.logo}
                src="/static/images/PRISM1.svg"
              />
            </RouterLink>
          </Toolbar>
          {children}
        </AppBar>
      ) : (
        // Show topbar background
        <AppBar
          className={clsx(classes.root, className)}
          elevation={0}
          {...rest}
        >
          <Toolbar className={classes.visible}>
            <RouterLink to="/">
              <Logo
                width={120}
                src="/static/ptp_logo_2.png"
              />
              <Logo
                width={120}
                src="/static/images/PRISM1.svg"
              />
            </RouterLink>
          </Toolbar>
          {children}
        </AppBar>
      )}
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  transparent: PropTypes.bool,
  children: PropTypes.object
};

export default TopBar;
