import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Container,
  Box,
  Typography,
  Divider,
  CardHeader,
  Paper
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import Page from 'src/components/Page';
import ApprovalEquipmentRequestTable from './ApprovalEquipmentRequestTable';
// import useGeoLocation from 'src/components/useGeoLocation';
import ApprovalTicketTable, { ApprovedTicketTable } from './ApprovalTicketTable';
import { fetchApproval } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import useStyles from '../../../components/defaultPageStyle';

function Approval() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [user] = useState(
    JSON.parse(localStorage.getItem('profile'))?.result
  );
  const [approvalTicket, setApprovalTicket] = useState([]);
  const [approvalEquipment, setApprovalEquipment] = useState([]);
  const [currentPage] = useState('ApprovalView');
  const [refresh, setRefresh] = useState(true);
  const classes = useStyles();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  // const location = useGeoLocation();

  const fetchData = (skipLoadingLoop) => {
    dispatch(fetchApproval(user?.EMPLOYEE_NUMBER, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          setApprovalTicket(res?.approval || []);
          setApprovalEquipment(res?.equipment || []);
        } else {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }

          if (res?.relogin === true) {
            history('/login');
          }
        }
      });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => fetchData(true), 300000);
    return () => clearInterval(interval);
  }, [refresh]);

  return (
    <Page
      className={classes.root}
      title="Approval - Prism"
    >
      <Container maxWidth={false}>
        <Typography variant="h1" component="h2" gutterBottom color="textPrimary">
          <Box
            fontFamily="Roboto"
            display="flex"
            justifyContent="flex-start"
          >
            Approval
          </Box>
        </Typography>
        <Paper>
          <Box>
            <CardHeader
              title="Service Approval"
              subheader="List of service that require your approval."
            />
            <Divider />

            <Box>
              <ApprovalTicketTable
                user={user}
                approvalTicket={approvalTicket}
                currentPage={currentPage}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Box>

          </Box>
        </Paper>
        <br />
        <Paper>
          <Box>
            <CardHeader
              title="Equipment Approval"
              subheader="List of equipment request that require your approval."
            />
            <Divider />

            <Box>
              <ApprovalEquipmentRequestTable
                user={user}
                equipmentRequest={approvalEquipment}
                currentPage={currentPage}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Box>

          </Box>
        </Paper>
        <br />
        <Paper>
          <Box>
            <CardHeader
              title="Responded Service"
              subheader="Monitor your approved service here."
            />
            <Divider />

            <Box>
              <ApprovedTicketTable
                user={user}
                approvalTicket={approvalTicket}
                currentPage={currentPage}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Box>

          </Box>
        </Paper>
      </Container>
    </Page>
  );
}

export default Approval;
