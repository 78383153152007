import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import {
  createMFAMethod,
  updateMFAMethod
} from 'src/actions/admin';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AuthenticationMethodForm = ({
  refresh,
  setRefresh,
  openPopup,
  setOpenPopup,
  data
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.result);
  const [dataIsEmpty, setDataIsEmpty] = useState(true);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [initialValue, setTempInitialValue] = useState({
    auth_name: '',
    auth_status: '',
    auth_description: '',
    employee_number: user?.EMPLOYEE_NUMBER
  });

  useEffect(() => {
    function isObjectNullOrUndefinedOrEmpty(obj) {
      return obj == null || Object.keys(obj).length === 0;
    }
    setDataIsEmpty(isObjectNullOrUndefinedOrEmpty(data));

    if (!isObjectNullOrUndefinedOrEmpty(data)) {
      setTempInitialValue({
        auth_id: data?.id,
        auth_name: data?.auth_name,
        auth_status: data?.auth_status,
        auth_description: data?.auth_description,
        employee_number: user?.EMPLOYEE_NUMBER,
      });
    }
    return () => {
      setDataIsEmpty(true);
    };
  }, []);

  return (
    ((dataIsEmpty === true) ? (
      <>
        {/* Add new method view */}
        <Page
          className={classes.root}
          title="New Authentication Method"
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={
                  Yup.object().shape({
                    auth_name: Yup.string().max(255).ensure().required('Please method name'),
                    auth_status: Yup.string().max(255).ensure().required('Please select status'),
                    auth_description: Yup.string().max(255).ensure().required('Please provide description'),
                  })
                }
                onSubmit={
                  (values, { setSubmitting }) => {
                    dispatch(createMFAMethod(values))
                      .then((res) => {
                        if (res?.success === true) {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setRefresh(!refresh);
                          setOpenPopup(!openPopup);
                        } else {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch();
                  }
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      Add New Authentication Method
                    </Typography>

                    <TextField
                      error={Boolean(touched.auth_name && errors.auth_name)}
                      fullWidth
                      helperText={touched.auth_name && errors.auth_name}
                      label="Name"
                      margin="normal"
                      name="auth_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.auth_name}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.auth_description && errors.auth_description)}
                      fullWidth
                      helperText={touched.auth_description && errors.auth_description}
                      label="Description"
                      margin="normal"
                      name="auth_description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.auth_description}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.auth_status && errors.auth_status)}
                      fullWidth
                      helperText={touched.auth_status && errors.auth_status}
                      label="Status"
                      margin="normal"
                      name="auth_status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.auth_status}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="true">
                        <em>Active</em>
                      </MenuItem>
                      <MenuItem value="false">
                        <em>Inactive</em>
                      </MenuItem>
                    </TextField>

                    <br />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        ADD
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      </>
    ) : (
      <>
        {/* Update method details view */}
        <Page
          className={classes.root}
          title="Edit Authentication Method"
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={
                  Yup.object().shape({
                    auth_name: Yup.string().max(255).ensure().required('Please method name'),
                    auth_status: Yup.string().max(255).ensure().required('Please select status'),
                    auth_description: Yup.string().max(255).ensure().required('Please provide description'),
                  })
                }
                onSubmit={
                  (values, { setSubmitting }) => {
                    setSubmitting(true);
                    dispatch(updateMFAMethod(values))
                      .then((res) => {
                        if (res?.success === true) {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setRefresh(!refresh);
                          setOpenPopup(!openPopup);
                        } else {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch();
                  }
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                    >
                      Edit Authentication Method
                    </Typography>

                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      ID
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.id}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Name
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.auth_name}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Verification Status
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.verification_status}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Create Datetime
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.create_datetime}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Create By
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.create_by}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Update Datetime
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.update_datetime || '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Update By
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 10 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data?.update_by || '-'}
                    </Typography>
                    <br />

                    <TextField
                      error={Boolean(touched.auth_description && errors.auth_description)}
                      fullWidth
                      helperText={touched.auth_description && errors.auth_description}
                      label="Description"
                      margin="normal"
                      name="auth_description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.auth_description}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.auth_status && errors.auth_status)}
                      fullWidth
                      helperText={touched.auth_status && errors.auth_status}
                      label="Status"
                      margin="normal"
                      name="auth_status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.auth_status}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="true">
                        <em>Active</em>
                      </MenuItem>
                      <MenuItem value="false">
                        <em>Inactive</em>
                      </MenuItem>
                    </TextField>

                    <br />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        UPDATE
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      </>
    ))
  );
};

AuthenticationMethodForm.propTypes = {
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  data: PropTypes.object
};

export default AuthenticationMethodForm;
