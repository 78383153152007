import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Container,
  Box,
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  fetchAllMFAMethod
} from 'src/actions/admin';
import Popup from '../../../components/Popup';
import ListOfAuthenticationMethod from './ListOfAuthenticationMethod';
import AuthenticationMethodForm from './AuthenticationMethodForm';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const AuthenticationMethod = ({
  divisionidofuser,
  employeeNumber,
  divisions,
  employeeDivision,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const retrieve = async () => {
    await dispatch(fetchAllMFAMethod())
      .then((res) => {
        if (res?.success === true) {
          setData({
            ...data,
            method: res?.data
          });
        }
      });
  };

  useEffect(() => {
    retrieve();
  }, [refresh]);

  return (
    <>
      <Paper>
        <Alert severity="warning">
          <AlertTitle>Important Reminder</AlertTitle>
          This section manages existing authentication methods and creates new authentication
          method IDs for the system environment.The process and flow for new methods should be
          developed within the system source code.
        </Alert>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Add Method" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Add Type"
                onClick={handleOpen}
              >
                ADD NEW METHOD
              </Button>
            </Tooltip>
          </Box>
          <ListOfAuthenticationMethod
            data={data}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            divisions={divisions}
            accountType={accountType}
            divisionidofuser={divisionidofuser}
            employeeDivision={employeeDivision}
          />
          <Popup
            openPopup={open}
            setOpenPopup={setOpen}
            title=""
          >
            <AuthenticationMethodForm
              openPopup={open}
              setOpenPopup={setOpen}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Popup>
          &nbsp;
        </Container>
      </Paper>
    </>
  );
};

AuthenticationMethod.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
  divisions: PropTypes.string,
  employeeDivision: PropTypes.string,
};

export default AuthenticationMethod;
