/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React,
{ useRef, useState, useEffect }
  from 'react';
import {
  Box,
  Button,
  IconButton,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import AboutPrism from './AboutPrism';
import PageContent from './PageContent';
import TopBar from '../../../layouts/MainLayout/TopBar';

const UseStyles = makeStyles(() => ({
  container: {
    maxWidth: '100%',
    width: '100%',
  },
  bgPicture: {
    width: '100%',
    height: '30%',
    objectFit: 'cover'
  },
  contentBody: {
    width: '100%',
  },
  prismFont: {
    display: 'inline-block',
    objectFit: 'cover'
  }
}));

export default function index() {
  const classes = UseStyles();
  const ref = useRef(null);
  const [transparent, setTransparent] = useState(true);

  // Function to scroll to login button
  const handleLoginClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Configure on which scrollY to on/off topbar
  const toggleTopbar = () => {
    if (window.scrollY >= 10) {
      setTransparent(false);
    } else {
      setTransparent(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleTopbar);
    return () => {
      // Unmount component when open new page or click button
      window.removeEventListener('scroll', toggleTopbar);
    };
  }, []);

  return (
    <>
      <Page
        title="Prism - Welcome"
      >
        <Box
          className={classes.container}
        >
          <Box
            className={classes.bgPicture}
            component="img"
            alt="Prime Mover loading container."
            src="/static/images/loading_pm.jpg"
            zIndex="modal"
            display="block"
            position="relative"
            top={0}
          />
          <AboutPrism />
          <div
            ref={ref}
          >
            <PageContent />
          </div>
        </Box>
        <Box
          className={classes.navbar}
          zIndex="tooltip"
          position="relative"
        >
          <TopBar transparent={transparent}>
            <Box
              position="absolute"
              display="inline"
              zIndex="tooltip"
              top={10}
              right={25}
            >
              <Button
                variant="contained"
                size="large"
                onClick={handleLoginClick}
              >
                Login
              </Button>
              <IconButton
                onClick={handleLoginClick}
              >
                <img
                  width={45}
                  alt="ChatbotIcon"
                  src="/static/images/ChatWithZeek.png"
                />
              </IconButton>
            </Box>
          </TopBar>
        </Box>
      </Page>
    </>
  );
}
