import React from 'react';
import {
  Box,
  makeStyles,
  // Backdrop,
  LinearProgress
} from '@material-ui/core';
// import { useSelector } from 'react-redux';

const useStyles = makeStyles((
  theme
) => ({
  root: {
    width: '100%',
    height: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  position: {
    height: '100%',
    width: '100%',
    // margin: '50%',
    // borderColor: '#40826d',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function LoadingLinear() {
  // const dispatch = useDispatch();
  // const loading = useSelector((state) => state?.service.loading);
  const classes = useStyles();

  // if (!loading) return null;

  return (
    <div className={classes.root}>
      <Box
        className={classes.position}
      // border={10}
      >
        <LinearProgress color="secondary" />
      </Box>
      {/* <Backdrop className={classes.backdrop} open>
        <LinearProgress color="secondary" />
      </Backdrop> */}

    </div>
  );
}

export default LoadingLinear;
