import * as api from '../api/index';

export const fetchAccountType = (employeeNumber) => async (/* dispatch */) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchAccountType(employeeNumber)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    // dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchOtherDivisionUser = (employeeNumber, operation) => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchOtherDivisionUser(employeeNumber, operation)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchOtherDivisionUserVendor = (employeeNumber) => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchOtherDivisionUserVendor(employeeNumber)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const listVendorAccount = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchVendorAccount()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const fetchUserInfo = (employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchUserInfo(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchUserDivisionID = (employeeNumber) => async (/* dispatch */) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchUserDivisionID(employeeNumber)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    // dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePassword = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.updateUserPass(formData)
      .then((res) => {
        if (res?.data) {
          if (res?.data.success === false) {
            dispatch({ type: 'HIDE_LOADING_SPINNER' });
            // return res?.data.message;
          } else if (res?.data.success === true) {
            dispatch({ type: 'AUTH', payload: res?.data });
            dispatch({ type: 'HIDE_LOADING_SPINNER' });
            setTimeout(() => {
              history('/login');
            }, 2000);
            return 'Success';
          } return res?.data.message;
        }
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.resetPass(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const resetPasswordbysuperadmin = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.resetPassbySA(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const fetchFaceIdData = (employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchFaceIdData(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchFaceIdToken = (employeeNumber) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchFaceIdToken(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const createFaceIdData = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.createFaceIdData(data)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const tokenLogin = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.tokenLogin(data)
      .then((res) => {
        if (res?.data) {
          if (res?.data.success === false) {
            dispatch({ type: 'HIDE_LOADING_SPINNER' });
            return res?.data.message;
          }
          if (res?.data.success === true) {
            dispatch({ type: 'AUTH', payload: res?.data });
            dispatch({ type: 'HIDE_LOADING_SPINNER' });
            return res?.data.success;
          }
        }
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const getOneMFARecord = (id) => async () => {
  try {
    const { data } = await api.fetchOneMFARecord(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const createMFARecord = (data) => async () => {
  try {
    const response = await api.fetchOneMFARecord(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMFARecord = (data) => async () => {
  try {
    const response = await api.updateMFARecord(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const checkAccountMFA = (id) => async () => {
  try {
    const { data } = await api.checkAccountMFA(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const checkMethod = (id) => async () => {
  try {
    const { data } = await api.checkMethod(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const totpGenerate = (data) => async () => {
  try {
    const response = await api.totpGenerate(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};
