import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Paper,
  Dialog,
  IconButton,
  MenuItem
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createReason, createSolved } from '../../../actions/admin';
import ReasonDetails from './reasonsDetails';
import SolvedDetails from './solvedviaDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ReasonForm = ({
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  /* const [clickdiv, setClickDiv] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  }; */

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                reason_id: '',
                reason_description: '',
                division_id: divisionidofuser,
                create_by: employeeNumber,
                division: ''
              }}
              validationSchema={
                Yup.object().shape({
                  reason_description: Yup.string().max(600).required('Description is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createReason(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      reason_id: '',
                      reason_description: '',
                      division_id: divisionidofuser,
                      create_by: employeeNumber,
                      division: ''
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW FORWARDED REASON
                  </Typography>
                  <TextField
                    error={Boolean(touched.reason_description && errors.reason_description)}
                    fullWidth
                    helperText={touched.reason_description && errors.reason_description}
                    label="Reason Description"
                    margin="normal"
                    name="reason_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason_description}
                    variant="outlined"
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                reason_id: '',
                reason_description: '',
                division_id: ''/* clickdiv */,
                create_by: employeeNumber,
                division: ''/* clickdivname */
              }}
              validationSchema={
                Yup.object().shape({
                  reason_description: Yup.string().max(600).required('Description is required'),
                  division_id: Yup.string().max(255).required('Division in charge is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createReason(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      reason_id: '',
                      reason_description: '',
                      division_id: '',
                      create_by: employeeNumber,
                      division: ''
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW FORWARDED REASON
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          // onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.reason_description && errors.reason_description)}
                    fullWidth
                    helperText={touched.reason_description && errors.reason_description}
                    label="Reason Description"
                    margin="normal"
                    name="reason_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason_description}
                    variant="outlined"
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

ReasonForm.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ReasonForm;

export const SolvedViaForm = ({
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  /* const [clickdiv, setClickDiv] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  }; */

  return (
    <Page
      className={classes.root}
      title="Others"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                solved_id: '',
                solved_description: '',
                division_id: divisionidofuser,
                create_by: employeeNumber,
                division: null
              }}
              validationSchema={
                Yup.object().shape({
                  solved_description: Yup.string().max(255).required('Medium is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createSolved(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      solved_id: '',
                      solved_description: '',
                      division_id: divisionidofuser,
                      create_by: employeeNumber,
                      division: ''
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW SOLUTION PROPOSED
                  </Typography>
                  <TextField
                    error={Boolean(touched.solved_description && errors.solved_description)}
                    fullWidth
                    helperText={touched.solved_description && errors.solved_description}
                    label="Solved Via"
                    margin="normal"
                    name="solved_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.solved_description}
                    variant="outlined"
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="success">
                        Successful create solution proposed
                      </Alert>
                    </Snackbar> */}
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                solved_id: '',
                solved_description: '',
                division_id: ''/* clickdiv */,
                create_by: employeeNumber,
                division: ''/* clickdivname */
              }}
              validationSchema={
                Yup.object().shape({
                  solved_description: Yup.string().max(255).required('Medium is required'),
                  division_id: Yup.string().max(255).required('Division in charge is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createSolved(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      solved_id: '',
                      solved_description: '',
                      division_id: '',
                      create_by: employeeNumber,
                      division: ''
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW SOLUTION PROPOSED
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          // onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.solved_description && errors.solved_description)}
                    fullWidth
                    helperText={touched.solved_description && errors.solved_description}
                    label="Solved Via"
                    margin="normal"
                    name="solved_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.solved_description}
                    variant="outlined"
                  />
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="success">
                        Successful create solution proposed
                      </Alert>
                    </Snackbar> */}
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

SolvedViaForm.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export function ListReason({
  reasons,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  accountType,
  divisions
}) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let reasonlist = [];
  let SAreasonlist = [];

  if (reasons) {
    reasonlist = reasons.map((reason) => (
      {
        reason_id: reason.REASON_ID || null,
        reason_description: reason.REASON_DESCRIPTION || '',
        division_id: reason.DIVISION_ID || '',
        division: reason.DIVISION || '',
      }
    )).filter((row) => row.division_id === divisionidofuser) || [];
  }
  if (reasons) {
    SAreasonlist = reasons.map((reason) => (
      {
        reason_id: reason.REASON_ID || null,
        reason_description: reason.REASON_DESCRIPTION || '',
        division_id: reason.DIVISION_ID || '',
        division: reason.DIVISION || '',
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF SERVICE FORWARDED REASON
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'reason_id' },
            { title: 'Reason Description', field: 'reason_description' }
          ]}
          data={reasonlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        // SUPER ADMIN VIEW
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'reason_id' },
            { title: 'Reason Description', field: 'reason_description' },
            { title: 'Division', field: 'division' }
          ]}
          data={SAreasonlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Forwarded Reason
        </DialogTitle>
        <DialogContent dividers>
          <ReasonDetails
            reason={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListReason.propTypes = {
  reasons: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export function ListSolvedVia({
  solvedvia,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let solvedlist = [];
  let SAsolvedlist = [];

  if (solvedvia) {
    solvedlist = solvedvia.map((solve) => (
      {
        solved_id: solve.SOLVED_ID || null,
        solved_description: solve.SOLVED_DESCRIPTION || '',
        division_id: solve.DIVISION_ID || '',
        division: solve.DIVISION || '',
        update_by: solve.UPDATE_BY || ''
      }
    )).filter((row) => row.division_id === divisionidofuser) || [];
  }
  if (solvedvia) {
    SAsolvedlist = solvedvia.map((solve) => (
      {
        solved_id: solve.SOLVED_ID || null,
        solved_description: solve.SOLVED_DESCRIPTION || '',
        division_id: solve.DIVISION_ID || '',
        division: solve.DIVISION || '',
        update_by: solve.UPDATE_BY || ''
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF SERVICE SOLUTION PROPOSED
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'Solved Via', field: 'solved_description' }
          ]}
          data={solvedlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'Solved Via', field: 'solved_description' },
            { title: 'Division', field: 'division' || 'division_id' }
          ]}
          data={SAsolvedlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Solution Proposed
        </DialogTitle>
        <DialogContent dividers>
          <SolvedDetails
            solve={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListSolvedVia.propTypes = {
  solvedvia: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};
