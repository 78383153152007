import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createCategory as NewCategory } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CategoryForm = ({
  departments,
  divisionid,
  employeeNumber,
  setOpen,
  refresh,
  setRefresh,
  accountType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clickdept, setClickDept] = useState('');
  const [clickdeptname, setClickDeptname] = useState('');
  const [clickdivisionid, setClickDivId] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const handleDeptClick = (dept) => {
    setClickDept(dept?.DEPARTMENT_ID);
    setClickDeptname(dept?.DEPARTMENT);
    setClickDivId(dept?.DIVISION_ID);
    setClickDivname(dept?.DIVISION);
  };
  const options = departments.map((option) => {
    const division = option.DIVISION;
    return {
      division: division || '',
      ...option,
    };
  }).filter((option) => option.DIVISION_STATUS === 'ACTIVE');

  return (
    <Page
      className={classes.root}
      title="Category"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType === 'Division Admin' ? (
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                category_name: '',
                department_id: clickdept,
                department_name: clickdeptname,
                division_name: clickdivname,
                service_type: '',
                external_flag: '',
                location_flag: '',
                category_status: '',
                create_by: employeeNumber,
                division_id: divisionid
              }}
              validationSchema={
                Yup.object().shape({
                  category_name: Yup.string().max(255).required('Category is required'),
                  service_type: Yup.string().ensure().required('Service type is required'),
                  department_id: Yup.string().ensure().required('Department in charge required'),
                  location_flag: Yup.string().ensure().required('Field is required'),
                  external_flag: Yup.string().ensure().required('Field is required'),
                  category_status: Yup.string().ensure().required('Field is required')
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(NewCategory(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpen(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.department_id && errors.department_id)}
                    fullWidth
                    helperText={touched.department_id && errors.department_id}
                    label="Select Department In Charge"
                    margin="normal"
                    name="department_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.department_id}
                    variant="outlined"
                  >
                    <MenuItem value="" default>None</MenuItem>
                    {departments ? (
                      departments.map((dept) => {
                        if (
                          dept.DIVISION_ID
                          === divisionid
                        ) {
                          return (
                            <MenuItem
                              value={dept.DEPARTMENT_ID}
                              onClick={() => handleDeptClick(dept)}
                              key={dept.DEPARTMENT}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {/* {dept.DEPARTMENT_ID} */}
                              {/* &nbsp; */}
                              {dept.DEPARTMENT}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.service_type && errors.service_type)}
                    fullWidth
                    helperText={touched.service_type && errors.service_type}
                    label="Select Service Type"
                    margin="normal"
                    name="service_type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.service_type}
                    variant="outlined"
                  >
                    <MenuItem value="ticket" default>Ticket</MenuItem>
                    <MenuItem value="request" default>Request</MenuItem>
                    <MenuItem value="ticket request" default>Both</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.category_name && errors.category_name)}
                    fullWidth
                    helperText={touched.category_name && errors.category_name}
                    label="New Category"
                    margin="normal"
                    name="category_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.location_flag && errors.location_flag)}
                    fullWidth
                    helperText={touched.location_flag && errors.location_flag}
                    label="Allow requestor to insert location when choose this category?"
                    margin="normal"
                    name="location_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.location_flag}
                    variant="outlined"
                  >
                    <MenuItem value="1" default>Yes</MenuItem>
                    <MenuItem value="0" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.external_flag && errors.external_flag)}
                    fullWidth
                    helperText={touched.external_flag && errors.external_flag}
                    label="This category can be viewed by external personnel?"
                    margin="normal"
                    name="external_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.external_flag}
                    variant="outlined"
                  >
                    <MenuItem value="Y" default>Yes</MenuItem>
                    <MenuItem value="N" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.category_status && errors.category_status)}
                    fullWidth
                    helperText={touched.category_status && errors.category_status}
                    label="Enable category?"
                    margin="normal"
                    name="category_status"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.category_status}
                    variant="outlined"
                  >
                    <MenuItem value="ENABLE" default>Yes</MenuItem>
                    <MenuItem value="DISABLE" default>No</MenuItem>
                  </TextField>
                  &nbsp;
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Add
                    </Button>
                    &nbsp;
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="reset"
                      variant="outlined"
                      onClick={resetForm}
                      fullWidth
                    >
                      Reset
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                category_name: '',
                department_id: clickdept,
                department_name: clickdeptname,
                division_name: clickdivname,
                service_type: '',
                external_flag: '',
                location_flag: '',
                category_status: '',
                create_by: employeeNumber,
                division_id: clickdivisionid
              }}
              validationSchema={
                Yup.object().shape({
                  category_name: Yup.string().max(255).required('Category is required'),
                  service_type: Yup.string().ensure().required('Service type is required'),
                  department_id: Yup.string().ensure().required('Department in charge required'),
                  location_flag: Yup.string().ensure().required('Field is required'),
                  external_flag: Yup.string().ensure().required('Field is required'),
                  category_status: Yup.string().ensure().required('Field is required')
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(NewCategory(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setOpen(false);
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Autocomplete
                    onChange={(event, dept) => {
                      handleDeptClick(dept);
                    }}
                    options={
                      options.sort((a, b) => b.DIVISION?.localeCompare(a?.DIVISION))
                    }
                    groupBy={(option) => option.DIVISION_ID}
                    getOptionLabel={(option) => option.DEPARTMENT}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.department_id && errors.department_id)}
                        helperText={touched.department_id && errors.department_id}
                        label="Select Department In Charge"
                        variant="outlined"
                        id="department_id"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="department_id"
                        value={values.department_id}
                        fullWidth
                      />
                    )}
                  />
                  {/* <TextField
                    error={Boolean(touched.department_id && errors.department_id)}
                    fullWidth
                    helperText={touched.department_id && errors.department_id}
                    label="Select Department In Charge"
                    margin="normal"
                    name="department_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.department_id}
                    variant="outlined"
                  >
                    <MenuItem value="" default>None</MenuItem>
                    {departments ? (
                      departments.map((dept) => (
                        <MenuItem
                          value={dept.DEPARTMENT_ID}
                          onClick={() => handleDeptClick(dept)}
                          key={dept.DEPARTMENT}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {dept.DEPARTMENT}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField> */}
                  <TextField
                    error={Boolean(touched.service_type && errors.service_type)}
                    fullWidth
                    helperText={touched.service_type && errors.service_type}
                    label="Select Service Type"
                    margin="normal"
                    name="service_type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.service_type}
                    variant="outlined"
                  >
                    <MenuItem value="ticket" default>Ticket</MenuItem>
                    <MenuItem value="request" default>Request</MenuItem>
                    <MenuItem value="ticket request" default>Both</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.category_name && errors.category_name)}
                    fullWidth
                    helperText={touched.category_name && errors.category_name}
                    label="New Category"
                    margin="normal"
                    name="category_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.location_flag && errors.location_flag)}
                    fullWidth
                    helperText={touched.location_flag && errors.location_flag}
                    label="Allow requestor to insert location when choose this category?"
                    margin="normal"
                    name="location_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.location_flag}
                    variant="outlined"
                  >
                    <MenuItem value="1" default>Yes</MenuItem>
                    <MenuItem value="0" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.external_flag && errors.external_flag)}
                    fullWidth
                    helperText={touched.external_flag && errors.external_flag}
                    label="This category can be viewed by external personnel?"
                    margin="normal"
                    name="external_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.external_flag}
                    variant="outlined"
                  >
                    <MenuItem value="Y" default>Yes</MenuItem>
                    <MenuItem value="N" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.category_status && errors.category_status)}
                    fullWidth
                    helperText={touched.category_status && errors.category_status}
                    label="Enable category?"
                    margin="normal"
                    name="category_status"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.category_status}
                    variant="outlined"
                  >
                    <MenuItem value="ENABLE" default>Yes</MenuItem>
                    <MenuItem value="DISABLE" default>No</MenuItem>
                  </TextField>
                  &nbsp;
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Add
                    </Button>
                    &nbsp;
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="reset"
                      variant="outlined"
                      onClick={resetForm}
                      fullWidth
                    >
                      Reset
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

CategoryForm.propTypes = {
  departments: PropTypes.array,
  divisionid: PropTypes.string,
  employeeNumber: PropTypes.string,
  setOpen: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
};

export default CategoryForm;
