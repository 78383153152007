import React from 'react';
import {
  Checkbox,
  // Box
} from '@material-ui/core';
import PropTypes from 'prop-types';

function ConsentAgreementAcknowledgement({
  data,
  setData
}) {
  const handleChange = (event) => {
    setData(event.target.checked);
  };

  return (
    <>
      <Checkbox
        checked={data}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />

      I hereby agree to all the stated terms and condition above.

    </>
  );
}

ConsentAgreementAcknowledgement.propTypes = {
  data: PropTypes.any,
  setData: PropTypes.any,
};

export default ConsentAgreementAcknowledgement;
