import * as api from '../api/index';

export const fetchDashboardData = (employeeNumber, skipLoadingLoop) => async (dispatch) => {
  try {
    if (skipLoadingLoop !== true) {
      dispatch({ type: 'SHOW_LOADING_SPINNER' });
    }
    const response = await api.fetchDashboardData(employeeNumber)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchServiceMonitorPermission = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchServiceMonitorPermission(data)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchServiceMonitorData = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchServiceMonitorData(data)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchListOfServiceWithOneStatus = ({
  employeeNumber,
  startDate,
  endDate,
  status
}) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchListOfServiceWithOneStatus({
      employeeNumber,
      startDate,
      endDate,
      status
    })
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export default fetchDashboardData();
