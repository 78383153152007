// const authReducer = (state = { authData: null }, action) => {
//   switch (action.type) {
//     case 'AUTH':
//       console.log('action: ', action);
//       localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
//       return { ...state, authData: action?.payload };
//     case 'LOGOUT':
//       localStorage.clear();
//       return { ...state, authData: null };
//     default:
//       return state;
//   }
// };

// export default authReducer;

const initialState = {
  authStatus: null,
  authData: null,
  userId: null,
  userData: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH':
      localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
      return {
        ...state,
        authData: action?.payload
      };
    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        authData: null
      };
    case 'SET_AUTHSTATUS':
      return {
        ...state,
        authStatus: action?.payload
      };
    case 'SET_USERID':
      return {
        ...state,
        userId: action?.payload
      };
    case 'SET_USERDATA':
      return {
        ...state,
        userData: action?.payload
      };
    default:
      return state;
  }
};

export default authReducer;
