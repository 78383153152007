import React, { useState, forwardRef } from 'react';
import {
  Paper,
  Button
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
  SaveAlt
} from '@material-ui/icons';
import MaterialTable, { MTableBodyRow } from 'material-table';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';

import Popup from '../../../components/Popup';
import TicketDetails from '../../../components/TicketDetails';

function ServiceTable({
  service,
  user,
  refresh,
  setRefresh
}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openTicketDetails, setOpenTicketDetails] = useState(false);
  let temp = [];
  let exportData = [];
  let tempExportData = [];

  const downloadExcel = () => {
    if (exportData?.length === 0) {
      exportData = tempExportData.map((row) => {
        row['Type '] = row.serviceType;
        row['Current Status'] = row.status;
        row['Service Number'] = row.serviceRefno;
        row['Severity '] = row.severity;
        row['Service Summary'] = row.title;
        row['Service Category'] = row.category;
        row['Location '] = row.locationName;
        row['Location Remarks'] = row.locationRemarks;
        row['PIC '] = row.pic;
        row['Last Update'] = row.updateDate;
        row['Creator Name'] = row.creator;
        row['Create Date'] = row.createDate;
        row['Ext/Telephone No'] = row.telephoneNo;
        row['External Refno'] = row.external_refno;
        delete row.serviceRefno;
        delete row.serviceType;
        delete row.createDate;
        delete row.creator;
        delete row.telephoneNo;
        delete row.title;
        delete row.category;
        delete row.locationName;
        delete row.locationRemarks;
        delete row.pic;
        delete row.updateDate;
        delete row.severity;
        delete row.status;
        delete row.tableData;
        delete row.id;
        delete row.external_refno;
        return row;
      });
    }
    // const headerTitle = 'Service Report';
    const workSheet = XLSX.utils.json_to_sheet(exportData);
    const workBook = XLSX.utils.book_new();
    // XLSX.utils.sheet_add_json(workSheet, newData, { origin: 'A3' });
    XLSX.utils.book_append_sheet(workBook, workSheet, 'reports');
    // Buffer
    // const buf = XLSX.write(workBook, { reportType: 'xlsx', type: 'buffer' });
    // console.log(buf);
    // Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    // Download
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const separator = '-';
    const currentDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    XLSX.writeFile(workBook, `${currentDate} - Service Report.xlsx`);
  };

  const formatDate = (tempDate) => {
    if (tempDate !== null) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      const datetime = tempDate;
      const t = datetime.substring(0, 19).split(/[- .T:]/);

      // Apply each element to the Date function
      const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
      return d.toString() || '';
    }
    return '';
  };

  if (service?.length !== 0) {
    const services = service.map((row) => {
      let tempCreateDate = service?.CREATE_DATE;
      let tempUpdateDate = service?.UPDATE_DATE;

      if (service?.CREATE_DATE !== null) {
        tempCreateDate = formatDate(row?.CREATE_DATE);
      }

      if (service?.UPDATE_DATE !== null) {
        tempUpdateDate = formatDate(row?.UPDATE_DATE);
      }

      return {
        tempCreateDate,
        tempUpdateDate,
        ...row,
      };
    });

    temp = services.map((ticket) => (

      {
        id: ticket.id || null,
        serviceRefno: ticket.SERVICE_REFNO || 'null',
        title: ticket.SERVICE_TITLE || 'null',
        status: (
          ticket?.SERVICE_STATUS_NAME === null
            ? ticket?.SERVICE_STATUS
            : ticket?.SERVICE_STATUS_NAME
        ),
        category: (
          ticket?.CATEGORY_NAME === null
            ? ticket?.CATEGORY
            : ticket?.CATEGORY_NAME
        ),
        updateDate: ticket?.UPDATE_DATE || '-',
        createDate: ticket.CREATE_DATE || ticket.tempCreateDate || '',
        pic: ticket.PIC || "Haven't been assigned yet!",
        severity: (
          ticket?.SEVERITY_IMPACT === null
            ? ticket?.SEVERITY_ID
            : ticket?.SEVERITY_IMPACT
        ),
        creator: ticket.EMPLOYEE_NAME,
        serviceType: ticket?.SERVICE_TYPE?.toUpperCase() || 'null',
        external_refno: ticket?.EXTERNAL_REFNO || '-',
        telephoneNo: ticket.PHONE_EXT_NO || '',
        locationName: ticket.LOCATION_NAME || '-',
        locationRemarks: ticket.LOCATION_REMARKS || '-',
      }

    )) || [];
    tempExportData = services.map((ticket) => (

      {
        id: ticket.id || null,
        serviceRefno: ticket.SERVICE_REFNO || 'null',
        title: ticket.SERVICE_TITLE || 'null',
        status: (
          ticket?.SERVICE_STATUS_NAME === null
            ? ticket?.SERVICE_STATUS
            : ticket?.SERVICE_STATUS_NAME
        ),
        category: (
          ticket?.CATEGORY_NAME === null
            ? ticket?.CATEGORY
            : ticket?.CATEGORY_NAME
        ),
        updateDate: ticket?.UPDATE_DATE || '-',
        pic: ticket.PIC || "Haven't been assigned yet!",
        severity: (
          ticket?.SEVERITY_IMPACT === null
            ? ticket?.SEVERITY_ID
            : ticket?.SEVERITY_IMPACT
        ),
        creator: ticket.EMPLOYEE_NAME,
        serviceType: ticket?.SERVICE_TYPE?.toUpperCase() || 'null',
        external_refno: ticket?.EXTERNAL_REFNO || '-',
        createDate: ticket.CREATE_DATE || ticket.tempCreateDate || '',
        telephoneNo: ticket.PHONE_EXT_NO || '',
        locationName: ticket.LOCATION_NAME || '-',
        locationRemarks: ticket.LOCATION_REMARKS || '-',
      }

    ))

      || [];
  }

  const handleOnClick = () => {
    setOpenTicketDetails(!openTicketDetails);
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
  };

  return (
    <>
      <MaterialTable
        title="Service Report"
        icons={tableIcons}
        columns={[
          { title: 'Type', field: 'serviceType' },
          { title: 'Status', field: 'status' },
          { title: 'Service Number', field: 'serviceRefno' },
          { title: 'Severity', field: 'severity' },
          { title: 'Service Summary', field: 'title' },
          { title: 'Service Category', field: 'category' },
          {
            title: 'Location',
            field: 'locationName',
            hidden: true,
            export: true
          },
          {
            title: 'Location Remark',
            field: 'locationRemark',
            hidden: true,
            export: true
          },
          {
            title: 'PIC',
            field: 'pic',
            hidden: true,
            export: true
          },
          { title: 'Last Update', field: 'updateDate' },
          { title: 'Creator', field: 'creator' },
          {
            title: 'Create Date',
            field: 'createDate',
            hidden: true,
            export: true
          },
          {
            title: 'Creator Tel No.',
            field: 'telephoneNo',
            hidden: true,
            export: true
          },
          { title: 'External Refno', field: 'external_refno' }
        ]}
        data={temp}
        onRowClick={((evt, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
        localization={{
          toolbar: {
            exportCSVName: 'Export as CSV',
            exportPDFName: 'Export as PDF'
          }
        }}
        options={{
          toolbar: true,
          paging: true,
          search: true,
          grouping: true,
          exportButton: {
            csv: true,
            pdf: true
          },
          exportAllData: true
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
              />
            );
          }
        }}
        actions={[
          {
            icon: () => <Button type="button" variant="outlined">Export as Excel</Button>,
            tooltip: 'Export to Excel',
            onClick: () => downloadExcel(),
            isFreeAction: true
          }
        ]}
      />
      <Popup
        openPopup={openTicketDetails}
        setOpenPopup={setOpenTicketDetails}
        title="Details"
      >
        <TicketDetails
          ticket={selectedRow}
          user={user}
          openPopup={openTicketDetails}
          setOpenPopup={setOpenTicketDetails}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </Popup>
    </>
  );
}

ServiceTable.propTypes = {
  service: PropTypes.object,
  user: PropTypes.object,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
};

export default ServiceTable;
