/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import {
  fetchSeverity,
  forwardChangeOwnerTicket,
  fetchAllDivision
} from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import { maxSelectFile, checkFileSize } from '../../../components/fileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function ForwardChangeOwnerTicketForm({
  user,
  serviceRefno,
  setOpenPopup,
  currentModule,
  currentSeverity,
  refresh,
  setRefresh,
  accountType
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);
  const [listOfDivision, setListOfDivision] = useState([]);
  const [listOfSeverity, setListOfSeverity] = useState([]);
  const [tempAttachment, setAttachment] = useState([]);
  const [tempServerity, setTempSeverity] = useState('');
  const [tempServerityImpact, setTempSeverityImpact] = useState('');
  const [tempModule, setTempModule] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const [tempActiveDivision, setTempActiveDivision] = useState('');
  const [tempActiveDivisionName, setTempActiveDivisionName] = useState('');

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const excludeInactiveDivision = (divisions) => {
    divisions.map((temp, i) => {
      if (temp.DIVISION_STATUS !== 'ACTIVE') {
        divisions.splice(i, 1);
      }
    });

    setListOfDivision(divisions || []);
  };

  const handleClickDivision = (activeDivision) => {
    setTempActiveDivision(activeDivision?.DIVISION_ID || null);
    setTempActiveDivisionName(activeDivision?.DIVISION || null);
  };

  const handleClickSeverity = (severity) => {
    setTempSeverity(severity?.SEVERITY_ID || '');
    setTempSeverityImpact(severity?.SEVERITY_IMPACT || '');
  };

  const loadData = () => {
    dispatch(fetchAllDivision(user?.DIVISION_ID))
      .then((res) => {
        if (res?.success === true) {
          excludeInactiveDivision(res?.activeDivision || []);
        }
      });

    dispatch(fetchSeverity(userEmployeeNumber))
      .then((res2) => {
        if (res2?.success === true) {
          setListOfSeverity(res2.severity);
        }
      });
  };

  useEffect(() => {
    loadData();
    setTempModule(currentModule);
    setTempSeverity(currentSeverity);
  }, []);

  return (
    <Box
      bgcolor="common.white"
      color="text.primary"
      m={2}
      borderRadius={12}
      p={2}
    >
      <Formik
        enableReinitialize
        initialValues={{
          service_refno: serviceRefno,
          employeeNumber: userEmployeeNumber,
          assignToNumber: '',
          assignToName: '',
          severity: tempServerity,
          severity_impact: tempServerityImpact,
          module: tempModule,
          attachment: tempAttachment,
          reason_description: 'Change Ownership',
          description: tempDescription,
          service_status: 'NEW',
          status_name: 'New',
          active_division: tempActiveDivision,
          active_division_name: tempActiveDivisionName
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500).min(5).required('Please provide description.')
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          console.log('values:', values);
          const data = new FormData();
          for (const value in values) {
            data.append(value, values[value]);
          }

          for (let x = 0; x < tempAttachment?.length; x++) {
            data.append('attachment', tempAttachment[x]);
          }

          dispatch(forwardChangeOwnerTicket(data))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setRefresh(!refresh);
                setOpenPopup(false);
              } else {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.severity
                && errors.severity)}
              fullWidth
              helperText={touched.severity
                && errors.severity}
              label="Severity"
              id="severity"
              margin="normal"
              name="severity"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.severity}
              variant="outlined"
              disabled={accountType !== 'Vendor User' ? (false) : (true)}
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfSeverity !== 0 ? (
                listOfSeverity.map((severity, index) => (
                  <MenuItem
                    value={severity.SEVERITY_ID}
                    onClick={() => handleClickSeverity(listOfSeverity[index])}
                  >
                    {severity?.SEVERITY_ID === currentSeverity && ('(Current) ')}
                    {severity?.SEVERITY_ID === currentSeverity
                      && (setTempSeverityImpact(severity?.IMPACT))}
                    {severity.IMPACT}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={null} disabled default>No severity data load</MenuItem>
              )}
            </TextField>

            <TextField
              error={Boolean(touched.active_division
                && errors.active_division)}
              fullWidth
              helperText={touched.active_division
                && errors.active_division}
              label="Forward to"
              id="active_division"
              margin="normal"
              name="active_division"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.active_division}
              variant="outlined"
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfDivision !== 0 ? (
                listOfDivision.map((activeDivision, index) => (
                  <MenuItem
                    value={activeDivision.DIVISION_ID}
                    onClick={() => handleClickDivision(listOfDivision[index])}
                  >
                    {activeDivision.DIVISION}
                  </MenuItem>
                ))

              ) : (
                <MenuItem value="" disabled default>No division data load</MenuItem>
              )}
            </TextField>

            <br />
            <br />

            <Button
              error={Boolean(touched.attachment && errors.attachment)}
              fullWidth
              helperText={touched.attachment && errors.attachment}
              label="Attachment"
              variant="outlined"
              focused
              component="label"
              onBlur={handleBlur}
              margin="normal"
            >
              Attachment :
              {' '}
              <input
                id="attachment"
                name="attachment"
                onChange={(event) => {
                  if (maxSelectFile(event) && checkFileSize(event)) {
                    setAttachment(event.target.files || null);
                    setFieldValue('attachment', event.currentTarget.files[0] || null);
                  }
                }}
                onClick={(e) => {
                  e.target.value = '';
                }}
                type="file"
                multiple
              />
            </Button>

            <br />
            <br />

            <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                name="description"
                aria-label="maximum height"
                placeholder="Forward Description"
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                fullwidth="true"
                helperText={touched.description && errors.description}
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                onChangeCapture={(e) => {
                  setTempDescription(e.target.value);
                }}
                value={values.description}
              />
              {(errors.description && touched.description) && (
                <Alert severity="error">
                  {errors.description || ''}
                </Alert>
              )}
              <br />
            </Box>

            <Button
              id="submit"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

ForwardChangeOwnerTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.bool,
  currentModule: PropTypes.string,
  currentSeverity: PropTypes.string,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
  accountType: PropTypes.string
};

export default ForwardChangeOwnerTicketForm;
