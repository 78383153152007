import React from 'react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const { src } = props;
  return (
    <img
      alt="Logo"
      src={src || '/static/ptp_logo.png'}
      {...props}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default Logo;
