/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  Divider
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Page from 'src/components/Page';
import TicketAmount from './TicketAmount';
import Sales from './Sales';
import TrafficByDevice from './TrafficByDevice';
import { fetchAccountType } from '../../../actions/user';
import { fetchDashboardData } from '../../../actions/report';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import NoAccessView from '../../errors/NoAccessView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

// Need division based on employee number
const Dashboard = () => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))?.result || []);
  const [accountType, setAccountType] = useState(JSON.parse(localStorage.getItem('profile'))?.accountType || '');
  const [activeTicketAmount, setActiveTicketAmount] = useState([]);
  const [divisionTicketAmount, setDivisionTicketAmount] = useState([]);
  const [newTicketAmount, setNewTicketAmount] = useState([]);
  const [closedTicketAmount, setClosedTicketAmount] = useState([]);
  const [listOfPeople, setListOfPeople] = useState([]);
  const [topModule, setTopModule] = useState([]);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [fullDate] = useState(new Date());
  const [refreshDataInterval] = useState(50000);

  const getData = (skipLoadingLoop) => {
    dispatch(fetchAccountType(user?.EMPLOYEE_NUMBER || null))
      .then((res) => {
        if (res?.success === true) {
          setAccountType(res.accountType);
          setUser(res.userInfo);
        } else if (res?.success === false) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          if (res?.relogin === true) {
            history('/login');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(fetchDashboardData(user?.EMPLOYEE_NUMBER, skipLoadingLoop))
      .then((res) => {
        if (res?.success === false) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }

          if (res?.relogin === true) {
            history('/login');
          }
        } else {
          setDivisionTicketAmount(res?.divisionTicket[0] || []);
          setActiveTicketAmount(res?.activeTicket[0] || []);
          setClosedTicketAmount(res?.closedTicket[0] || []);
          setNewTicketAmount(res?.newTicket[0] || []);
          setListOfPeople(res?.listOfPeople || []);
          setTopModule(res?.topModule || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUser(user);
    getData();
    if (refreshDataInterval > 0) {
      const refresh = setInterval(() => getData(true), refreshDataInterval);
      return () => clearInterval(refresh);
    }
  }, [refreshDataInterval]);

  return (
    <Page
      className={classes.root}
      title="Dashboard - Prism"
    >
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        color="textPrimary"
      >
        <Box
          fontFamily="Roboto"
          display="flex"
          justifyContent="flex-end"
          marginRight={3}
        >
          <Box>
            Quick Links
          </Box>
        </Box>
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginRight={3}
      >
        <Button
          className={classes.addTicketButton}
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_CLIENT_URL}/#/app/service/open_add_form/true`;
          }}
        >
          Add New Service
        </Button>
      </Box>
      <br />
      <br />
      <Divider />
      {
        (accountType !== 'User' && accountType !== 'Vendor User') ? (
          <>
            <br />
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              color="primary"
            >
              <Box
                fontFamily="Roboto"
                display="flex"
                justifyContent="center"
              >
                <Box>
                  Dashboard
                </Box>

              </Box>
            </Typography>
            <Container maxWidth={false}>
              <Typography component="div">
                <Box>
                  {`${user?.DIVISION_NAME} `}
                  {`${fullDate?.getFullYear()} `}
                  Summary
                </Box>
              </Typography>
              <br />
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TicketAmount
                    user={user || []}
                    data={divisionTicketAmount || []}
                    title="Total Ticket"
                    iconColor=""
                    icon="="
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TicketAmount
                    user={user || []}
                    data={activeTicketAmount || []}
                    title="Active Ticket"
                    iconColor="yellow"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TicketAmount
                    user={user || []}
                    data={closedTicketAmount || []}
                    title="Closed Ticket"
                    iconColor="green"
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xl={3}
                  xs={12}
                >
                  <TicketAmount
                    user={user || []}
                    data={newTicketAmount || []}
                    title="New Ticket"
                    iconColor="blue"
                  />
                </Grid>
              </Grid>
              <Typography component="div">
                <Box mt={3} mb={3}>
                  {`${user?.DEPARTMENT_NAME} `}
                  {`${fullDate.toLocaleString('default', { month: 'long' })} `}
                  Summary
                </Box>
              </Typography>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  lg={8}
                  md={12}
                  xl={9}
                  xs={12}
                >
                  <Sales listOfPeople={listOfPeople || []} />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={6}
                  xl={3}
                  xs={12}
                >
                  <TrafficByDevice topModule={topModule || []} />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={6}
                  xl={3}
                  xs={12}
                />
                <Grid
                  item
                  lg={8}
                  md={12}
                  xl={9}
                  xs={12}
                />
              </Grid>
            </Container>

          </>
        ) : (
          <>
            <NoAccessView />
          </>
        )
      }
    </Page>
  );
};

export default Dashboard;
