import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { updatePassword } from '../../../actions/user';

const Password = ({ employeeNumber }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const history = useNavigate();

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container>
        <Formik
          initialValues={{
            employee_number: employeeNumber,
            oldpassword: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={
            Yup.object().shape({
              oldpassword: Yup.string().max(255).required('Current password is required'),
              password: Yup.string().max(255).required('password is required')
                .matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                ),
              confirmPassword: Yup
                .string()
                .required('Please confirm your password')
                .when('password', {
                  is: (password) => (!!(password && password.length > 0)),
                  then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
                })
            })
          }
          onSubmit={
            (values, { setSubmitting }) => {
              dispatch(updatePassword(values, history))
                .then((res) => {
                  console.log(res);
                  if (res?.success === false) {
                    handleError(res?.message);
                  } else if (res?.success === true) {
                    setSubmitting(false);
                  }
                })
                .catch((err) => {
                  handleError(err?.message);
                });
              setSubmitting(false);
            }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>

              <Card>
                <CardHeader
                  subheader="Update password"
                  title="Password"
                />
                <Divider />
                <CardContent>
                  <TextField
                    error={Boolean(touched.oldpassword && errors.oldpassword)}
                    fullWidth
                    helperText={touched.oldpassword && errors.oldpassword}
                    label="Current Password"
                    margin="normal"
                    name="oldpassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.oldpassword}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="New Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label="Confirm New Password"
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                  />
                </CardContent>
                <Divider />
                <Box>
                  {error && (
                    <>
                      <Alert severity="error">{message}</Alert>
                    </>
                  )}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Container>

    </Box>
  );
};

Password.propTypes = {
  employeeNumber: PropTypes.string
};

export default Password;
