/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import { listDepartment, listUsers, listLocation } from 'src/actions/admin';
import {
  listExternalUser,
  listExternalUserType,
  fetchModule,
  fetchSeverity,
  createTask,
  fetchPeoples
} from 'src/actions/service';
import { fetchOtherDivisionUser } from 'src/actions/user';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { maxSelectFile, checkFileSize } from '../../../components/fileUpload';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function CreateTaskForm({
  user,
  setOpenPopup,
  refresh,
  setRefresh,
  employeeNumber
}) {
  const classes = useStyles();
  const history = useNavigate();
  const [includeSecondaryPerson, setIncludeSecondaryPerson] = useState(false);
  const [sNumber, setSnumber] = useState('');
  const [sName, setSname] = useState('');
  const [sEmail, setSemail] = useState('');
  const dispatch = useDispatch();

  const [listOfExternalUser, setListOfExternalUser] = useState([]);
  const [listOfExternalUserType, setListOfExternalUserType] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const [departments, setDepartment] = useState([]);
  const [modules, setListOfModule] = useState([]);
  const [listOfSeverity, setListOfSeverity] = useState([]);
  const [listOfPeople, setListOfPeople] = useState([]);
  const operation = 'ASG';

  const [tempAttachment, setAttachment] = useState([]);
  const [tempLocation, setLocation] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const [tempCategory, setCategory] = useState('');
  const [tempLocationId, setTempLocationId] = useState('');
  const [tempLocationName, setTempLocationName] = useState('');
  const [clickmodule, setClickModule] = useState('');
  const [tempServerity, setTempSeverity] = useState('');
  const [tempServerityImpact, setTempSeverityImpact] = useState('');
  const [tempEmpNum, setTempEmpNum] = useState('000000');
  const [tempEmpName, setTempEmpName] = useState('');
  const [openInternalUserList, setOpenInternalUserList] = useState(false);
  const [openExternalUserList, setOpenExternalUserList] = useState(false);
  const [internalCustomerListName, setInternalCustomerListName] = useState(false);
  const [externalCustomerListName, setExternalCustomerListName] = useState(false);
  const [deptID, setDeptID] = useState('');
  const [deptName, setDeptName] = useState('');
  const [empID, setEmpID] = useState('');
  const [empName, setEmpName] = useState('');
  const [custID, setCustID] = useState('');
  const [custName, setCustName] = useState('');
  const [typeUser, setTypeUser] = useState('');
  const [typeExternal, setTypeExternal] = useState('');
  const [tempContactedby, setContactedby] = useState('');
  const [tempTitle, setTempTitle] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const [tempExtNo, setTempExtNo] = useState('');
  const [tempIpAddress] = useState('');
  const [tempPcName] = useState('');
  const [tempLocationRemarks, setTempLocationRemarks] = useState('');
  const [tempStartJob, setTempStartJob] = useState('');
  const [tempExternalRefName, setExternalRefName] = useState('');
  const [tempInitialValue, setTempInitialValue] = useState({
    contactedby: '',
    usertype: typeUser,
    title: '',
    category: tempCategory,
    description: '',
    attachment: tempAttachment,
    email: user?.EMAIL_ADDRESS,
    name: user?.EMPLOYEE_NAME,
    employee_number: user?.EMPLOYEE_NUMBER,
    ext_no: '',
    ip_address: '',
    pc_name: '',
    secondary_name: sName,
    secondary_email: sEmail,
    secondary_employee_number: sNumber,
    type: 'ticket',
    location_id: tempLocationId,
    location_name: tempLocationName,
    location_remarks: '',
    userfrom: deptID || typeExternal,
    userfrom_name: deptName || typeExternal,
    custempid: empID || custID,
    custempname: empName || custName,
    severity: tempServerity,
    severity_impact: tempServerityImpact,
    assignToNumber: tempEmpNum,
    assignToName: tempEmpName,
    module: clickmodule,
    startjob: '',
    external_refname: ''
  });

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const retrievePeoples = (empNo, skipLoadingLoop) => {
    dispatch(fetchPeoples(empNo, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (Array.isArray(res?.peoples)) {
            setPeoples(res?.peoples || []);
          }
        } else {
          if (res?.relogin === true) {
            history('/login');
          }
        }
      })
      .catch((err) => {
        console.log('ERROR RETRIEVE LIST OF PEOPLES IN DIVISION : ', err);
      });
  };

  const locations = tempLocation.map((location) => {
    let locationName = `${location?.LEVEL}, ${location?.BUILDINGS}`;

    if (location?.DIVISION !== '') {
      locationName += `, ${location?.DIVISION}`;
    }

    return {
      location_name: locationName,
      ...location,
    };
  });

  const modulesoptions = modules.map((option) => {
    const division = option.DIVISION_NAME;
    return {
      division: division || '',
      ...option,
    };
  }).filter((option) => option.CATEGORY_STATUS === 'ENABLE');

  const excludeInactivePeople = (people) => {
    people.map((temp, i) => {
      if (temp.LOGIN_STATUS !== 'active') {
        people.splice(i, 1);
      }
    });

    setListOfPeople(people || []);
  };

  const options = departments.map((option) => {
    const division = option.DIVISION;
    return {
      division: division || '',
      ...option,
    };
  });

  const retrieveTaskElement = () => {
    dispatch(listExternalUser())
      .then((res) => {
        setListOfExternalUser(res);
      });
    dispatch(listExternalUserType())
      .then((res) => {
        setListOfExternalUserType(res);
      });
    dispatch(listUsers())
      .then((res) => {
        setAllUsers(res);
      });
    dispatch(listDepartment())
      .then((res) => {
        setDepartment(res);
      });
    dispatch(fetchModule(employeeNumber))
      .then((res3) => {
        if (res3?.success === true) {
          setListOfModule(res3?.ticketModules || []);
        }
      });
    dispatch(fetchSeverity(employeeNumber))
      .then((res2) => {
        if (res2?.success === true) {
          setListOfSeverity(res2.severity || []);
        }
      });
    dispatch(fetchOtherDivisionUser(employeeNumber, operation))
      .then((res) => {
        if (res?.success === true) {
          excludeInactivePeople(res?.listOfPeople || []);
        }
      });
  };

  const handleLocation = (val) => {
    setTempLocationId(val?.LOCATION_ID);
    setTempLocationName(val?.location_name);
  };

  const handleOpenLocation = (value) => {
    if (value?.LOCATION_FLAG === '1') {
      setOpenLocation(true);
    } else {
      setOpenLocation(false);
    }
  };

  const handleCheckBoxOnChange = () => {
    setIncludeSecondaryPerson(!includeSecondaryPerson);
    if (includeSecondaryPerson === false) {
      setSnumber('');
      setSname('');
      setSemail('');
    }
  };

  const handleSecondaryPerson = (person) => {
    setSname(person?.EMPLOYEE_NAME || '');
    setSnumber(person?.EMPLOYEE_NUMBER || '');
    setSemail(person?.EMAIL_ADDRESS || '');
  };

  const handleClickModule = (value, event) => {
    event.preventDefault();
    handleOpenLocation(value);
    setClickModule(value?.MODULE_ID);
    setCategory(value?.CATEGORY_ID);
  };

  const handleClickSeverity = (severity) => {
    setTempSeverity(severity?.SEVERITY_ID || null);
    setTempSeverityImpact(severity?.IMPACT || null);
  };

  const handleClickEmployee = (employee) => {
    setTempEmpNum(employee?.EMPLOYEE_NUMBER || null);
    setTempEmpName(employee?.EMPLOYEE_NAME || null);
  };

  const handleInternalUser = () => {
    setOpenInternalUserList(true);
    setOpenExternalUserList(false);
    setExternalCustomerListName(false);
    setTypeUser('INTERNAL');
    setTypeExternal(null);
    setCustID(null);
    setCustName(null);
  };

  const handleExternalUser = () => {
    setOpenInternalUserList(false);
    setInternalCustomerListName(false);
    setOpenExternalUserList(true);
    setTypeUser('EXTERNAL');
    setDeptID(null);
    setDeptName(null);
    setEmpID(null);
    setEmpName(null);
  };

  const handleClickInternalUser = (dept) => {
    console.log('test: ', dept?.DEPARTMENT_ID);
    setInternalCustomerListName(true);
    setTypeUser('INTERNAL');
    setDeptID(dept?.DEPARTMENT_ID);
    setDeptName(dept?.DEPARTMENT);
  };

  const handleClickExternalUser = (exttype) => {
    setExternalCustomerListName(true);
    setTypeUser('EXTERNAL');
    setTypeExternal(exttype.EXTERNAL_TYPE);
  };

  const handleClickUser = (users) => {
    setEmpID(users.EMPLOYEE_NUMBER);
    setEmpName(users.EMPLOYEE_NAME);
  };

  const handleClickUserEx = (externalusers) => {
    setCustID(externalusers.EXTERNAL_ID);
    setCustName(externalusers.EXTERNAL_NAME);
  };

  useEffect(() => {
    retrieveTaskElement();
    dispatch(listLocation())
      .then((res) => {
        setLocation(res || []);
      });
    retrievePeoples(user?.EMPLOYEE_NUMBER, false);
  }, []);

  // This use effect will update form value when change
  // this is to solve dissapearing value when choose attachments
  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      contactedby: tempContactedby,
      usertype: typeUser,
      title: tempTitle,
      category: tempCategory,
      description: tempDescription,
      attachment: tempAttachment,
      ext_no: tempExtNo,
      ip_address: tempIpAddress,
      pc_name: tempPcName,
      secondary_name: sName,
      secondary_email: sEmail,
      secondary_employee_number: sNumber,
      location_id: tempLocationId,
      location_name: tempLocationName,
      location_remarks: tempLocationRemarks,
      userfrom: deptID || typeExternal,
      userfrom_name: deptName || typeExternal,
      custempid: empID || custID,
      custempname: empName || custName,
      severity: tempServerity,
      severity_impact: tempServerityImpact,
      assignToNumber: tempEmpNum,
      assignToName: tempEmpName,
      module: clickmodule,
      startjob: tempStartJob,
      external_refname: tempExternalRefName
    });
  }, [
    tempAttachment,
    tempContactedby,
    tempCategory,
    tempLocationId,
    tempLocationName,
    tempTitle,
    tempDescription,
    tempIpAddress,
    tempExtNo,
    tempPcName,
    tempLocationRemarks,
    sName,
    sEmail,
    sNumber,
    deptID,
    deptName,
    typeExternal,
    empID,
    empName,
    custID,
    custName,
    tempExternalRefName
  ]);

  return (
    <Page
      // className={classes.root}
      title="Create Task"
    >
      <Box>
        <Formik
          enableReinitialize
          initialValues={tempInitialValue}
          validationSchema={
            Yup.object().shape({
              title: Yup.string().max(255).required('Title is required'),
              description: Yup.string().max(500).min(2).required('Please provide description.'),
              category: Yup.string().ensure().required('Please choose a category'),
              secondary_email: Yup.string().max(255).email('Email must be a valid email'),
              location_remarks: Yup.string().max(255, 'Location remarks must less than 255 characters'),
              ext_no: Yup.string().max(50).required('Please provide a contactable number.'),
              severity: Yup.number().required('Please select severity.'),
              module: Yup.string().required('Please select module.'),
              assignToName: Yup.string().required('Please select people to be assigned to.'),
              contactedby: Yup.string().required('Please select user contacted from which medium.'),
              userfrom: Yup.string().required('Please select internal/external user.').nullable(),
              usertype: Yup.string().required('Please select type of user.').nullable(),
              custempid: Yup.string().required('Please select employee/customer.').nullable(),
              startjob: Yup.string().required('Please choose to start job or not.'),
            })
          }
          onSubmit={
            (values, { setSubmitting }) => {
              console.log('values', values);
              const data = new FormData();
              // data.append('attachment', tempAttachment);
              for (const value in values) {
                data.append(value, values[value]);
              }

              for (let x = 0; x < tempAttachment?.length; x++) {
                data.append('attachment', tempAttachment[x]);
              }
              dispatch(createTask(data))
                .then((res) => {
                  if (!res?.data) {
                    if (res?.success !== true) {
                      setSubmitting(false);
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.notifier,
                        },

                      });
                    } else {
                      setOpenPopup(false);
                      setRefresh(!refresh);
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.notifier,
                        },
                      });
                    }
                  } else {
                    if (res?.data.success !== true) {
                      setSubmitting(false);
                      enqueueSnackbar({
                        message: res?.data.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.data.notifier,
                        },
                      });
                    } else {
                      setOpenPopup(false);
                      setRefresh(!refresh);
                      enqueueSnackbar({
                        message: res?.data.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                          ),
                          success: res?.data.notifier,
                        },
                      });
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                });
            }
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              keepDirtyOnReinitialize
            >
              <LoadingSpinner />
              <Checkbox
                onClick={() => handleCheckBoxOnChange()}
              />
              <Typography style={{ display: 'inline' }}>Include Secondary Person</Typography>
              <Paper>
                {includeSecondaryPerson && (
                  <>
                    <Box mb={3} boxShadow={1}>
                      <Container>
                        <TextField
                          error={Boolean(touched.secondary_employee_number
                            && errors.secondary_employee_number)}
                          fullWidth
                          helperText={touched.secondary_employee_number
                            && errors.secondary_employee_number}
                          label="Name"
                          id="secondary_employee_number"
                          margin="normal"
                          name="secondary_employee_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.secondary_employee_number}
                          variant="outlined"
                        >
                          <MenuItem
                            value=""
                            default
                            onClick={() => {
                              setSnumber('');
                              setSname('');
                              setSemail('');
                            }}
                          >
                            None
                          </MenuItem>
                          {peoples ? (
                            peoples.slice(0, peoples.length).map((people) => (
                              <MenuItem
                                value={people.EMPLOYEE_NUMBER}
                                onClick={() => handleSecondaryPerson(people)}
                              >
                                {people.EMPLOYEE_NAME}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="" disabled default>No people data load</MenuItem>
                          )}
                        </TextField>
                        <TextField
                          fullWidth
                          label="Employee Number"
                          margin="normal"
                          onChange={handleChange}
                          variant="outlined"
                          disabled
                          value={values.secondary_employee_number}
                        >
                          {values.secondary_employee_number}
                        </TextField>
                        <TextField
                          error={Boolean(touched.secondary_email && errors.secondary_email)}
                          fullWidth
                          helperText={touched.secondary_email && errors.secondary_email}
                          label="Email"
                          margin="normal"
                          name="secondary_email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onChangeCapture={(e) => {
                            setSemail(e.target.value);
                          }}
                          type="email"
                          value={values.secondary_email}
                          variant="outlined"
                        />
                      </Container>
                    </Box>
                  </>
                )}
              </Paper>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography>User Details</Typography>
              </Box>
              <Paper>
                <Box mb={2}>
                  <Container>
                    <TextField
                      error={Boolean(touched.usertype && errors.usertype)}
                      fullWidth
                      helperText={touched.usertype && errors.usertype}
                      id="usertype"
                      label="User Type"
                      margin="normal"
                      name="usertype"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setTypeUser(e.target.value);
                      }}
                      select
                      value={values.usertype}
                      variant="outlined"
                    >
                      <MenuItem value="INTERNAL" onClick={handleInternalUser}>INTERNAL</MenuItem>
                      <MenuItem value="EXTERNAL" onClick={handleExternalUser}>EXTERNAL</MenuItem>
                    </TextField>
                    {openInternalUserList ? (
                      <>
                        <Autocomplete
                          onChange={(event, dept) => {
                            handleClickInternalUser(dept);
                          }}
                          options={
                            options.sort((a, b) => b.DIVISION?.localeCompare(a?.DIVISION))
                          }
                          groupBy={(option) => option.DIVISION}
                          getOptionLabel={(option) => option.DEPARTMENT}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.userfrom && errors.userfrom)}
                              helperText={touched.userfrom && errors.userfrom}
                              label="Department"
                              variant="outlined"
                              id="userfrom"
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="userfrom"
                              value={values.userfrom}
                              fullWidth
                            />
                          )}
                        />
                      </>
                    ) : null}
                    {internalCustomerListName ? (
                      <>
                        <TextField
                          error={Boolean(touched.custempid && errors.custempid)}
                          fullWidth
                          helperText={touched.custempid && errors.custempid}
                          id="custempid"
                          label="Employee Name"
                          margin="normal"
                          name="custempid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.custempid}
                          variant="outlined"
                        >
                          {allusers ? (
                            allusers.map((users) => {
                              if (
                                users.DEPARTMENT_ID
                                === deptID
                              ) {
                                return (
                                  <MenuItem
                                    value={users.EMPLOYEE_NUMBER}
                                    onClick={() => handleClickUser(users)}
                                    key={users.EMPLOYEE_NUMBER}
                                    style={{ whiteSpace: 'unset' }}
                                  >
                                    {users.EMPLOYEE_NUMBER}
                                    {' - '}
                                    {users.EMPLOYEE_NAME}
                                  </MenuItem>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                      </>
                    ) : null}
                    {openExternalUserList ? (
                      <>
                        <TextField
                          error={Boolean(touched.userfrom && errors.userfrom)}
                          fullWidth
                          helperText={touched.userfrom && errors.userfrom}
                          id="userfrom"
                          label="External User"
                          margin="normal"
                          name="userfrom"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.userfrom}
                          variant="outlined"
                        >
                          {listOfExternalUserType ? (
                            listOfExternalUserType.map((exttype) => (
                              <MenuItem
                                value={exttype.EXTERNAL_TYPE}
                                onClick={() => handleClickExternalUser(exttype)}
                                key={exttype.EXTERNAL_TYPE}
                                style={{ whiteSpace: 'unset' }}
                              >
                                {exttype.EXTERNAL_TYPE}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                      </>
                    ) : null}
                    {externalCustomerListName ? (
                      <>
                        <TextField
                          error={Boolean(touched.custempid && errors.custempid)}
                          fullWidth
                          helperText={touched.custempid && errors.custempid}
                          id="custempid"
                          label="Customer Name"
                          margin="normal"
                          name="custempid"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.custempid}
                          variant="outlined"
                        >
                          <MenuItem value="" default>None</MenuItem>
                          {listOfExternalUser ? (
                            listOfExternalUser.map((externalusers) => {
                              if (
                                externalusers.EXTERNAL_TYPE
                                === typeExternal
                              ) {
                                return (
                                  <MenuItem
                                    value={externalusers.EXTERNAL_ID}
                                    onClick={() => handleClickUserEx(externalusers)}
                                    key={externalusers.EXTERNAL_ID}
                                    style={{ whiteSpace: 'unset' }}
                                  >
                                    {externalusers.EXTERNAL_ID}
                                    {' - '}
                                    {externalusers.EXTERNAL_NAME}
                                  </MenuItem>
                                );
                              }
                              return null;
                            })
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                      </>
                    ) : null}
                    <TextField
                      error={Boolean(touched.contactedby && errors.contactedby)}
                      fullWidth
                      helperText={touched.contactedby && errors.contactedby}
                      id="contactedby"
                      label="Contacted by"
                      margin="normal"
                      name="contactedby"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onClick={(e) => {
                        setContactedby(e.target.value);
                      }}
                      select
                      value={values.contactedby}
                      variant="outlined"
                    >
                      <MenuItem
                        value="EMAIL"
                      >
                        EMAIL
                      </MenuItem>
                      <MenuItem
                        value="TELEPHONE"
                      >
                        TELEPHONE
                      </MenuItem>
                      <MenuItem
                        value="TEXT"
                      >
                        TEXT
                      </MenuItem>
                    </TextField>
                  </Container>
                </Box>
              </Paper>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography>Assign Task</Typography>
              </Box>
              <Paper>
                <Box mb={2}>
                  <Container>
                    <TextField
                      error={Boolean(touched.assignToName
                        && errors.assignToName)}
                      fullWidth
                      helperText={touched.assignToName
                        && errors.assignToName}
                      label="Assign to"
                      id="assignToName"
                      margin="normal"
                      name="assignToName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.assignToName}
                      variant="outlined"
                    >
                      <MenuItem value="" default>None</MenuItem>
                      {listOfPeople ? (
                        listOfPeople.map((people, index) => (
                          <MenuItem
                            value={people.EMPLOYEE_NAME}
                            onClick={() => handleClickEmployee(listOfPeople[index])}
                          >
                            {people.EMPLOYEE_NAME}
                          </MenuItem>
                        ))

                      ) : (
                        <MenuItem value="" disabled default>No people data load</MenuItem>
                      )}
                    </TextField>
                    <TextField
                      value={values.assignToNumber}
                      name="assignToNumber"
                      disabled
                      variant="outlined"
                      fullWidth
                      label="Employee Number Preview"
                      margin="normal"
                    />
                    <br />
                    <br />
                  </Container>
                </Box>
              </Paper>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography>Task Details</Typography>
              </Box>
              <Paper>
                <Box mb={2}>
                  <Container>
                    <Autocomplete
                      onChange={(event, value) => {
                        handleClickModule(value, event);
                      }}
                      options={
                        modulesoptions.sort(
                          (a, b) => b.DIVISION_NAME?.localeCompare(a?.DIVISION_NAME)
                        )
                      }
                      getOptionLabel={(option) => option.MODULE_DESCRIPTION}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.module && errors.module)}
                          fullWidth
                          helperText={touched.module && errors.module}
                          label="Module"
                          margin="normal"
                          name="module"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.module}
                          variant="outlined"
                        />
                      )}
                    />
                    {openLocation === true && (
                      <>
                        <TextField
                          error={Boolean(touched.location && errors.location)}
                          fullWidth
                          helperText={touched.location && errors.location}
                          id="location"
                          label="Location"
                          margin="normal"
                          name="location"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.location_id}
                          variant="outlined"

                        >
                          {locations ? (
                            locations.map((location) => (
                              <MenuItem
                                value={location?.LOCATION_ID}
                                onClick={() => handleLocation(location)}
                              >
                                {location?.location_name || ''}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                        <TextField
                          error={Boolean(touched.location_remarks && errors.location_remarks)}
                          fullWidth
                          helperText={touched.location_remarks && errors.location_remarks}
                          id="location_remarks"
                          label="Location Remarks (Optional)"
                          margin="normal"
                          name="location_remarks"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onChangeCapture={(e) => {
                            setTempLocationRemarks(e.target.value);
                          }}
                          type="text"
                          value={values.location_remarks}
                          variant="outlined"
                        />
                      </>
                    )}
                    <TextField
                      error={Boolean(touched.severity
                        && errors.severity)}
                      fullWidth
                      helperText={touched.severity
                        && errors.severity}
                      label="Severity"
                      id="severity"
                      margin="normal"
                      name="severity"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.severity}
                      variant="outlined"
                    >
                      <MenuItem value="" default>None</MenuItem>
                      {listOfSeverity ? (
                        listOfSeverity.map((severity, index) => (
                          <MenuItem
                            value={severity.SEVERITY_ID}
                            onClick={() => handleClickSeverity(listOfSeverity[index])}
                          >
                            {severity.IMPACT}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={null} disabled default>No severity data load</MenuItem>
                      )}
                    </TextField>
                    <br />
                    <br />
                    <Button
                      id="attachment"
                      name="attachment"
                      error={Boolean(touched.attachment && errors.attachment)}
                      fullWidth
                      helperText={touched.attachment && errors.attachment}
                      label="Attachment"
                      variant="outlined"
                      focused="true"
                      component="label"
                      margin="normal"
                    >
                      Attachment :
                      {' '}
                      <input
                        id="attachment"
                        name="attachment"
                        onChange={(event) => {
                          if (maxSelectFile(event) && checkFileSize(event)) {
                            setAttachment(event.target.files || null);
                            setFieldValue('attachment', event.currentTarget.files[0] || null);
                          }
                        }}
                        type="file"
                        multiple
                      />
                    </Button>
                    <br />
                    <br />
                    <TextField
                      error={Boolean(touched.ext_no && errors.ext_no)}
                      fullWidth
                      helperText={touched.ext_no && errors.ext_no}
                      id="ext_no"
                      label="Contact Number (Ext No / Personal No)"
                      margin="normal"
                      name="ext_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setTempExtNo(e.target.value);
                      }}
                      type="text"
                      value={values.ext_no}
                      variant="outlined"

                    />
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      id="title"
                      label="Title"
                      margin="normal"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setTempTitle(e.target.value);
                      }}
                      type="text"
                      value={values.title}
                      variant="outlined"

                    />
                    <br />
                    <br />
                    <TextareaAutosize
                      rowsMax={10}
                      rowsMin={10}
                      name="description"
                      aria-label="maximum height"
                      placeholder="Service Description"
                      className={classes.textArea}
                      error={Boolean(touched.description && errors.description)}
                      fullwidth="true"
                      helperText={touched.description && errors.description}
                      label="Description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setTempDescription(e.target.value);
                      }}
                      value={values.description}
                    />
                    <br />
                    <TextField
                      // error={Boolean(touched.external_refname && errors.external_refname)}
                      fullWidth
                      // helperText={touched.external_refname && errors.external_refname}
                      label="External Reference Name (Leave blank if unrelated)"
                      id="external_refname"
                      margin="normal"
                      name="external_refname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onChangeCapture={(e) => {
                        setExternalRefName(e.target.value);
                      }}
                      type="text"
                      value={values.external_refname}
                      variant="outlined"
                    />
                    <br />
                    <TextField
                      // error={Boolean(touched.external_refno && errors.external_refno)}
                      fullWidth
                      // helperText={touched.external_refno && errors.external_refno}
                      label="External Reference Number (Leave blank if unrelated)"
                      id="external_refno"
                      margin="normal"
                      name="external_refno"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.external_refno}
                      variant="outlined"
                    />
                  </Container>
                </Box>
              </Paper>
              <Divider />
              <br />
              <Paper>
                <Box mb={2}>
                  <Container>
                    <FormControl
                      component="fieldset"
                    >
                      <FormLabel
                        component="legend"
                      >
                        Start Job Now?
                      </FormLabel>
                      <RadioGroup
                        error={Boolean(touched.startjob && errors.startjob)}
                        helperText={touched.startjob && errors.startjob}
                        id="startjob"
                        row
                        aria-label="startjob"
                        name="startjob"
                        value={values.startjob}
                        onChange={handleChange}
                        onChangeCapture={(e) => {
                          setTempStartJob(e.target.value);
                        }}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                      <FormHelperText error={Boolean(touched.startjob && errors.startjob)}>
                        {errors.startjob}
                      </FormHelperText>
                    </FormControl>
                    <br />
                    <br />
                    {(errors.description && touched.description) && (
                      <Alert severity="error">{errors.description || ''}</Alert>
                    )}
                    <Box m={1}>
                      <Button
                        id="submit"
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleChange}
                      >
                        Create Task
                      </Button>
                    </Box>
                  </Container>
                </Box>
              </Paper>
            </form>
          )}
        </Formik>

      </Box>

    </Page>
  );
}

export default CreateTaskForm;

CreateTaskForm.propTypes = {
  // categories: PropTypes.array,
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  peoples: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  employeeNumber: PropTypes.object,
};
