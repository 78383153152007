import React from 'react';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function LoadingSpinner() {
  // const dispatch = useDispatch();
  const loading = useSelector((state) => state?.service.loading);
  const classes = useStyles();

  if (!loading) return null;

  return (
    <div>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default LoadingSpinner;
