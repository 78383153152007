/* eslint-disable max-len */
import React, { useState, forwardRef } from 'react';
import {
  Search,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  ArrowDownward,
  AddBox,
  Clear
} from '@material-ui/icons';
import {
  Paper,
} from '@material-ui/core';
import MaterialTable, { MTableBodyRow } from 'material-table';
import PropTypes from 'prop-types';

import EquipmentRequestDetails from './EquipmentRequestDetails';
import Popup from '../../../components/Popup';

function EquipmentRequestTable({
  equipmentRequest,
  user,
  refresh,
  setRefresh
}) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  let temp = [];

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
  };

  if (equipmentRequest?.length !== 0) {
    temp = equipmentRequest.map((item) => (

      {
        id: item?.EQUIPMENT_REQUEST_REFNO || null,
        refno: item?.EQUIPMENT_REQUEST_REFNO || null,
        type: item?.TYPE_NAME || item?.EQUIPMENT_TYPE_ID || null,
        pic: item?.PIC_EMPLOYEE_NAME || item?.PIC || '-',
        remarks: item?.EQUIPMENT_REQUEST_REMARKS || null,
        status: item?.STATUS_DESCRIPTION || item?.EQUIPMENT_REQUEST_STATUS || null,
        createDate: item?.CREATE_DATE || null
      }
    ));
    //   .filter(
    //   // (item) => (item.status === 'Pending Unresolved') || (row.status === 'Resubmit Request') || (row.status === 'Pending Request')
    // );
  }

  const handleOnClick = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={[
          { title: 'Refno', field: 'id' },
          { title: 'Type', field: 'type' },
          { title: 'Approval Pending', field: 'pic' },
          { title: 'Status', field: 'status' },
          { title: 'Remarks', field: 'remarks' },
          { title: 'Create Date', field: 'createDate' }
        ]}
        data={temp}
        onRowClick={((evt, selectedRowData) => {
          equipmentRequest.forEach((row) => {
            if (row?.EQUIPMENT_REQUEST_REFNO === selectedRowData?.id) {
              setSelectedRow(row);
            }
          });
          handleOnClick();
        })}
        options={{
          toolbar: true,
          paging: true,
          search: true,
          grouping: true,
          rowStyle: (rowData) => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Row: (props) => {
            return (
              <MTableBodyRow
                {...props}
              />
            );
          }
        }}
      />
      <Popup
        openPopup={openDetails}
        setOpenPopup={setOpenDetails}
        title="Request Equipment"
      >
        <EquipmentRequestDetails
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
          setOpenPopup={setOpenDetails}
          data={selectedRow}
        />
      </Popup>
    </>
  );
}

EquipmentRequestTable.propTypes = {
  equipmentRequest: PropTypes.array,
  user: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default EquipmentRequestTable;
