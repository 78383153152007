import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Container,
  makeStyles,
  IconButton,
  Box,
  Tooltip,
  Button,
  Paper,
  Dialog,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ListVendor, { ListVendorAccount } from './vendorView';
import { listVendor, listVendorView, listExternalCompView } from '../../../actions/admin';
import { listVendorAccount } from '../../../actions/user';
import NewVendorForm from './vendorForm';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import RegisterView from '../Division/registerForm';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ManageVendor = ({
  divisionidofuser,
  employeeNumber,
  divisions,
  accountType
}) => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(null);
  const stateVendor = useSelector((state) => state?.vendor);
  const [vendor, setVendor] = useState(stateVendor);
  const stateVendorView = useSelector((state) => state?.vendorview);
  const [vendorview, setVendorView] = useState(stateVendorView);
  const [newvendor, setNewVendor] = useState(false);
  const location = useLocation();
  const [register, setRegister] = useState(false);
  const stateVendorAccount = useSelector((state) => state?.vendorAccount);
  const [vendorAccount, setVendorAccount] = useState(stateVendorAccount);
  const stateAllCompany = useSelector((state) => state?.allCompany);
  const [allCompany, setAllCompany] = useState(stateAllCompany);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const retrieve = () => {
    dispatch(listVendor())
      .then((res) => {
        promptNotification(res);
        setVendor(res);
      });
    dispatch(listVendorView(divisionidofuser))
      .then((res) => {
        promptNotification(res);
        setVendorView(res);
      });
    dispatch(listVendorAccount())
      .then((res) => {
        promptNotification(res);
        setVendorAccount(res.data);
      });
    dispatch(listExternalCompView())
      .then((res) => {
        setAllCompany(res);
      });
  };
  const handleAddVendor = () => {
    setNewVendor(true);
  };

  const handleClose = () => {
    setNewVendor(false);
    setRegister(false);
  };

  const handleClickRegister = () => {
    setRegister(true);
  };

  useEffect(() => {
    retrieve();
    if (!employeeNumber) {
      history('/login');
    }
  }, [location, refresh]);

  return (
    <Page
      className={classes.root}
      title="Vendor"
    >
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Add Vendor" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Add Vendor"
                onClick={handleAddVendor}
              >
                ADD VENDOR
              </Button>
            </Tooltip>
          </Box>
          <ListVendor
            vendor={vendor}
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            divisions={divisions}
            accountType={accountType}
          />
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={newvendor}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Vendor
            </DialogTitle>
            <DialogContent dividers>
              <NewVendorForm
                vendorview={vendorview}
                divisionidofuser={divisionidofuser}
                employeeNumber={employeeNumber}
                refresh={refresh}
                setRefresh={setRefresh}
                setNewVendor={setNewVendor}
                divisions={divisions}
                accountType={accountType}
              />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          &nbsp;
        </Container>
      </Paper>
      &nbsp;
      &nbsp;
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Vendor/Customer Registration" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Register User"
                onClick={handleClickRegister}
              >
                REGISTER VENDOR/CUSTOMER ACCOUNT
              </Button>
            </Tooltip>
          </Box>
          <ListVendorAccount
            vendorAccount={vendorAccount}
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            divisions={divisions}
            accountType={accountType}
          />
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={register}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Register Vendor/Customer Account
            </DialogTitle>
            <DialogContent dividers>
              <RegisterView
                setRegister={setRegister}
                divisions={divisions}
                refresh={refresh}
                setRefresh={setRefresh}
                allCompany={allCompany}
                employeeNumberuser={employeeNumber}
              />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          &nbsp;
        </Container>
      </Paper>
    </Page>
  );
};

ManageVendor.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ManageVendor;
