import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateDA } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const UserDetails = ({
  DA,
  refresh,
  setRefresh,
  setOpen
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="Category"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              division: '',
              employeenumber: DA.employeenumber || '',
              name: DA.name || '',
              position: DA.position || '',
              department: DA.department || '',
              account_type: DA.account_type || '',
            }}
            validationSchema={
              Yup.object().shape({
                // employeenumber: Yup.string().ensure().required('Employee Number is required')
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateDA(DA.employeenumber, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpen(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              //   errors,
              handleBlur,
              handleChange,
              handleSubmit,
              // resetForm,
              isSubmitting,
              //   touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h5" display="inline">
                  Employee Number :
                </Typography>
                &nbsp;
                <Typography display="inline" style={{ marginLeft: 10 }}>
                  {values.employeenumber}
                </Typography>
                <br />
                <Typography variant="h5" display="inline">
                  Name
                </Typography>
                <Typography variant="h5" display="inline" style={{ marginLeft: 92.5 }}>
                  :
                </Typography>
                &nbsp;
                <Typography display="inline" style={{ marginLeft: 10 }}>
                  {values.name}
                </Typography>
                <br />
                <Typography variant="h5" display="inline">
                  Position
                </Typography>
                <Typography variant="h5" display="inline" style={{ marginLeft: 77 }}>
                  :
                </Typography>
                &nbsp;
                <Typography display="inline" style={{ marginLeft: 10 }}>
                  {values.position}
                </Typography>
                <br />
                <Typography variant="h5" display="inline">
                  Department
                </Typography>
                <Typography variant="h5" display="inline" style={{ marginLeft: 52 }}>
                  :
                </Typography>
                &nbsp;
                <Typography display="inline" style={{ marginLeft: 10 }}>
                  {values.department}
                </Typography>
                <TextField
                  fullWidth
                  label="Account Type"
                  margin="normal"
                  name="account_type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.account_type}
                  variant="outlined"
                  select
                >
                  <MenuItem value="Division Admin">
                    Division Admin
                  </MenuItem>
                  <MenuItem value="Division User">
                    Division User
                  </MenuItem>
                  <MenuItem value="User">
                    User
                  </MenuItem>
                </TextField>
                &nbsp;
                <br />
                <Box
                  alignItems="center"
                  justifyContent="flex-end"
                />
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

UserDetails.propTypes = {
  DA: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpen: PropTypes.func,
};

export default UserDetails;
