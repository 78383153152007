import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Typography
  // TextareaAutosize,
  // makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { closeTicket } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark
//   },
//   textArea: {
//     width: '100%'
//   }
// }));

function CloseTicketForm({
  serviceRefno,
  user,
  // setOpenPopup,
  refresh,
  setRefresh
}) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);
  const [userEmployeeName] = useState(user?.EMPLOYEE_NAME);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Box
      bgcolor="common.white"
      color="text.primary"
      m={2}
      borderRadius={12}
      p={2}
    >
      <Formik
        enableReinitialize
        initialValues={{
          serviceRefno,
          employeeNumber: userEmployeeNumber,
          employeeName: userEmployeeName,
          description: '',
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          dispatch(closeTicket(values))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                // setOpenPopup(false);
                setRefresh(!refresh);
              } else if (res?.success === false) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch((err) => {
              console.log('Error : ', err);
              enqueueSnackbar({
                message: err?.message,
                options: {
                  key: new Date().getTime() + Math.random(),
                  action: (key) => (
                    <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                  ),
                  success: false,
                  notifier: 'warning',
                },
              });
            });
        }}
      >
        {({
          // errors,
          // handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          // touched,
          // values
        }) => (
          <form onSubmit={handleSubmit}>

            {/* <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                margin="normal"
                name="description"
                aria-label="maximum height"
                placeholder="Withdrawal Reason."
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
              />
            </Box> */}
            <Box
              mb={2}
            >
              <Typography>
                Are you sure you want to close the ticket?
                You will not be able to update this ticket any further.
              </Typography>
            </Box>

            <Button
              id="submit"
              color="primary"
              // fullWidth
              size="medium"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Close Ticket
            </Button>
          </form>
        )}
      </Formik>

    </Box>
  );
}

CloseTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  // setOpenPopup: PropTypes.bool,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
};

export default CloseTicketForm;
