/* eslint-disable new-cap */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Box,
  // Button,
  // TextField,
  // makeStyles,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import {
  fetchFaceIdToken,
} from '../../../actions/user';
import CreateFaceIdForm from './CreateFaceIdForm';
import Popup from '../../../components/Popup';

function RegisterFaceIO({
  employeeNumber
}) {
  const dispatch = useDispatch();
  const [iv, setIv] = useState('');
  const [token, setToken] = useState('');
  const [openFaceIO, setOpenFaceIO] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const data = {};
  const [prismToken] = useState(JSON.parse(localStorage.getItem('profile'))?.token);

  const getToken = async () => {
    await dispatch(fetchFaceIdToken(employeeNumber))
      .then((res) => {
        if (res.success === true) {
          setIv(res.iv);
          setToken(res.token);
        }
      })
      .then(() => {
        setOpenFaceIO(true);
      })
      .catch((err) => {
        console.log('err:', err);
      });
  };

  useEffect(() => {
    getToken();
    return () => {

    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container>
          <Card>
            <CardHeader
              subheader="Face ID registration for face login"
              title="Face ID"
            />
            <Divider />
            {((openFaceIO === true)) && (
              <>
                <CardContent>
                  <div>
                    <iframe
                      src={`https://prism.ptp.com.my/RegisterFaceIO.html?iv=${iv}&token=${token}&employeeNumber=${employeeNumber}&prismToken=${prismToken}`}
                      title="Enroll a new user on this FACEIO application"
                      width="100%"
                      height="500"
                    />
                  </div>
                </CardContent>
              </>
            )}
            <Divider />
            {(openForm === true) && (
              <Popup
                openPopup={openForm}
                setOpenPopup={setOpenForm}
                title="Register Face ID"
              >
                <CreateFaceIdForm
                  data={data}
                  openPopup={openForm}
                  setOpenPopup={setOpenForm}
                />
              </Popup>

            )}
          </Card>
        </Container>
      </Box>
    </>
  );
}

RegisterFaceIO.propTypes = {
  employeeNumber: PropTypes.string
};

export default RegisterFaceIO;
