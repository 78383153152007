import React, { useState, useEffect } from 'react';
import {
  Button,
  Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Popup from 'src/components/Popup';
import decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function FaceIDLogin() {
  const [open, setOpen] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const history = useNavigate();

  const checkLocalStorage = () => {
    if (JSON.parse(localStorage.getItem('profile'))?.token) {
      return JSON.parse(localStorage.getItem('profile'))?.token;
    }
    return false;
  };

  const checkToken = () => {
    setRefresh(!refresh);
    const token = checkLocalStorage();
    if (token !== false) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 > new Date().getTime()) {
        history('/app/dashboard');
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      checkToken();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [refresh]);

  return (
    <>
      <Box
        mt={1}
        mb={1}
      >
        <Alert
          severity="warning"
          action={(
            <Button
              color="primary"
              size="small"
              onClick={() => {
                history('/virtual-prism-assistance');
              }}
            >
              Click here for Chatbot
            </Button>
          )}
        >
          Please register Face ID before using this login method.
          Visit PRISM chatbot for Face ID usage tutorial.
          Face ID is only available on mobile devices.
        </Alert>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setOpen(!open)}
        >
          Click here to re-open face id
        </Button>
      </Box>
      <Popup
        openPopup={open}
        setOpenPopup={setOpen}
        title="Face ID Login"
      >
        <Alert
          severity="warning"
          action={(
            <Button
              color="primary"
              size="small"
              onClick={() => {
                history('/virtual-prism-assistance');
              }}
            >
              Click here for Chatbot
            </Button>
          )}
        >
          Please register Face ID before using this login method.
          Visit PRISM chatbot for Face ID usage tutorial.
        </Alert>
        <iframe
          src="https://prism.ptp.com.my/LoginFaceIO.html"
          title="Face ID Authentication"
          width="100%"
          height={500}
        />
      </Popup>
    </>
  );
}

export default FaceIDLogin;
