import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import {
  listOfEquipmentItem,
  listOfAllEquipmentItem
} from '../../../actions/equipment';
import EquipmentItemDetails from './EquipmentItemDetails';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ListOfEquipmentItem({
  employeeNumber,
  refresh,
  setRefresh,
  employeeDivision,
  accountType,
  divisions,
}) {
  const dispatch = useDispatch();
  const [itemList, setItemList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputItemList = (data) => {
    const tempList = data?.map((item) => (
      {
        item_id: item.ITEM_ID || null,
        item_name: item.ITEM_NAME || '',
        item_status: item.ITEM_STATUS || '',
        equipment_type_id: item.EQUIPMENT_TYPE_ID || '',
        equipment_type_name: item.EQUIPMENT_TYPE_NAME || '',
        effective_from_date: item.EFFECTIVE_FROM_DATE || '',
        effective_to_date: item.EFFECTIVE_TO_DATE || '',
        update_by: item.UPDATE_BY || '',
        update_date: item.UPDATE_DATE || '',
        create_by: item.CREATE_BY || '',
        create_date: item.CREATE_DATE || '',
      }
    ))
      || [];
    setItemList(tempList);
  };

  const retrieveData = () => {
    if (accountType === 'Super Admin') {
      dispatch(listOfAllEquipmentItem())
        .then((res) => {
          if (res?.success === true) {
            inputItemList(res?.equipmentItem);
          }
        });
    } else {
      dispatch(listOfEquipmentItem(employeeDivision))
        .then((res) => {
          if (res?.success === true) {
            inputItemList(res?.equipmentItem);
          }
        });
    }
  };

  useEffect(() => {
    retrieveData();
  }, [refresh]);

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        EQUIPMENT ITEM
      </Typography>
      &nbsp;
      <MaterialTable
        icons={tableIcons}
        title=""
        columns={[
          { title: 'ID', field: 'item_id' },
          { title: 'Name', field: 'item_name' },
          { title: 'Status', field: 'item_status' },
          { title: 'Type', field: 'equipment_type_name' },
          { title: 'Effective From Date', field: 'effective_from_date' },
          { title: 'Effective To Date', field: 'effective_to_date' }
        ]}
        data={itemList}
        options={{
          search: true,
          grouping: true,
          rowStyle: () => ({
            fontFamily: 'helvetica',
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        onRowClick={((event, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Equipment Item
        </DialogTitle>
        <DialogContent dividers>
          <EquipmentItemDetails
            data={selectedRow}
            employeeNumber={employeeNumber}
            divisions={divisions}
            employeeDivision={employeeDivision}
            accountType={accountType}
            openPopup={open}
            setOpenPopup={setOpen}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListOfEquipmentItem.propTypes = {
  refresh: PropTypes.string,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.string,
  employeeNumber: PropTypes.string,
  employeeDivision: PropTypes.string,
};

export default ListOfEquipmentItem;
