/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  // Card,
  // CardContent,
  // TextField,
  // InputAdornment,
  // SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Popup from '../../../components/Popup';
import CreateTaskForm from './CreateTaskForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className,
  setOpenNormalView,
  user,
  divisionID,
  employeeNumber,
  setOpenPopup,
  refresh,
  setRefresh,
  ...rest
}) => {
  const history = useNavigate();
  const accountType = user?.accountType || 'User';
  const classes = useStyles();
  const [pageName, setPageName] = useState('My Service');
  const [reportButton, setReportButton] = useState(false);
  const [createTaskButton, setCreateTaskButton] = useState(false);
  const [openCreateTask, setOpenCreateTask] = useState(false);

  const handleMyTicketClick = () => {
    setPageName('My Service');
    setOpenNormalView(true);
    setReportButton(false);
    setCreateTaskButton(false);
  };
  const handleManageTicketClick = () => {
    setPageName('Manage Service');
    setOpenNormalView(false);
    if (accountType === 'Vendor User') {
      setReportButton(false);
    } else {
      setReportButton(true);
      setCreateTaskButton(true);
    }
  };
  const handleOpenCreateTask = () => {
    setOpenCreateTask(true);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >

      <Box
        display="flex"
        justifyContent="flex-end"
        mb={3}
      >
        {accountType === 'User' ? (
          <>
            <Button
              variant="contained"
              color="inherit"
              className={classes.importButton}
              onClick={handleMyTicketClick}
            >
              My Ticket
            </Button>
          </>
        ) : accountType === 'Division User'
          || accountType === 'Division Admin'
          || accountType === 'Super Admin'
          || accountType === 'Vendor User'
          ? (
            <>
              <Button
                variant="contained"
                color="inherit"
                className={classes.importButton}
                onClick={handleMyTicketClick}
              >
                My Service
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={handleManageTicketClick}
                className={classes.exportButton}
              >
                Manage Service
              </Button>
            </>
          ) : (
            <>
            </>
          )}

      </Box>
      <Typography variant="h1" component="h2" gutterBottom color="textPrimary">
        <Box
          fontFamily="Roboto"
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Box>
            {pageName}
          </Box>
          <Box flexGrow={2} />
          <Box
            alignSelf="flex-end"
          >
            {reportButton === true && (
              <>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => {
                    history('/app/report');
                  }}
                  className={classes.exportButton}
                >
                  Service Report
                </Button>
              </>
            )}
          </Box>
          {divisionID === 'DV007' && (
            <Box
              alignSelf="flex-end"
            >
              {createTaskButton === true && (
                <>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleOpenCreateTask}
                    className={classes.exportButton}
                  >
                    Create Task
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>
      </Typography>
      <Popup
        openPopup={openCreateTask}
        setOpenPopup={setOpenCreateTask}
        title="Create Task"
      >
        <CreateTaskForm
          user={user?.result}
          openPopup={openCreateTask}
          setOpenPopup={setOpenCreateTask}
          refresh={refresh}
          setRefresh={setRefresh}
          employeeNumber={employeeNumber}
        />
      </Popup>
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  setOpenNormalView: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.func,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  divisionID: PropTypes.object,
  employeeNumber: PropTypes.object
};

export default Toolbar;
