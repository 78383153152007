import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Container,
  makeStyles,
  IconButton,
  Box,
  Tooltip,
  Button,
  Paper,
  Dialog,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ListExternalCompany from './externalCompanyView';
import { listExternalComp, listExternalCompView } from '../../../actions/admin';
import NewExternalCompany from './externalCompanyForm';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ManageExternalCompany = ({
  divisionidofuser,
  employeeNumber,
  divisions,
  accountType
}) => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(null);
  const stateExternalCompany = useSelector((state) => state?.externalCompany);
  const [externalCompany, setExternalCompany] = useState(stateExternalCompany);

  const stateExtCompanyView = useSelector((state) => state?.extCompanyView);
  const [extCompanyView, setExtCompanyView] = useState(stateExtCompanyView);

  const [newExternalCompany, setNewExternalCompany] = useState(false);
  const location = useLocation();

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const retrieve = () => {
    dispatch(listExternalComp())
      .then((res) => {
        promptNotification(res);
        setExternalCompany(res);
      });
    dispatch(listExternalCompView())
      .then((res) => {
        promptNotification(res);
        setExtCompanyView(res);
      });
  };
  const handleAddExternalCompany = () => {
    setNewExternalCompany(true);
  };

  const handleClose = () => {
    setNewExternalCompany(false);
  };

  useEffect(() => {
    retrieve();
    if (!employeeNumber) {
      history('/login');
    }
  }, [location, refresh]);

  return (
    <Page
      className={classes.root}
      title="Task"
    >
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Add External Company" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Add External Company"
                onClick={handleAddExternalCompany}
              >
                ADD EXTERNAL COMPANY
              </Button>
            </Tooltip>
          </Box>
          <ListExternalCompany
            externalCompany={externalCompany}
            divisionidofuser={divisionidofuser}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            divisions={divisions}
            accountType={accountType}
          />
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={newExternalCompany}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              External Company
            </DialogTitle>
            <DialogContent dividers>
              <NewExternalCompany
                extCompanyView={extCompanyView}
                divisionidofuser={divisionidofuser}
                employeeNumber={employeeNumber}
                refresh={refresh}
                setRefresh={setRefresh}
                setNewExternalCompany={setNewExternalCompany}
                divisions={divisions}
                accountType={accountType}
              />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          &nbsp;
        </Container>
      </Paper>
    </Page>
  );
};

ManageExternalCompany.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ManageExternalCompany;
