import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Paper,
  Dialog,
  IconButton,
  MenuItem
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createStatus } from '../../../actions/admin';
import StatusDetails from './statusDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const StatusForm = ({
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                status_id: '',
                status_description: '',
                division_id: divisionidofuser,
                division_name: '',
                default_flag: null,
                ticket: '',
                request: '',
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  status_id: Yup.string().max(5).required('ID is required').min(3, 'Must be at least 3 character'),
                  status_description: Yup.string().max(30).required('Description is required'),
                  // default_flag: Yup.string().ensure().required('Field is required'),
                  ticket: Yup.string().ensure().required('Field is required'),
                  request: Yup.string().ensure().required('Field is required')
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createStatus(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      status_id: '',
                      status_description: '',
                      division_id: divisionidofuser,
                      default_flag: '',
                      ticket: '',
                      request: '',
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW STATUS
                  </Typography>
                  <TextField
                    error={Boolean(touched.status_id && errors.status_id)}
                    fullWidth
                    helperText={(touched.status_id && errors.status_id) || 'Eg.: WIP'}
                    label="ID"
                    margin="normal"
                    name="status_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.status_id}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.status_description && errors.status_description)}
                    fullWidth
                    helperText={(touched.status_description && errors.status_description) || 'Eg.: Work In Progress'}
                    label="Status Description"
                    margin="normal"
                    name="status_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.status_description}
                    variant="outlined"
                  />
                  {/* <TextField
                    error={Boolean(touched.default_flag && errors.default_flag)}
                    fullWidth
                    helperText={touched.default_flag && errors.default_flag}
                    label="View status as default?"
                    margin="normal"
                    name="default_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.default_flag}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField> */}
                  <TextField
                    error={Boolean(touched.ticket && errors.ticket)}
                    fullWidth
                    helperText={touched.ticket && errors.ticket}
                    label="Status used for ticket?"
                    margin="normal"
                    name="ticket"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.ticket}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.request && errors.request)}
                    fullWidth
                    helperText={touched.request && errors.request}
                    label="Status used for request?"
                    margin="normal"
                    name="request"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.request}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField>
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                status_id: '',
                status_description: '',
                division_id: '',
                division_namme: '',
                default_flag: null,
                ticket: '',
                request: '',
                create_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  status_id: Yup.string().max(5).required('ID is required').min(3, 'Must be at least 3 character'),
                  status_description: Yup.string().max(30).required('Description is required'),
                  // default_flag: Yup.string().ensure().required('Field is required'),
                  ticket: Yup.string().ensure().required('Field is required'),
                  request: Yup.string().ensure().required('Field is required'),
                  division_id: Yup.string().max(150).required('Division in charge is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createStatus(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      status_id: '',
                      status_description: '',
                      division_id: '',
                      default_flag: '',
                      ticket: '',
                      request: '',
                      create_by: employeeNumber
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW STATUS
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.status_id && errors.status_id)}
                    fullWidth
                    helperText={(touched.status_id && errors.status_id) || 'Eg.: WIP'}
                    label="ID"
                    margin="normal"
                    name="status_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.status_id}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                  />
                  <TextField
                    error={Boolean(touched.status_description && errors.status_description)}
                    fullWidth
                    helperText={(touched.status_description && errors.status_description) || 'Eg.: Work In Progress'}
                    label="Status Description"
                    margin="normal"
                    name="status_description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.status_description}
                    variant="outlined"
                  />
                  {/* <TextField
                    error={Boolean(touched.default_flag && errors.default_flag)}
                    fullWidth
                    helperText={touched.default_flag && errors.default_flag}
                    label="View status as default?"
                    margin="normal"
                    name="default_flag"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.default_flag}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField> */}
                  <TextField
                    error={Boolean(touched.ticket && errors.ticket)}
                    fullWidth
                    helperText={touched.ticket && errors.ticket}
                    label="Status used for ticket?"
                    margin="normal"
                    name="ticket"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.ticket}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField>
                  <TextField
                    error={Boolean(touched.request && errors.request)}
                    fullWidth
                    helperText={touched.request && errors.request}
                    label="Status used for request?"
                    margin="normal"
                    name="request"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.request}
                    variant="outlined"
                  >
                    <MenuItem value="TRUE" default>Yes</MenuItem>
                    <MenuItem value="FALSE" default>No</MenuItem>
                  </TextField>
                  &nbsp;
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

StatusForm.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  divisions: PropTypes.array,
  accountType: PropTypes.string,
};

export default StatusForm;

export function ListStatus({
  status,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) {
  const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let statuslist = [];
  let SAstatuslist = [];

  if (status) {
    statuslist = status.map((stat) => (
      {
        status_id: stat.STATUS_ID || null,
        status_description: stat.STATUS_DESCRIPTION || '',
        default_flag: stat.DEFAULT_FLAG || '',
        ticket: stat.TICKET || '',
        request: stat.REQUEST || '',
        division_id: stat.DIVISION_ID || null,
        division_name: stat.DIVISION_NAME || ''
      }
    )).filter((row) => row.division_id === divisionid) || [];
  }
  if (status) {
    SAstatuslist = status.map((stat) => (
      {
        status_id: stat.STATUS_ID || null,
        status_description: stat.STATUS_DESCRIPTION || '',
        default_flag: stat.DEFAULT_FLAG || '',
        ticket: stat.TICKET || '',
        request: stat.REQUEST || '',
        division_id: stat.DIVISION_ID || null,
        division_name: stat.DIVISION_NAME || ''
      }
    ));
  }
  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF STATUS
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'status_id' },
            { title: 'Status Description', field: 'status_description' }
          ]}
          data={statuslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            { title: 'ID', field: 'status_id' },
            { title: 'Status Description', field: 'status_description' },
            { title: 'Division', field: 'division_name' }
          ]}
          data={SAstatuslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ticket Status
        </DialogTitle>
        <DialogContent dividers>
          <StatusDetails
            stat={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListStatus.propTypes = {
  status: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};
