/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { fetchOtherDivisionUser } from '../../../actions/user';
import { fetchSeverity, fetchModule, assignTicket } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function AssignTicketForm({
  user,
  serviceRefno,
  setOpenPopup,
  type,
  refresh,
  setRefresh,
  currentModule,
  currentSeverity,
  currentCategory
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);
  const [listOfPeople, setListOfPeople] = useState([]);
  const [listOfSeverity, setListOfSeverity] = useState([]);
  const [listOfModule, setListOfModule] = useState([]);
  const [tempEmpNum, setTempEmpNum] = useState('000000');
  const [tempEmpName, setTempEmpName] = useState('');
  const [tempServerity, setTempSeverity] = useState('');
  const [tempServerityImpact, setTempSeverityImpact] = useState('');
  const [tempModule, setTempModule] = useState('');
  const [tempCategory, setTempCategory] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const operation = 'ASG';

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  // const handleClose = () => {
  //   setOpenPopup(false);
  // };

  const excludeInactivePeople = (peoples) => {
    peoples.map((temp, i) => {
      if (temp.LOGIN_STATUS !== 'active') {
        peoples.splice(i, 1);
      }
    });

    setListOfPeople(peoples || []);
  };

  const handleClickEmployee = (employee) => {
    setTempEmpNum(employee?.EMPLOYEE_NUMBER || null);
    setTempEmpName(employee?.EMPLOYEE_NAME || null);
  };

  const handleClickModule = (module) => {
    setTempModule(module?.MODULE_ID || null);
    setTempCategory(module?.CATEGORY_ID || null || currentCategory);
  };

  const handleClickSeverity = (severity) => {
    setTempSeverity(severity?.SEVERITY_ID || null);
    setTempSeverityImpact(severity?.IMPACT || null);
  };

  const loadData = () => {
    dispatch(fetchOtherDivisionUser(userEmployeeNumber, operation))
      .then((res) => {
        if (res?.success === true) {
          excludeInactivePeople(res?.listOfPeople || []);
        }
      });
    dispatch(fetchSeverity(userEmployeeNumber))
      .then((res2) => {
        if (res2?.success === true) {
          setListOfSeverity(res2.severity || []);
        }
      });
    dispatch(fetchModule(userEmployeeNumber))
      .then((res3) => {
        if (res3?.success === true) {
          if (type === 'request') {
            setListOfModule(res3?.requestModules || []);
          } else {
            setListOfModule(res3?.ticketModules || []);
          }
        }
      });
  };

  useEffect(() => {
    loadData();
    setTempModule(currentModule);
    setTempSeverity(currentSeverity);
    setTempCategory(currentCategory);
  }, []);

  return (
    <Box
      bgcolor="common.white"
      color="text.primary"
      m={2}
      borderRadius={12}
      p={2}
    >
      <Formik
        enableReinitialize
        initialValues={{
          serviceRefno,
          description: tempDescription,
          employeeNumber: userEmployeeNumber,
          assignToNumber: tempEmpNum,
          assignToName: tempEmpName,
          severity: tempServerity,
          severity_impact: tempServerityImpact,
          module: tempModule,
          category: tempCategory || currentCategory
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500),
            severity: Yup.number().required('Please select severity.'),
            module: Yup.string().required('Please select module.'),
            assignToName: Yup.string().required('Please select people to be assigned to.'),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          dispatch(assignTicket(values))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setRefresh(!refresh);
                setOpenPopup(false);
              } else {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Autocomplete
              id="module"
              options={listOfModule}
              getOptionLabel={(option) => option.MODULE_DESCRIPTION}
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: 'module',
                    value: newValue ? newValue.MODULE_ID : ''
                  }
                });
                if (newValue) {
                  handleClickModule(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Module"
                  variant="outlined"
                  margin="normal"
                  error={Boolean(touched.module && errors.module)}
                  helperText={touched.module && errors.module}
                  onBlur={handleBlur}
                />
              )}
              value={listOfModule.find((module) => module.MODULE_ID === values.module) || null}
            />
            <TextField
              error={Boolean(touched.severity
                && errors.severity)}
              fullWidth
              helperText={touched.severity
                && errors.severity}
              label="Severity"
              id="severity"
              margin="normal"
              name="severity"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.severity}
              variant="outlined"
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfSeverity !== 0 ? (
                listOfSeverity.map((severity, index) => (
                  <MenuItem
                    value={severity.SEVERITY_ID}
                    onClick={() => handleClickSeverity(listOfSeverity[index])}
                  >
                    {severity?.SEVERITY_ID === currentSeverity && ('(Current) ')}
                    {severity?.SEVERITY_ID === currentSeverity
                      && (setTempSeverityImpact(severity?.IMPACT))}
                    {severity.IMPACT}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={null} disabled default>No severity data load</MenuItem>
              )}
            </TextField>

            <TextField
              error={Boolean(touched.assignToName
                && errors.assignToName)}
              fullWidth
              helperText={touched.assignToName
                && errors.assignToName}
              label="Assign to"
              id="assignToName"
              margin="normal"
              name="assignToName"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.assignToName}
              variant="outlined"
            >
              <MenuItem value="" default>None</MenuItem>
              {listOfPeople ? (
                listOfPeople.map((people, index) => (
                  <MenuItem
                    value={people.EMPLOYEE_NAME}
                    onClick={() => handleClickEmployee(listOfPeople[index])}
                  >
                    {people.EMPLOYEE_NAME}
                  </MenuItem>
                ))

              ) : (
                <MenuItem value="" disabled default>No people data load</MenuItem>
              )}
            </TextField>

            <TextField
              value={values.assignToNumber}
              name="assignToNumber"
              disabled
              variant="outlined"
              fullWidth
              label="Employee Number Preview"
              margin="normal"
            />

            <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                margin="normal"
                name="description"
                aria-label="maximum height"
                placeholder="Leave blank for auto-generate description."
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                onChangeCapture={(e) => {
                  setTempDescription(e.target.value);
                }}
                value={values.description}
              // defaultValue={`This ticket is assigned to ${tempEmpName}.`}
              />
            </Box>

            <Button
              id="submit"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
}

AssignTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.bool,
  type: PropTypes.string,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
  currentModule: PropTypes.string,
  currentSeverity: PropTypes.string,
  currentCategory: PropTypes.string
};

export default AssignTicketForm;
