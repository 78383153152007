import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
  Divider,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  listFAQ, listDivActive
} from '../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  roots: {
    width: '100%',
    marginTop: theme.spacing(0),
    overflowX: 'auto',
    overflowY: 'auto',
    marginLeftt: theme.spacing(0)
  },
  margin: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  list: {
    width: '100%',
    padding: 0,
    backgroundColor: '#e8eaf6',
    color: '#212121'
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  margintop: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  noBorder: {
    color: 'black'
  },
}));

const Faqs = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const stateFAQ = useSelector((state) => state?.faqs);
  const [faqs, setFAQ] = useState(stateFAQ);
  // const [stateAnswer] = useState(useSelector((state) => state?.answers));
  // const [answers, setAnswer] = useState(stateAnswer);
  const [stateDivisions] = useState(useSelector((state) => state?.divisions));
  const [divisions, setDivisions] = useState(stateDivisions);
  const location = useLocation();
  const [expandedPanel, setExpandedPanel] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };
  const retrieve = () => {
    dispatch(listFAQ())
      .then((res) => {
        if (res?.success === false) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          if (res?.relogin === true) {
            history('/login');
          }
        }
        setFAQ(res);
      });
    /* dispatch(listAnswer())
      .then((res) => {
        setAnswer(res);
      }); */
    dispatch(listDivActive())
      .then((res) => {
        if (res?.success === false) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          if (res?.relogin === true) {
            history('/login');
          }
        }
        setDivisions(res);
      });
  };

  useEffect(() => {
    retrieve();
  }, [location]);

  return (
    <Page
      title="FAQs - Prism"
    >
      <Container>
        <Grid>
          <Typography
            variant="h3"
            gutterBottom
            className={clsx(classes.roots, classes.margin)}
            color="primary"
            style={{ fontWeight: 500 }}
          >
            FAQs
          </Typography>
          {divisions && (
            <>
              {divisions.map((division) => (
                <Box>
                  <br />
                  <Typography
                    key={division.DIVISION_ID}
                    variant="h5"
                  >
                    {division.DIVISION}
                  </Typography>
                  <br />
                  {faqs && (
                    <>
                      {faqs.map((ques) => {
                        if (ques.DIVISION_ID === division.DIVISION_ID) {
                          return (
                            <>
                              <Accordion
                                key={ques.FAQ_ID}
                                expanded={expandedPanel === ques.FAQ_ID}
                                onChange={handleAccordionChange(ques.FAQ_ID)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading}>
                                    {ques.QUESTION}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box className={classes.list}>
                                    <Typography
                                      variant="h5"
                                      className={clsx(classes.margintop)}
                                    >
                                      Answer
                                    </Typography>
                                    <Divider />
                                    <Box>
                                      <TextField
                                        fullWidth
                                        margin="normal"
                                        name="answer"
                                        multiline
                                        value={ques.ANSWER}
                                        disabled
                                        InputProps={{
                                          disableUnderline: true,
                                          classes: { disabled: classes.noBorder }
                                        }}
                                        className={clsx(classes.button)}
                                      />
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        } return null;
                      })}
                    </>
                  )}
                  <br />
                  <Divider />
                </Box>
              ))}
            </>
          )}
          &nbsp;
          &nbsp;
        </Grid>
      </Container>
    </Page>
  );
};

export default Faqs;
