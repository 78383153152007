import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
  TextField,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { updateDivisionStatus } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DivisionDetails = ({
  selectedDivision,
  refresh,
  setRefresh,
  setOpendivision
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="Status"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              division_id: selectedDivision.division_id || '',
              division_name: selectedDivision.division_name || '',
              division_status: selectedDivision.division_status || ''
            }}
            validationSchema={
              Yup.object().shape({
                //
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateDivisionStatus(selectedDivision.division_id, values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpendivision(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <Box>
                    <Typography
                      color="primary"
                      style={{ fontWeight: 500 }}
                      variant="h6"
                    >
                      UPDATE DIVISION STATUS
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="h5" display="inline">
                      ID
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 92 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {values.division_id}
                    </Typography>
                    <br />
                    <Typography variant="h5" display="inline">
                      Division Name :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {values.division_name}
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    label="Division status"
                    margin="normal"
                    name="division_status"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_status}
                    variant="outlined"
                  >
                    <MenuItem value="ACTIVE" default>Active</MenuItem>
                    <MenuItem value="INACTIVE" default>Inactive</MenuItem>
                  </TextField>
                  &nbsp;
                  <br />
                  <br />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      Update
                    </Button>
                  </Box>
                </>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

DivisionDetails.propTypes = {
  selectedDivision: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpendivision: PropTypes.func
};

export default DivisionDetails;
