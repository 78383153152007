import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { dismissTicket } from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  },
  redButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f'
    }
  }
}));

function DismissTicketForm({
  serviceRefno,
  user,
  setOpenPopup,
  refresh,
  setRefresh
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userEmployeeNumber] = useState(user?.EMPLOYEE_NUMBER);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Box
      bgcolor="common.white"
      color="text.primary"
      m={2}
      borderRadius={12}
      p={2}
    >
      <Formik
        enableReinitialize
        initialValues={{
          serviceRefno,
          employeeNumber: userEmployeeNumber,
          description: 'Hi,\n\nSorry we had to dismiss your ticket. Please find below section for the dismissal reason.\n\nReason : \n\nApologies for the inconvenience. Thank you.',
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(500),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch(dismissTicket(values))
            .then((res) => {
              if (res?.success === true) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setRefresh(!refresh);
                setOpenPopup(true);
              } else if (res?.success === false) {
                enqueueSnackbar({
                  message: res?.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    action: (key) => (
                      <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                    ),
                    success: res?.success,
                    notifier: res?.notifier || null,
                  },
                });
                setSubmitting(false);
              }
            })
            .catch((err) => {
              console.log('error : ', err);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>

            <Box mb={2} mt={1}>
              <TextareaAutosize
                rowsMax={10}
                rowsMin={10}
                margin="normal"
                name="description"
                aria-label="maximum height"
                placeholder="Dismissal Reason."
                className={classes.textArea}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
              />
            </Box>

            <Button
              id="submit"
              className={classes.redButton}
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              onClick={handleChange}
            >
              Dismiss
            </Button>
          </form>
        )}
      </Formik>

    </Box>
  );
}

DismissTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  setOpenPopup: PropTypes.bool,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
};

export default DismissTicketForm;
