import React, { useState } from 'react';
import {
  makeStyles,
  Paper,
  Container,
  Box,
  Tooltip,
  Button,

} from '@material-ui/core';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import Popup from '../../../components/Popup';
import EquipmentItemForm from './EquipmentItemForm';
import ListOfEquipmentItem from './ListOfEquipmentItem';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const EquipmentItem = ({
  divisionidofuser,
  employeeNumber,
  divisions,
  employeeDivision,
  accountType
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Add Vendor" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Add Type"
                onClick={handleOpen}
              >
                ADD ITEM
              </Button>
            </Tooltip>
          </Box>
          <ListOfEquipmentItem
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            divisions={divisions}
            accountType={accountType}
            divisionidofuser={divisionidofuser}
            employeeDivision={employeeDivision}
          />
          <Popup
            openPopup={open}
            setOpenPopup={setOpen}
            setServiceType=""
            title="Add New Item"
          >
            <EquipmentItemForm
              divisionidofuser={divisionidofuser}
              employeeNumber={employeeNumber}
              divisions={divisions}
              employeeDivision={employeeDivision}
              accountType={accountType}
              openPopup={open}
              setOpenPopup={setOpen}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Popup>
          &nbsp;
        </Container>
      </Paper>
    </>
  );
};

EquipmentItem.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
  divisions: PropTypes.string,
  employeeDivision: PropTypes.string,
};

export default EquipmentItem;
