/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
// import { /* Link as RouterLink, */ useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  // MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createModule as NewModule } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ModuleForm = ({
  categories,
  divisionid,
  employeeNumber,
  refresh,
  setRefresh,
  setOpenM,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [categoryname, setCategoryname] = useState('');
  const [categoryid, setCategoryid] = useState('');
  const [departmentname, setDepartmentname] = useState('');
  const [departmentid, setDepartmentid] = useState('');
  const [divid, setDivid] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const handleCategoryClick = (category) => {
    setCategoryname(category?.CATEGORY_NAME || '');
    setCategoryid(category?.CATEGORY_ID || '');
    setDepartmentname(category?.DEPARTMENT_NAME || '');
    setDepartmentid(category?.DEPARTMENT_ID || '');
    setDivid(category?.DIVISION_ID || '');
  };

  categories.map((temp, i) => {
    if (temp.CATEGORY_STATUS !== 'ENABLE') {
      categories.splice(i, 1);
    }
  });

  const options = categories.map((category) => {
    const division = category.DIVISION_NAME;

    return {
      division: division || '',
      ...category,
    };
  });

  return (
    <Page
      className={classes.root}
      title="Module"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={{
              module_description: '',
              department_id: departmentid,
              department_name: departmentname,
              category_id: categoryid,
              category_name: categoryname,
              create_by: employeeNumber,
              division_id: divid
            }}
            validationSchema={
              Yup.object().shape({
                module_description: Yup.string().max(255).required('Module is required'),
                category_id: Yup.string().ensure().required('Category is required')
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(NewModule(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setOpenM(false);
                      setRefresh(!refresh);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {accountType === 'Super Admin' ? (
                  <>
                    <Autocomplete
                      onChange={(event, category) => {
                        handleCategoryClick(category);
                      }}
                      options={
                        options.sort((a, b) => b.DIVISION_NAME?.localeCompare(a?.DIVISION_NAME))
                      }
                      groupBy={(option) => option.DIVISION_NAME}
                      getOptionLabel={(option) => option.CATEGORY_NAME}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.category_id && errors.category_id)}
                          helperText={touched.category_id && errors.category_id}
                          label="Category"
                          variant="outlined"
                          id="category_id"
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="category_id"
                          value={values.category_id}
                          fullWidth
                        />
                      )}
                    />
                    {console.log('list of option: ', options)}
                  </>
                  // <TextField
                  //   error={Boolean(touched.category_id && errors.category_id)}
                  //   fullWidth
                  //   helperText={touched.category_id && errors.category_id}
                  //   label="Select Category"
                  //   margin="normal"
                  //   name="category_id"
                  //   onBlur={handleBlur}
                  //   onChange={handleChange}
                  //   select
                  //   value={values.category_id}
                  //   variant="outlined"
                  // >
                  //   {categories ? (
                  //     categories.map((category) => (
                  //       <MenuItem
                  //         height={20}
                  //         value={category.CATEGORY_ID}
                  //         key={category.CATEGORY_ID}
                  //         onClick={() => handleCategoryClick(category)}
                  //         style={{ whiteSpace: 'unset' }}
                  //       >
                  //         {/* {category.CATEGORY_ID}
                  //       &nbsp; */}
                  //         {category.CATEGORY_NAME}
                  //       </MenuItem>
                  //     ))
                  //   ) : (
                  //     <MenuItem value="">
                  //       <em>None</em>
                  //     </MenuItem>
                  //   )}
                  // </TextField>
                ) : (
                  <Autocomplete
                    onChange={(event, category) => {
                      handleCategoryClick(category);
                    }}
                    options={
                      options.filter((a) => divisionid === a?.DIVISION_ID)
                    }
                    getOptionLabel={(option) => option.CATEGORY_NAME}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.category_id && errors.category_id)}
                        helperText={touched.category_id && errors.category_id}
                        label="Category"
                        variant="outlined"
                        id="category"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="category"
                        value={values.category_id}
                        fullWidth
                      />
                    )}
                  />
                  /* <TextField
                    error={Boolean(touched.category_id && errors.category_id)}
                    fullWidth
                    helperText={touched.category_id && errors.category_id}
                    label="Select Category"
                    margin="normal"
                    name="category_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.category_id}
                    variant="outlined"
                  >
                    {categories ? (
                      categories.map((category) => {
                        if (
                          category.DIVISION_ID
                          === divisionid
                        ) {
                          return (
                            <MenuItem
                              height={20}
                              value={category.CATEGORY_ID}
                              key={category.CATEGORY_ID}
                              onClick={() => handleCategoryClick(category)}
                              style={{ whiteSpace: 'unset' }}
                            >
                              {category.CATEGORY_NAME}
                            </MenuItem>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField> */
                )}
                <TextField
                  error={Boolean(touched.module_description && errors.module_description)}
                  fullWidth
                  helperText={touched.module_description && errors.module_description}
                  label="Module"
                  margin="normal"
                  name="module_description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.module_description}
                  variant="outlined"
                />
                &nbsp;
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    Add
                  </Button>
                  &nbsp;
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="reset"
                    variant="outlined"
                    fullWidth
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

ModuleForm.propTypes = {
  // user: PropTypes.object,
  categories: PropTypes.array,
  // departments: PropTypes.array,
  divisionid: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setOpenM: PropTypes.func,
  accountType: PropTypes.string,
};

export default ModuleForm;
