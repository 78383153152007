import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  redButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f',
    }
  }
}));

export default useStyles;
