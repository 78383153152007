import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Password from './Password';
import RegisterFaceIO from './RegisterFaceIO';
import AuthValidationView from './AuthValidationView';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import {
  fetchFaceIdData
} from '../../../actions/user';
import {
  getOneMFARecord,
  fetchActiveMFAMethod
} from '../../../actions/admin';
import
MFAForm
  from './MFAForm';
import Popup from '../../../components/Popup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [employeeNumber] = useState(user?.result.EMPLOYEE_NUMBER);
  const [faceIdRegistered, setFaceIdRegistered] = useState(false);
  const [existingFaceidData, setExistingFaceidData] = useState({});
  const [mfaRecordData, setMfaRecordData] = useState([]);
  const [mfaMethodData, setMfaMethodData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [openValidation, setOpenValidation] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const getFaceIdData = () => {
    dispatch(fetchFaceIdData(employeeNumber))
      .then((res) => {
        setFaceIdRegistered(res.success);
        setExistingFaceidData(res?.listOfFaceId[0] || {});
      })
      .catch((err) => {
        console.log('ERROR RETRIEVE FACEID DATA : ', err);
      });
  };

  const getMFAData = () => {
    dispatch(getOneMFARecord(employeeNumber))
      .then((res) => {
        if (res?.success === true) {
          setMfaRecordData(res?.data);
          if (res?.data[0]?.VERIFICATION_STATUS === 'false') {
            setShowVerifyButton(true);
            setOpenValidation(true);
          }
        }
      });
    dispatch(fetchActiveMFAMethod())
      .then((res) => {
        if (res?.success === true) {
          setMfaMethodData(res?.data);
        }
      });
  };

  const setView = () => {
    setIsMobile(width <= 900);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setView();
  };

  useEffect(() => {
    if (!employeeNumber) {
      history('/login');
      promptNotification();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
    getFaceIdData();
    getMFAData();
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [width]);

  return (
    <>
      <Page
        className={classes.root}
        title="Settings - Prism"
      >
        <Container maxWidth="lg">
          <Box mt={3} mb={3}>
            <Password employeeNumber={employeeNumber} />
          </Box>

          <Box
            mb={3}
            ml={3}
            mr={3}
          >
            <MFAForm
              data={{ record: mfaRecordData, method: mfaMethodData, user }}
            />
            {showVerifyButton && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  setOpenValidation(true);
                }}
              >
                Verify Authentication
              </Button>
            )}
          </Box>

          {faceIdRegistered === true ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
              >
                <Container>
                  <Card>
                    <CardHeader
                      subheader="Face ID registration for face login"
                      title="Face ID"
                    />
                    <Divider />
                    <CardContent>
                      <Box>
                        <Alert severity="Success">{`Face ID registered! Activated on ${existingFaceidData?.FACEID_ACTIVATION_DATETIME} and your PIN is ${existingFaceidData?.FACEIO_PIN}`}</Alert>
                      </Box>
                    </CardContent>
                  </Card>
                </Container>
              </Box>
            </>
          ) : (
            <>
              {isMobile ? (
                <>
                  <Box mt={3}>
                    <RegisterFaceIO employeeNumber={employeeNumber} />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                  >
                    <Container>
                      <Card>
                        <CardHeader
                          subheader="Face ID registration for face login"
                          title="Face ID"
                        />
                        <Divider />
                        <CardContent>
                          <Box>
                            <Alert severity="warning">Face ID registration only available on mobile devices, please relogin using a mobile browser.</Alert>
                          </Box>
                        </CardContent>
                      </Card>
                    </Container>
                  </Box>
                </>
              )}
            </>
          )}
        </Container>
      </Page>
      {openValidation === true && (
        <>
          <Popup
            openPopup={openValidation}
            setOpenPopup={setOpenValidation}
          >
            <AuthValidationView />
          </Popup>
        </>
      )}
    </>
  );
};

export default SettingsView;
