import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { addNewVendor } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewVendor = ({
  vendorview,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  setNewVendor,
  divisions,
  accountType
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tempVendorId, setTempVendorId] = useState('');
  const [tempVendor, setTempVendor] = useState('');
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [clickdiv, setClickDiv] = useState('');
  const [clickdivname, setClickDivname] = useState('');
  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };
  const options = vendorview.map((option) => {
    const vendor = option.VENDOR_NAME;
    return {
      vendor: vendor || '',
      ...option,
    };
  });

  const handleClickVendor = (value) => {
    setTempVendorId(value?.VENDOR_ID || '');
    setTempVendor(value?.VENDOR_NAME || '');
  };

  return (
    <Page
      className={classes.root}
      title="Division"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                vendor_id: tempVendorId,
                vendor_name: tempVendor,
                division_id: divisionidofuser,
                created_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  vendor_id: Yup.string().ensure().required('Vendor is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(addNewVendor(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setNewVendor(false);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    Add New Vendor
                  </Typography>
                  <Autocomplete
                    onChange={(event, value) => {
                      handleClickVendor(value);
                    }}
                    options={options}
                    getOptionLabel={(option) => option.VENDOR_NAME}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.vendor_id && errors.vendor_id)}
                        helperText={touched.vendor_id && errors.vendor_id}
                        label="Vendor Name"
                        variant="outlined"
                        id="vendor_id"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="vendor_id"
                        value={values.vendor_id}
                        fullWidth
                      />
                    )}
                  />
                  {/* <TextField
                  error={Boolean(touched.vendor_id && errors.vendor_id)}
                  fullWidth
                  helperText={touched.vendor_id && errors.vendor_id}
                  label="Vendor Name"
                  margin="normal"
                  name="vendor_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vendor_id}
                  variant="outlined"
                  select
                >
                  {vendorview ? (
                    vendorview.map((vendor) => (
                      <MenuItem
                        value={vendor.VENDOR_ID}
                        onClick={() => handleClickVendor(vendor)}
                        key={vendor.VENDOR_ID}
                        style={{ whiteSpace: 'unset' }}
                      >
                        {vendor.VENDOR_NAME}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </TextField> */}
                  <br />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ADD
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                vendor_id: tempVendorId,
                vendor_name: tempVendor,
                division_id: clickdiv,
                division_name: clickdivname,
                created_by: employeeNumber
              }}
              validationSchema={
                Yup.object().shape({
                  vendor_id: Yup.string().ensure().required('Vendor is required'),
                  division_id: Yup.string().max(255).required('Division in charge is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(addNewVendor(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setNewVendor(false);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    Add New Vendor
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <Autocomplete
                    onChange={(event, value) => {
                      handleClickVendor(value);
                    }}
                    options={options}
                    getOptionLabel={(option) => option.VENDOR_NAME}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.vendor_id && errors.vendor_id)}
                        helperText={touched.vendor_id && errors.vendor_id}
                        label="Vendor Name"
                        variant="outlined"
                        id="vendor_id"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="vendor_id"
                        value={values.vendor_id}
                        fullWidth
                      />
                    )}
                  />
                  <br />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ADD
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

NewVendor.propTypes = {
  vendorview: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  setNewVendor: PropTypes.func,
  divisions: PropTypes.array,
  accountType: PropTypes.string,
};

export default NewVendor;
