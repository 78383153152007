import React from 'react';
import {
  makeStyles,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Page from 'src/components/Page';
import AuthenticationMethod from './AuthenticationMethod';
import AuthenticationRecord from './AuthenticationRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const index = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  // const employeeDivision = JSON.parse(localStorage.getItem('profile'))?.result?.DIVISION_ID;

  return (
    <>
      <Page
        className={classes.root}
        title="Authentication"
      >
        <Box
          mb={3}
        >
          <AuthenticationMethod />
        </Box>
        <Box
          mb={3}
        >
          <AuthenticationRecord />
        </Box>
      </Page>
    </>
  );
};

index.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
};

export default index;
