/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  TextField,
  Button,
  Container,
  Typography,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  totpValidate
} from '../../actions/auth';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const TOTPVerificationView = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const globalUserData = useSelector((state) => state?.auth?.userData);
  const globalUserId = useSelector((state) => state?.auth?.userId);
  const [initialValues] = useState({
    totp_code: '',
    employee_number: globalUserId
  });
  const [errorCount, setErrorCount] = useState(0);
  const [retryLimit] = useState(3);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const popupNotification = (res) => {
    enqueueSnackbar({
      message: res?.message,
      options: {
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
        ),
        success: res?.success,
        notifier: res?.notifier || null,
      },
    });
  };

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  return (
    <>
      {error && (
        <>
          <Alert severity="error">{message}</Alert>
        </>
      )}
      <Box
        display="flex"
        mt="2%"
      >
        <Typography
          variant="h1"
          gutterBottom
          align="center"
        >
          Welcome back! Please Complete the Multi-Factor Authentication
        </Typography>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        mt="5%"
      >
        <Container maxWidth="sm">
          <Box
            m={2}
          >
            <Box>
              <Typography
                align="center"
                component="div"
              >
                <h2>
                  TOTP Authentication
                </h2>
                <p>
                  Open your Google Authenticator app on your mobile device to get your
                  verification code.
                </p>
              </Typography>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                totp_code: Yup.string().min(6, 'Code must be 6 digits.').max(6, 'Code must be 6 digits.').required('Authentication code is required.'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // setFormData(values);

                try {
                  // Submit user credential to backend
                  dispatch(totpValidate(values, history))
                    .then((res) => {
                      if (res?.success === false && errorCount <= retryLimit) {
                        popupNotification(res);
                        handleError(res?.message);
                        setSubmitting(false);
                        setErrorCount(errorCount + 1);
                      } else if (res?.success === false && errorCount > retryLimit) {
                        popupNotification({
                          message: 'Code validation exceeds limit. Please relogin.',
                          notifier: res?.notifier,
                          success: res?.success
                        });
                        setSubmitting(false);
                        history('/login');
                      } else if (res?.success === true) {
                        dispatch({ type: 'AUTH', payload: globalUserData });
                        popupNotification(res);
                        history('/app/dashboard');
                      }
                    })
                    .catch(() => {
                      handleError('Server Error');
                      setSubmitting(false);
                    });
                } catch (err) {
                  handleError(err);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.totp_code && errors.totp_code)}
                    fullWidth
                    helperText={touched.totp_code && errors.totp_code}
                    label="Authentication Code"
                    margin="normal"
                    name="totp_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="Text"
                    inputProps={{
                      maxLength: 6,
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.code}
                    variant="outlined"
                  />
                  <Box
                    my={2}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </>
  );
};

TOTPVerificationView.propTypes = {
};

export default TOTPVerificationView;
