/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
import * as api from '../api/index';

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.login(formData)
      .then((res) => {
        if (res?.data) {
          if (res?.data.success === false) {
            dispatch({ type: 'HIDE_LOADING_SPINNER' });
            return res?.data;
          } else if (res?.data.success === true) {
            if (res?.data?.mfaStatus === true) {
              dispatch({ type: 'SET_AUTHSTATUS', payload: res?.data?.mfaStatus });
              dispatch({ type: 'SET_USERID', payload: res?.data?.result?.EMPLOYEE_NUMBER });
              dispatch({ type: 'SET_USERDATA', payload: res?.data });
              dispatch({ type: 'HIDE_LOADING_SPINNER' });
            } else {
              dispatch({ type: 'AUTH', payload: res?.data });
              dispatch({ type: 'HIDE_LOADING_SPINNER' });
            }
            return res?.data;
          }
        }
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const register = (
  formData
) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.register(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((error) => {
        return error;
      });
    // dispatch({ type: 'AUTH', payload: data });
    // history('/app/customer');
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const activate = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.activateAccount(formData)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const forgotpassword = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.forgotpassword(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((error) => {
        return error;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const totpValidate = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.totpValidate(formData)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((error) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return error;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const totpVerify = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.totpVerify(formData)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data || res;
      })
      .catch((error) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return error;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};
