export default (logins = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL':
      return logins;
    case 'CREATE':
      return [...logins, action.payload];
    case 'FETCH_ONE':
      return action.payload;
    default:
      return logins;
  }
};
