/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  // Container,
  MenuItem,
  CardContent,
  Card,
  CardHeader,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  disableMFARecord,
  createMFARecord
} from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles(() => ({
  red: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#d32f2f',
    }
  },
}));

const MFAForm = ({
  data
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [mfaMethodData, setMfaMethodData] = useState(data?.method);
  const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.result);
  const [initialValues, setInitialValues] = useState({
    mfa_id: '',
    user_id: user?.EMPLOYEE_NUMBER,
    employee_number: user?.EMPLOYEE_NUMBER
  });
  const [tempInitialValues, setTempInitialValues] = useState({});

  // This usestate will run on first load
  useEffect(() => {
    setMfaMethodData(data?.method);
    setTempInitialValues({
      mfa_id: data?.record[0]?.MFA_ID || '',
      user_id: user?.EMPLOYEE_NUMBER || '',
      employee_number: user?.EMPLOYEE_NUMBER || ''
    });
  }, []);

  // This usestate will run when data value change
  useEffect(() => {
    setMfaMethodData(data?.method);
    setTempInitialValues({
      mfa_id: data?.record[0]?.MFA_ID || '',
      user_id: user?.EMPLOYEE_NUMBER || '',
      employee_number: user?.EMPLOYEE_NUMBER || ''
    });
  }, [data]);

  // This usestate will run when tempInitialValue update
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      mfa_id: tempInitialValues?.mfa_id
    });
  }, [tempInitialValues]);

  return (
    <>
      <Card>
        <CardHeader
          title="Multi-Factor Authentication"
          subheader="Choose your prefered secondary Authentication"
        />
        <Divider />
        <CardContent>
          {data?.record?.length === 0 ? (
            <>
              {/* If user has no record of MFA activation before */}
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  Yup.object().shape({
                    mfa_id: Yup.string().max(255),
                    user_id: Yup.string().max(255),
                    employee_number: Yup.string().max(255)
                  })
                }
                onSubmit={
                  (values, { setSubmitting, resetForm }) => {
                    dispatch(createMFARecord(values))
                      .then((res) => {
                        if (res?.success === true) {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          history('#/app/settings');
                        } else {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch();
                    resetForm({
                      values: {
                        mfa_id: '',
                        user_id: ''
                      }
                    });
                  }
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <br />
                    <TextField
                      error={Boolean(touched.mfa_id
                        && errors.mfa_id)}
                      fullWidth
                      helperText={touched.mfa_id
                        && errors.mfa_id}
                      label="Authentication"
                      id="mfa_id"
                      margin="normal"
                      name="mfa_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.mfa_id ? values.mfa_id : ''}
                      variant="outlined"
                    >
                      <MenuItem
                        value="undefined"
                        default
                        disabled
                      >
                        None
                      </MenuItem>
                      {(Array.isArray(mfaMethodData) && mfaMethodData?.length >= 0) ? (
                        mfaMethodData.map((method) => (
                          <MenuItem
                            value={method?.id}
                            key={method?.id}
                          >
                            {`${method?.AUTH_NAME}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled default>Loading active method</MenuItem>
                      )}
                    </TextField>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      p={2}
                    >
                      <Button
                        color="primary"
                        disabled={!isSubmitting && (values.mfa_id === '' || values.mfa_id === 'undefined')}
                        type="submit"
                        variant="contained"
                      >
                        Activate
                      </Button>
                    </Box>

                  </form>
                )}
              </Formik>

            </>
          ) : (
            <>
              {/* Else show disable MFA form */}
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={
                  Yup.object().shape({
                    mfa_id: Yup.string().max(255),
                    user_id: Yup.string().max(255),
                    employee_number: Yup.string().max(255)
                  })
                }
                onSubmit={
                  (values, { setSubmitting }) => {
                    setSubmitting(true);
                    dispatch(disableMFARecord(values))
                      .then((res) => {
                        if (res?.success === true) {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          history('#/app/settings');
                        } else {
                          enqueueSnackbar({
                            message: res?.message,
                            options: {
                              key: new Date().getTime() + Math.random(),
                              action: (key) => (
                                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                              ),
                              success: res?.success,
                              notifier: res?.notifier || null,
                            },
                          });
                          setSubmitting(false);
                        }
                      })
                      .catch();
                  }
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.mfa_id
                        && errors.mfa_id)}
                      fullWidth
                      helperText={touched.mfa_id
                        && errors.mfa_id}
                      label="Authentication"
                      id="mfa_id"
                      margin="normal"
                      name="mfa_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      select
                      value={values.mfa_id ? values.mfa_id : ''}
                      variant="outlined"
                      disabled
                    >
                      <MenuItem
                        value=""
                        default
                      >
                        None
                      </MenuItem>
                      <MenuItem value="undefined" disabled>Loading active method</MenuItem>
                      {(Array.isArray(mfaMethodData) && mfaMethodData?.length >= 0) ? (
                        mfaMethodData.map((method) => (
                          <MenuItem
                            value={method?.id}
                            key={method?.id}
                          >
                            {`${method?.AUTH_NAME} ${(data?.record[0]?.MFA_ID === method?.id) ? '(Current)' : ''}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="undefined" disabled default>Loading active method</MenuItem>
                      )}
                    </TextField>
                    {(data?.record[0]?.VERIFICATION_STATUS === 'false' && data?.record[0]?.AUTH_STATUS === 'true') && (
                      <>
                        <Alert severity="warning">The user has not yet verified MFA.</Alert>
                      </>
                    )}
                    {(data?.record[0]?.VERIFICATION_STATUS === 'true' && data?.record[0]?.AUTH_STATUS === 'true') && (
                      <>
                        <Alert severity="info">{`The user has verified MFA on ${data?.record[0]?.UPDATE_DATETIME}`}</Alert>
                      </>
                    )}
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      &nbsp;
                      <Button
                        className={classes.red}
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Disable
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

MFAForm.propTypes = {
  data: PropTypes.object
};

export default MFAForm;
