/* eslint-disable no-alert */
/* eslint-disable no-lonely-if */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Container,
  Box,
  Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

import Page from 'src/components/Page';
import ManageView from './ManageView';
import NormalView from './NormalView';
import Toolbar from './Toolbar';
import {
  fetchPersonalTicket,
  fetchManageTicket,
} from '../../../actions/service';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Service = () => {
  let { openAdd } = useParams();
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [stateCategory] = useState(
    useSelector((state) => state?.service.categories)
  );
  const [tickets, setTickets] = useState(null);
  const [ticketCategories, setTicketCategories] = useState(stateCategory);
  const [requestCategories, setRequestCategories] = useState([]);
  const [equipmentRequest, setEquipmentRequest] = useState([]);
  const [employeeNumber] = useState(user?.result.EMPLOYEE_NUMBER);
  const [divisionID] = useState(user?.result.DIVISION_ID);
  const [openNormalView, setOpenNormalView] = useState(true);
  const [newTicket, setNewTicket] = useState(null);
  const [assignedTicket, setAssignedTicket] = useState(null);
  const [handleTicket, setHandleTicket] = useState(null);
  const [solutionProposedTicket, setSolutionProposedTicket] = useState(null);
  const [accountType] = useState(user?.accountType || null);
  const [refreshInterval, setRefreshInterval] = useState(10000);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const retrieveMyTicket = (empNo, skipLoadingLoop) => {
    dispatch(fetchPersonalTicket(empNo, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          if (Array.isArray(res?.personalTicket)) {
            setTickets(res?.personalTicket || []);
          }
          setTicketCategories(res?.ticketCategory || []);
          setRequestCategories(res?.requestCategory || []);
          setEquipmentRequest(res?.equipmentRequest || []);
        } else {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          if (res?.relogin === true) {
            history('/login');
          }
        }
      })
      .catch((err) => {
        console.log('ERROR RETRIEVE PERSONAL TICKET : ', err);
      });
  };

  const retrieveManageTicket = (empNo, skipLoadingLoop) => {
    dispatch(fetchManageTicket(empNo, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          setNewTicket(res?.newTicket || []);
          setAssignedTicket(res?.assignedTicket || []);
          setHandleTicket(res?.handleTicket || []);
          setSolutionProposedTicket(res?.solutionProposedTicket || []);
        } else {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log('ERROR RETRIEVE PERSONAL TICKET (ELSE) : ', err);
      });
  };

  const checkOpenForm = (val) => {
    if (val === true || val === false) {
      openAdd = val;
    } else {
      openAdd = val;
    }
  };

  const getData = (skipLoadingLoop) => {
    try {
      if (openNormalView === true) {
        retrieveMyTicket(employeeNumber, skipLoadingLoop);
      } else if (openNormalView === false) {
        retrieveManageTicket(employeeNumber, skipLoadingLoop);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setRefreshInterval(50000);
    checkOpenForm(openAdd);
    if (!employeeNumber) {
      alert('Please Re-login');
    }
    setUser(JSON.parse(localStorage.getItem('profile')));

    getData();

    if (refreshInterval > 0) {
      const interval = setInterval(() => {
        getData(true);
      }, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refresh, location, openNormalView, refreshInterval]);

  return (
    <Page
      className={classes.root}
      title="Service - Prism"
    >
      <Container maxWidth={false}>
        <Toolbar
          setOpenNormalView={setOpenNormalView}
          user={user}
          tickets={tickets}
          refresh={refresh}
          setRefresh={setRefresh}
          openAdd={openAdd}
          employeeNumber={employeeNumber}
          divisionID={divisionID}
        />
        <Box>
          {openNormalView === true ? (
            <>
              <NormalView
                tickets={tickets}
                ticketCategories={ticketCategories}
                requestCategories={requestCategories}
                equipmentRequest={equipmentRequest}
                user={user?.result}
                refresh={refresh}
                setRefresh={setRefresh}
                openAdd={openAdd}
              />
            </>
          ) : (
            <>
              <ManageView
                newTicket={newTicket}
                assignedTicket={assignedTicket}
                handleTicket={handleTicket}
                solutionProposedTicket={solutionProposedTicket}
                user={user?.result}
                refresh={refresh}
                setRefresh={setRefresh}
                accountType={accountType}
              />
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default Service;
