import React, { useState, forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Paper,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox
} from '@material-ui/icons';
import Page from 'src/components/Page';
import DAForm from './DAForm';
import DADUForm from './divadminuserDetails';

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function DAList({
  das,
  dus,
  users,
  divisionidofuser,
  employeeNumberuser,
  refresh,
  setRefresh
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [addOpen, setaddOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };
  const handleClickOpen = () => {
    setaddOpen(true);
  };
  const handleClose = () => {
    setaddOpen(false);
    setOpen(false);
  };

  let DAlist = [];
  let DUlist = [];

  if (das) {
    DAlist = das.map((DA) => (
      {
        employeenumber: DA.EMPLOYEE_NUMBER || null,
        name: DA.EMPLOYEE_NAME || '',
        position: DA.POSITION || '',
        department: DA.DEPARTMENT_NAME || '',
        division_id: DA.DIVISION_ID || '',
        account_type: DA.ACCOUNT_TYPE || ''
      }
    )).filter(
      (row) => (row.division_id === divisionidofuser)
        && (row.employeenumber !== employeeNumberuser)
    ) || [];
  }

  if (dus) {
    DUlist = dus.map((DU) => (
      {
        employeenumber: DU.EMPLOYEE_NUMBER || null,
        name: DU.EMPLOYEE_NAME || '',
        position: DU.POSITION || '',
        department: DU.DEPARTMENT_NAME || '',
        division_id: DU.DIVISION_ID || '',
        account_type: DU.ACCOUNT_TYPE || ''
      }
    )).filter(
      (row) => (row.division_id === divisionidofuser)
        && (row.employeenumber !== employeeNumberuser)
    ) || [];
  }

  return (
    <Page
      className={classes.paper}
      title="Division"
    >
      <>
        <Paper>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginRight={3}
            paddingTop={1}
          >
            <Tooltip title="Add Division Admin/User" aria-label="add">
              <Button
                variant="outlined"
                color="primary"
                tooltip="Add Division Admin/User"
                onClick={handleClickOpen}
              >
                ADD DIVISION ADMIN/DIVISION USER
              </Button>
            </Tooltip>
          </Box>
          <Box
            marginRight={3}
            marginLeft={3}
          >
            <Typography
              color="primary"
              style={{ fontWeight: 500 }}
            >
              DIVISION ADMIN
            </Typography>
            &nbsp;
            <MaterialTable
              icons={tableIcons}
              title=" "
              columns={[
                { title: 'Employee Number', field: 'employeenumber' },
                { title: 'Name', field: 'name' },
                { title: 'Position', field: 'position' },
                { title: 'Department', field: 'department' },
              ]}
              data={DAlist}
              options={{
                search: true,
                grouping: true,
                rowStyle: () => ({
                  fontFamily: 'helvetica',
                })
              }}
              onRowClick={((event, selectedRowData) => {
                handleOnClick();
                setSelectedRow(selectedRowData);
              })}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
            />
          </Box>
        </Paper>
        &nbsp;
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={addOpen}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Division Admin/User
          </DialogTitle>
          <DialogContent dividers>
            <DAForm
              users={users}
              divisionidofuser={divisionidofuser}
              setaddOpen={setaddOpen}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Division Admin
          </DialogTitle>
          <DialogContent dividers>
            <DADUForm
              DA={selectedRow}
              refresh={refresh}
              setRefresh={setRefresh}
              setOpen={setOpen}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Paper>
          <Box
            marginRight={3}
            marginLeft={3}
            paddingTop={2}
          >
            <Typography
              color="primary"
              style={{ fontWeight: 500 }}
            >
              DIVISION USER
            </Typography>
            &nbsp;
            <MaterialTable
              icons={tableIcons}
              title=" "
              columns={[
                { title: 'Employee Number', field: 'employeenumber' },
                { title: 'Name', field: 'name' },
                { title: 'Position', field: 'position' },
                {
                  title: 'Department',
                  field: 'department'
                },
              ]}
              data={DUlist}
              onRowClick={((event, selectedRowData) => {
                handleOnClick();
                setSelectedRow(selectedRowData);
              })}
              options={{
                search: true,
                grouping: true,
                rowStyle: () => ({
                  fontFamily: 'helvetica',
                })
              }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />
              }}
            />
          </Box>
        </Paper>
      </>
    </Page>
  );
}

DAList.propTypes = {
  das: PropTypes.array,
  dus: PropTypes.array,
  users: PropTypes.array,
  employeeNumberuser: PropTypes.string,
  divisionidofuser: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
};

export default DAList;
