import React
, { useEffect, useState }
  from 'react';
import {
  Box,
  Paper,
  Hidden,
  Zoom,
  makeStyles,
  Typography
} from '@material-ui/core';

import ContactInformation from './ContactInformation';

const UseStyles = makeStyles(() => ({
  prismFont: {
    display: 'inline-block',
    objectFit: 'cover',
    border: '10px',
    borderRadius: '4px 4px 0 0',
  },
  container: {
    m: '3',
    marginTop: '3',
    marginBottom: '3',
    marginLeft: '3',
    marginRight: '3',
  }
}));

function AboutPrism() {
  const [grow, setGrow] = useState(false);
  const classes = UseStyles();
  const [screenWidth, setScreenWidth] = useState(0);
  const prismDescription = 'An integrated system that provide seamless service request and issue management on a single platform for all divisions across Port of Tanjung Pelepas (PTP).';

  // Configure on which scrollY to on/off topbar
  const detectWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    setGrow(true);
    detectWidth();
  }, [window.innerWidth]);

  return (
    <>
      <Box
        m={1}
        ml={screenWidth <= 900 ? 2 : 10}
        mr={screenWidth <= 900 ? 2 : 10}
        zIndex="tooltip"
        top={screenWidth <= 1170 ? -50 : -150}
        position="relative"
        maxWidth="100%"
        minWidth="40%"
      >
        <Hidden smDown>
          <Paper
            elevation={24}
          >

            {/* Import PRISM image */}
            <Box
              alt="Title"
              justifyContent="center"
              display="flex"
            >
              <Box
                alt="Prism Wording"
                className={classes.prismFont}
                src="/static/images/PRISM1.svg"
                component="img"
                maxWidth="40%"
                width="auto"
                display="block"
              />
            </Box>

            {/* PRISM Description */}
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <Typography
              variant="h1"
              gutterBottom
            />
            <Typography
              variant="h3"
              gutterBottom
            >
              <Zoom in={grow} style={{ transitionDelay: grow ? '400ms' : '0ms' }}>
                <Box
                  justifyContent="center"
                  textAlign="center"
                  display="flex"
                  position="relative"
                  m={1}
                  ml={3}
                  mr={3}
                  top={-40}
                >
                  {prismDescription}
                </Box>
              </Zoom>
            </Typography>

            {/* Show Contact information  */}
            <Box
              alt="Contact Information"
              display="flex"
              justifyContent="center"
              m={3}
              mt={3}
              ml={3}
              mr={3}
            >
              <ContactInformation />
            </Box>

            {/* Word Cloud */}
            <Box
              alt="Title"
              justifyContent="center"
              display="flex"
            >
              <Zoom in={grow} style={{ transitionDelay: grow ? '400ms' : '0ms' }}>
                <Box
                  alt="Prism Wording"
                  className={classes.prismFont}
                  src="/static/images/wordcloud4.svg"
                  component="img"
                  maxWidth="50%"
                  width="auto"
                  display="block"
                />
              </Zoom>
            </Box>
          </Paper>
        </Hidden>

        <Hidden only={['md', 'lg', 'xl']}>
          <Paper
            elevation={24}
          >

            {/* Import PRISM image */}
            <Box
              alt="Title"
              justifyContent="center"
              display="flex"
            >
              <Box
                alt="Prism Wording"
                className={classes.prismFont}
                src="/static/images/PRISM1.svg"
                component="img"
                maxWidth="80%"
                width="auto"
                display="block"
              />
            </Box>

            {/* PRISM Description */}
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <Typography
              variant="h3"
              gutterBottom
            >
              <Zoom in={grow} style={{ transitionDelay: grow ? '400ms' : '0ms' }}>
                <Box
                  justifyContent="center"
                  textAlign="center"
                  display="flex"
                  m={1}
                  ml={3}
                  mr={3}
                  position="relative"
                  top={-30}
                >
                  {prismDescription}
                </Box>
              </Zoom>
            </Typography>

            {/* Show Contact information  */}
            <Box
              alt="Contact Information"
              display="flex"
              justifyContent="center"
              m={3}
              mt={3}
              ml={3}
              mr={3}
            >
              <ContactInformation />
            </Box>

            {/* Word Cloud */}
            <Box
              alt="Title"
              justifyContent="center"
              display="flex"
            >
              <Box
                alt="Prism Wording"
                className={classes.prismFont}
                src="/static/images/wordcloud4.svg"
                component="img"
                maxWidth="80%"
                width="auto"
                display="block"
              />
            </Box>
          </Paper>
        </Hidden>
      </Box>
    </>
  );
}

export default AboutPrism;
